<template>
    <div class="cz2__panel cz2__panel--secondary cz2__edit-art">
        <focus-lock>
            <div class="cz2__panel__body cz2__edit-art__body" tabindex="0">
                <div class="cz2__panel-section__header cz2__type__small-header">
                    {{$t('EDIT_CHOOSE_LOCATION')}}
                </div>

                <div class="cz2__edit-locations cz2__sub-menu__options">
                    <button v-for="l in availableLocations" :key="l.location.location" :class="[
                        'cz2__edit-location',
                        'cz2__sub-menu-option',
                        {
                            'cz2__edit-location--selected': l.selected,
                        },
                        {
                            'cz2__sub-menu-option--selected': l.selected,
                        },
                    ]"  :disabled="!l.available"
                        :title="l.name"
                        @click.prevent="selectLocation(l)">
                        <span class="cz2__edit-location__image cz2__sub-menu-option__image" :style="{
                            backgroundImage: `url(${l.image})`
                        }"></span>
                        <span class="cz2__edit-location__name cz2__sub-menu-option__name">
                            {{$t('ACTION_ADD')}} {{l.location.name}}
                        </span>
                    </button>
                </div>

                <template v-if="selectedLocation">
                    <div class="cz2__panel-section">
                        <div class="cz2__panel-section__header cz2__type__small-header">
                            {{$t('EDIT_ART_CHOOSE_ICON')}}

                            <span v-if="selectedComponent && !selectedComponent.placeholder" class="cz2__panel-section__selected cz2__type__small-print">
                                {{selectedComponent.description[0].description}}
                            </span>
                        </div>

                        <div class="cz2__art-options cz2__sub-menu__options">
                            <button v-for="option in availableOptions"
                                :key="option.component.code"
                                :class="[
                                    'cz2__art-option',
                                    'cz2__sub-menu-option',
                                    {
                                        'cz2__art-option--selected': option.selected,
                                    },
                                    {
                                        'cz2__sub-menu-option--selected': option.selected,
                                    },
                                ]"
                                :title="option.component.description[0].description"
                                @click.prevent="selectOption(option)"
                            >
                                <span class="cz2__art-option__image cz2__sub-menu-option__image" :style="{
                                    backgroundImage: `url(${option.image})`
                                }"></span>

                                <span class="cz2__art-option__name cz2__sub-menu-option__name">
                                    {{option.component.description[0].description}}
                                </span>
                            </button>
                        </div>
                    </div>
                </template>

                <template v-if="isComplete">
                    <div class="cz2__edit-art__flex-space">
                    </div>

                    <div class="cz2__remove-component">
                        <button @click.prevent="removeArt"><span>{{$t('EDIT_ART_REMOVE_ICON')}}</span></button>
                    </div>
                </template>
            </div>

            <div class="cz2__panel__actions">
                <button class="cz2__panel-action" @click.prevent="cancel"><span>{{$t('ACTION_CANCEL')}}</span></button>
                <button class="cz2__panel-action cz2__panel-action--primary"
                    :disabled="!isComplete"
                    @click.prevent="accept">
                    <span>{{$t('ACTION_SAVE')}}</span>
                </button>
            </div>
        </focus-lock>
    </div>
</template>

<style lang="scss">
    @import "../style/variables.scss";

    #cz2.cz2 {

        .cz2__panel__body.cz2__edit-art__body {
            display: flex;

            flex-direction: column;
            justify-content: flex-start;
            align-items: stretch;

            & > * {
                flex: 0 0 auto;
            }

            & > .cz2__edit-art__flex-space {
                height: 1px;

                flex: 1 1 auto;
            }
        }

        .cz2__art-options {
            display: flex;
            flex-wrap: wrap;

            margin-top: 17px;

            margin-left: -4px;
            margin-right: -4px;
        }

        .cz2__art-option {
            &:disabled {
                opacity: 0.2;
            }
        }
    }
</style>

<script>
    import FocusLock from 'vue-focus-lock';

    import * as constants from '../constants';

    import { getLocationImageUrl, makeComponentThumbnailUrl } from './imagehelpers';

    export default {
        components: {
            FocusLock,
        },
        data() {
            let startingLocation = null;

            const { selectedStep } = this.$store.state;
            const isExisting = selectedStep.startsWith('edit-');

            if (isExisting) {
                const code = selectedStep.replace('edit-art-', '');

                startingLocation = this.$store.getters.filledArt.find((l) => l.location === code);
            }

            return {
                selectedLocation: startingLocation,
                startingLocation,
            };
        },
        computed: {
            /**
             * Current view controller.
             */
            viewController() {
                return this.$store.state.customizer.viewController;
            },

            /**
             * Selected step.
             */
            selectedStep() {
                return this.$store.state.selectedStep;
            },

            /**
             * New customization or edit existing?
             */
            isNew() {
                return this.selectedStep.startsWith('new-');
            },

            /**
             * Get a list of locations available for selection.
             */
            availableLocations() {
                const locations = this.$store.getters.allArt.map((l) => ({
                    location: l,
                    image: getLocationImageUrl(this.viewController, l),
                    available: !l.filled
                        || (this.startingLocation && l.location === this.startingLocation.location)
                        || (this.selectedLocation && l.location === this.selectedLocation.location),
                    selected: this.selectedLocation ? l.location === this.selectedLocation.location : null,
                }));

                return locations;
            },

            /**
             * Calculate the actual placement based on the selected location and expected activity code.
             */
            selectedPlacement() {
                if (this.selectedLocation == null) {
                    return null;
                }

                const placement = this.selectedLocation.placements.find((x) => constants.ACTIVITY_ART.includes(x.placement.custom.activity));

                return placement;
            },

            /**
             * Currently selected component.
             */
            selectedComponent() {
                if (this.selectedPlacement == null) {
                    return null;
                }

                const component = this.viewController.selectedAtPlacement(this.selectedPlacement.placement.code);

                return component;
            },

            /**
             * Components available for selection.
             */
            availableOptions() {
                const options = [];

                if (this.selectedPlacement == null) {
                    return options;
                }

                const currentSelection = this.viewController.selectedAtPlacement(this.selectedPlacement.placement.code);

                this.selectedPlacement.placement.components.forEach((c) => {
                    if (c.placeholder) {
                        return;
                    }

                    options.push({
                        component: c,
                        image: makeComponentThumbnailUrl(1, c),
                        selected: c.code === currentSelection.code,
                    });
                });

                return options;
            },

            /**
             * Is the current selection complete?
             */
            isComplete() {
                if (this.selectedPlacement == null) {
                    return false;
                }

                const currentSelection = this.viewController.selectedAtPlacement(this.selectedPlacement.placement.code);

                if (currentSelection == null) {
                    return false;
                }

                if (currentSelection.placeholder) {
                    return false;
                }

                return true;
            },
        },
        watch: {
            /**
             * When a selected location changes, clear out options in the old place.
             */
            selectedLocation(newValue, oldValue) {
                if (oldValue) {
                    oldValue.placements.forEach((placement) => {
                        this.viewController.clearComponent(placement.placement.code);
                    });
                }
            },

            /**
             * When selected placement and location changes, zoom to that.
             */
            selectedPlacement() {
                this.$store.dispatch('setFocusedPlacement', {
                    placement: this.selectedPlacement,
                });
            },
        },
        mounted() {
            // When the component is loaded, capture the current state of the view controller and save it in case
            // we need to revert to the previous state and toss away all customizations.
            //
            // At any given time there should be only one module that can do the revert.
            this.captureController();

            // Zoom in initial placement.
            if (this.selectedPlacement) {
                this.$store.dispatch('setFocusedPlacement', {
                    placement: this.selectedPlacement,
                });
            }
        },
        methods: {
            /**
             * Captures the current state and active controller so we could cancel changes if necessary.
             */
            captureController() {
                if (this.viewController) {
                    this.revertController = this.viewController.clone();
                }
            },

            /**
             * Accept color choice.
             */
            accept() {
                // Keep the current configured view controller, and go back to the main screen.
                this.$store.dispatch('selectStep', null);
            },

            /**
             * Reject color changes and restore the original selection.
             */
            cancel() {
                // Revert back to the state of the controller as was captured when the component was mounted.
                this.$store.dispatch('showController', this.revertController);

                // Go back to the main screen.
                this.$store.dispatch('selectStep', null);
            },

            /**
             * Selects a location for personalization.
             */
            selectLocation(l) {
                this.selectedLocation = l.location;
            },

            /**
             * Selects an option for the selected placement.
             */
            selectOption(option) {
                if (this.selectedPlacement) {
                    this.viewController.updateComponent(this.selectedPlacement.placement.code, option.component.code);

                    // Adjust placement location according to placement alignment.
                    if (!this.selectedPlacement.placement.custom['placement-y-original']) {
                        this.selectedPlacement.placement.custom['placement-y-original'] = this.selectedPlacement.placement.custom['placement-y'];
                    }

                    const placementY = this.selectedPlacement.placement.custom['placement-y-original'];

                    const imageHeight = (option.component.custom['art-height'] || constants.DEFAULT_ART_HEIGHT)
                        * (+this.selectedPlacement.placement.custom['placement-scale']);

                    const placementHeight = this.selectedPlacement.placement.custom['personalization-height'];

                    let dy = 0;

                    switch (this.selectedPlacement.placement.custom['placement-vertical-alignment']) {
                    case 'top': {
                        dy = -(placementHeight - imageHeight) * 0.5;
                        break;
                    }
                    case 'bottom': {
                        dy = (placementHeight - imageHeight) * 0.5;
                        break;
                    }
                    default:
                        break;
                    }

                    this.selectedPlacement.placement.custom['placement-y'] = (+placementY + dy).toString();
                }
            },

            /**
             * Removes selected art from the current location.
             */
            removeArt() {
                if (this.selectedPlacement) {
                    this.viewController.clearComponent(this.selectedPlacement.placement.code);

                    // Go back to the main screen.
                    this.$store.dispatch('selectStep', null);
                }
            },
        },
    };
</script>

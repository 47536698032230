/**
 * Images size.
 */
export const BASE_IMAGE_SIZE = 2400;

/**
 * Location DPI.
 */
export const LOCATION_DPI = 300;

/**
 * How much to scale the product image thumbnail so the location icon would be visible.
 */
export const IMAGE_PLACEMENT_SCALE = 0.75;
export const IMAGE_PLACEMENT_SIZE = 600;

/**
 * Default (maximum) art image height.
 */
export const DEFAULT_ART_HEIGHT = 760;

/**
 * Main placement containing the product ID.
 */
export const PLACEMENT_PRODUCT = 'Product';

/**
 * Art activities
 */
export const ACTIVITY_ART = ['AM'];

/**
 * Embroidery activities.
 */
export const ACTIVITY_EMBROIDERY = ['ED', 'EM'];

/**
 * Embroidery activities - text on garment.
 */
export const ACTIVITY_EMBROIDERY_ED = ['ED'];

/**
 * Embroidery activities - patch rectangular.
 */
export const ACTIVITY_EMBROIDERY_EM_R = ['EM'];

/**
 * Embroidery activities - patch oval.
 */
export const ACTIVITY_EMBROIDERY_EM_O = ['EM'];

/**
 * Embroidery activities - any patches.
 */
export const ACTIVITY_EMBROIDERY_PATCHES = ['EM'];

/**
 * Suffix of placements containing patches.
 */
export const PATCH_PLACEMENT_SUFFIX = '-PATCH';

/**
 * Suffix of placements containing text.
 */
export const TEXT_PLACEMENT = 'ED';

/**
 * Suffix of placements containing text.
 */
export const TEXT_PLACEMENT_SUFFIX = '-TEXT';

/**
 * Logo activities.
 */
export const ACTIVITY_LOGO = ['EL', 'EH'];

/**
 * Locations located on the back of the product.
 */
export const LOCATIONS_BACK = ['CMB', '4J6'];

/**
 * Front image angle.
 */
export const FRAME_FRONT = 1;

/**
 * Back image angle.
 */
export const FRAME_BACK = 2;

/**
 * Level of zoom for focused placements.
 */
export const FOCUSED_ZOOM = 2;

/**
 * Default location sizes.
 */
export const DEFAULT_SIZES = [
    'BBC',
    '4x1',
    'BBY',
    '4x1.5',
    'CBC',
    '1x1',
    'CMB',
    '4x4',
    'LCC',
    '3x1',
    'LCR',
    '1x2',
    'LCT',
    '2x1',
    'LSH',
    '4x4',
    'LSS',
    '4x4',
    'LUF',
    '4x4',
    'RCC',
    '3x1',
    'RCT',
    '2x1',
    'RSC',
    '1x1',
    'RSH',
    '4x4',
    'RSS',
    '4x4',
    'RUF',
    '4x4',
    'TCR',
    '2x2',
    'TCL',
    '2x2',
    '2S7',
    '3x3',
    '3R7',
    '3x3',
    'FPL',
    '4x2.25',
    'LBP',
    '1.5x1',
    'LFP',
    '1.5x1',
    'LSP',
    '1.5x1',
    'OBP',
    '4x1.25',
    'RBP',
    '1x1',
    'RFP',
    '1.5x1',
    'RSP',
    '1.5x1',
    '1B7',
    '4x4',
    '1F7',
    '4x4',
    '1F8',
    '4x4',
    '1K7',
    '4x4',
    '1V2',
    '4x4',
    '2C4',
    '4x4',
    '2C9',
    '4x4',
    '2E6',
    '4x4',
    '2S7',
    '4x4',
    '3R7',
    '4x4',
    '4J6',
    '6x4',
    '5T4',
    '4x4',
    '5U5',
    '4x4',
    '5U6',
    '4x4',
    'BBY',
    '4x1.5',
    'CMB',
    '4x4',
    'CMF',
    '4x4',
    'CMP',
    '4x4',
    'FPL',
    '4x2.5',
    'LSS',
    '4x4',
    'LU1',
    '4x4',
    'LU9',
    '4x4',
    'LUF',
    '4x4',
    'RSS',
    '4x4',
    'RU1',
    '4x4',
    'RU9',
    '4x4',
    'RUF',
    '4x4',
];

/**
 * Default location size.
 */
export const DEFAULT_SIZE = '4x4';

/**
 * Placement base size in inches.
 */
export const PLACEMENT_SIZE = 8;

/**
 * Standard DPI setting.
 */
export const STANDARD_DPI = 72;

/**
 * Standard DPI setting.
 */
export const STANDARD_PIXELS = 300;

/**
 * Maximum number of characters per logo notes field.
 */
export const LOGO_NOTES_LIMIT = 140;

/**
 * Name of the definition with the embroidery thread.
 */
export const DEFINITION_THREAD = 'Embroidery Thread';

/**
 * Name of the definition with the embroidery fonts.
 */
export const DEFINITION_FONT = 'Embroidery Font';

/**
 * Name of the definition with the embroidery patches.
 */
export const DEFINITION_PATCH = 'Embroidery Patch';

/**
 * Width of the smallest desktop breakpoint, 'small' in variables.scss
 */
export const DESKTOP_BREAKPOINT_SMALL = 1024;

/**
 * Browser-calculated width of menu subpanel at desktop sizes.
 */
export const SUB_PANEL_DESKTOP_WIDTH = 324;

/**
 * List of all reasonable clothing size values.
 */
export const BODY_FITS = [
    'short',
    'regular',
    'long',
    'extra-long',
];

/**
 * List of all reasonable clothing size values.
 */
export const SIZE_ORDERING = [
    '4XS',
    '3XS',
    'XXS',
    'XS',
    'S',
    'M',
    'L',
    'XL',
    'XXL',
    '3XL',
    '4XL',
    '5XL',
    '6XL',
    '7XL',
    '8XL',
    '9XL',
    '10XL',
];

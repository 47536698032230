<template>
    <div class="cz2__panel cz2__panel--secondary cz2__sizes">
        <focus-lock>
            <div class="cz2__panel__body cz2__sizes__body" tabindex="0">
                <div class="cz2__type__small-header">
                    <span class="cz2__small-header__title">{{$t('SIZE_CHOOSE_FIT')}}</span>

                    <button v-if="sizeChartAvailable" class="cz2__what-size-cta" @click.prevent="showSizingChart">
                        {{$t('SIZE_WHAT_SIZE_AM_I')}}
                    </button>
                </div>

                <div v-for="attribute in availableOptions" :key="attribute.attribute" class="cz2__sizes__options">
                    <div class="cz2__type__mini-header">
                        {{attribute.title}}
                    </div>

                    <div class="cz2__size-options">
                        <button v-for="option in attribute.values"
                            :key="option.value"
                            :disabled="option.blocked"
                            :class="[
                                'cz2__size-option',
                                {
                                    'cz2__size-option--caps': attribute.attribute === 'body',
                                    'cz2__size-option--selected': option.selected,
                                }
                            ]" @click.prevent="selectSize(attribute, option)">
                            {{option.value}}
                        </button>
                    </div>
                </div>
            </div>

            <div class="cz2__panel__actions">
                <button class="cz2__panel-action" @click.prevent="cancel"><span>{{$t('ACTION_CANCEL')}}</span></button>
                <button class="cz2__panel-action cz2__panel-action--primary"
                    :disabled="!isComplete"
                    @click.prevent="accept">
                    <span>{{$t('ACTION_SAVE')}}</span>
                </button>
            </div>
        </focus-lock>
    </div>
</template>

<style lang="scss">
    @import "../style/variables.scss";

    #cz2.cz2 {
        .cz2__sizes__body {
            .cz2__type__small-header {
                display: flex;
                justify-content: space-between;
                align-items: center;
            }

            .cz2__small-header__title {
                font-family: $font-header;
                font-weight: 600;
                font-size: 20px;
                line-height: 30px;
                text-transform: capitalize;
            }
        }

        .cz2__what-size-cta {
            border-bottom: 1px solid $color-primary-dark;

            color: $color-primary-dark;

            font-family: $font-main;
            font-size: 12px;
            line-height: 18px;
            text-align: right;
            text-transform: capitalize;
        }

        .cz2__sizes__options {
            margin-top: 32px;

            @include respond-above(sm) {
                margin-top: 40px;
            }
        }

        .cz2__size-options {
            margin-top: 12px;
            display: flex;
            flex-wrap: wrap;
            gap: 8px;
        }

        .cz2__size-option {
            position: relative;

            padding: 10px 16px;
            width: auto; /* Client Styles Override */
            height: 40px;
            border: 1px solid $color-accent-light-gray;
            border-radius: 4px;

            color: $color-primary-dark;
            background: transparent;

            font-family: $font-main;
            font-size: 14px;
            font-weight: 500;
            line-height: 20px;

            transition: background-color 0.25s ease-in-out;

            &.cz2__size-option--caps {
                text-transform: capitalize;
            }

            &.cz2__size-option--selected {
                background: $color-accent-light-gray;
            }

            &:disabled {
                opacity: 0.25;
                pointer-events: none;
            }
        }
    }
</style>

<script>
    import Vue from 'vue';
    import FocusLock from 'vue-focus-lock';

    import * as constants from '../constants';

    export default {
        components: {
            FocusLock,
        },
        data() {
            const size = this.$store.state.customizer.selectedSize;

            return {
                lastAttribute: size ? 'size' : null,

                variant: {
                    size: size ? size.size : null,
                    body: size ? size.body : null,
                    neck: size ? size.neck : null,
                    sleeve: size ? size.sleeve : null,
                },
            };
        },
        computed: {
            /**
             * Current view controller.
             */
            viewController() {
                return this.$store.state.customizer.viewController;
            },

            /**
             * Selected size variant.
             */
            selectedSize() {
                return this.$store.state.customizer.selectedSize;
            },

            /**
             * Get variants collection.
             */
            variants() {
                return this.$store.state.customizer.variants;
            },

            /**
             * Retrives current lot number.
             */
            currentLot() {
                const product = this.viewController.selectedAtPlacement(constants.PLACEMENT_PRODUCT);

                const lot = product.code;

                return lot;
            },

            /**
             * Generate a list of available components.
             */
            availableOptions() {
                if (this.viewController == null) {
                    return [];
                }

                const lot = this.currentLot;

                const options = [];

                // Order body fits using standard ordering, buts undefined order values at end
                if (this.variants?.variationAttributes?.includes('body')) {
                    const bodyValues = this.collectUnique(lot, 'body');
                    const sortedBodyValues = this.sortFromSet(bodyValues, constants.BODY_FITS);
                    if (sortedBodyValues.length > 0) {
                        options.push({
                            attribute: 'body',
                            title: this.$t('SIZE_BODY'),
                            values: sortedBodyValues,
                        });
                    }
                }

                // Order sizes using standard ordering, puts undefined order values at end
                if (this.variants?.variationAttributes?.includes('size')) {
                    const sizeValues = this.collectUnique(lot, 'size');
                    const sizes = this.sortFromSet(sizeValues, constants.SIZE_ORDERING);
                    if (sizes.length > 0) {
                        options.push({
                            attribute: 'size',
                            title: this.$t('SIZE_SIZE'),
                            values: sizes,
                        });
                    }
                }

                // Order neck sizes
                if (this.variants?.variationAttributes?.includes('neck')) {
                    const neckSizes = this.collectUnique(lot, 'neck').sort(this.comparePossibleNumbers);
                    if (neckSizes.length > 0) {
                        options.push({
                            attribute: 'neck',
                            title: this.$t('SIZE_NECK'),
                            values: neckSizes,
                        });
                    }
                }

                // Order sleeve sizes
                if (this.variants?.variationAttributes?.includes('sleeve')) {
                    const sleeveSizes = this.collectUnique(lot, 'sleeve').sort(this.comparePossibleNumbers);
                    if (sleeveSizes.length > 0) {
                        options.push({
                            attribute: 'sleeve',
                            title: this.$t('SIZE_SLEEVE'),
                            values: sleeveSizes,
                        });
                    }
                }

                // If the user has selected an attribute value, else we can assume all values are unblocked
                if (this.lastAttribute) {
                    // Get current variant values
                    const currentVariantValues = options.map((option) => ({
                        attribute: option.attribute,
                        value: this.variant[option.attribute],
                    }));

                    // Look over current option sets (size, body, etc.)
                    options.forEach((option) => {
                        const optionAttribute = option.attribute;
                        const optionValues = option.values;
                        optionValues.forEach((optionValue) => {
                            const optionObj = {
                                attribute: optionAttribute,
                                value: optionValue.value,
                            };
                            const optionIsSelectable = this.hasSelectableVariant(optionObj, currentVariantValues);
                            optionValue.blocked = !optionIsSelectable;
                        });
                    });
                }

                return options;
            },

            /**
             * Get selected variant.
             */
            selectedVariant() {
                const values = this.availableOptions.map((a) => ({
                    attribute: a.attribute,
                    value: this.variant[a.attribute],
                }));

                const variant = this.hasVariant(values);

                return variant;
            },

            /**
             * Is the current selection complete?
             */
            isComplete() {
                let complete = true;

                const availableOptionKeys = this.availableOptions.map((option) => option.attribute);

                Object.keys(this.variant).forEach((k) => {
                    if (this.variant[k] == null && availableOptionKeys.includes(k)) {
                        complete = false;
                    }
                });

                return complete;
            },

            /**
             * Is size chart available?
             */
            sizeChartAvailable() {
                return this.variants ? this.variants.sizeChart : false;
            },
        },
        watch: {
            selectedVariant() {
                this.$store.dispatch('selectSize', this.selectedVariant);
            },
        },
        mounted() {
            this.captureVariant();
        },
        methods: {
            /**
             * Collects unique values of the given variation attribute, filters out null values.
             */
            collectUnique(lot, type) {
                const dimensions = [];

                this.variants.variants.forEach((v) => {
                    if (v.lot === lot) {
                        const dim = v[type];

                        if (dim != null) {
                            const exists = dimensions.find((d) => d.value === dim);

                            if (exists) {
                                if (v.available) {
                                    exists.available = true;
                                }
                            } else {
                                dimensions.push({
                                    value: dim,
                                    available: v.available,
                                    blocked: false,
                                    selected: this.variant && this.variant[type] === dim,
                                });
                            }
                        }
                    }
                });

                // Now that we've looked at every variant to see if they are available, we know if it's available at all
                const newDimensions = dimensions.filter(({ value }) => value !== null).map((dim) => {
                    dim.blocked = !dim.available;
                    return dim;
                });
                return newDimensions;
            },

            /**
             * Captures the current state and active controller so we could cancel changes if necessary.
             */
            captureVariant() {
                this.revertSize = this.selectedSize;
            },

            /**
             * Accept size choice.
             */
            accept() {
                const values = this.availableOptions.map((a) => ({
                    attribute: a.attribute,
                    value: this.variant[a.attribute],
                }));

                const variant = this.hasVariant(values);

                if (variant) {
                    this.$store.dispatch('selectSize', variant);
                }

                // Keep the current configured view controller, and go back to the main screen.
                this.$store.dispatch('selectStep', null);
            },

            /**
             * Reject size changes and restore the original selection.
             */
            cancel() {
                // Revert back to the state of the controller as was captured when the component was mounted.
                this.$store.dispatch('selectSize', this.revertSize);

                // Go back to the main screen.
                this.$store.dispatch('selectStep', null);
            },

            /**
             * Checks if there is a variant with given variation values.
             */
            hasVariant(values) {
                let found = null;

                // Look at all variants to find a match
                for (let variantIndex = 0; variantIndex < this.variants.variants.length; variantIndex += 1) {
                    const variant = this.variants.variants[variantIndex];

                    // Must match variant lot (same color)
                    if (variant.lot === this.currentLot) {
                        let match = true;

                        // Compare each value for each selected attribute value to the current value for the given variant
                        // Must match on all values, accept null as a match
                        for (let aIndex = 0; aIndex < values.length; aIndex += 1) {
                            if (values[aIndex].value !== variant[values[aIndex].attribute] && values[aIndex].value !== null) {
                                match = false;
                                break;
                            }
                        }

                        // The match must be available to be a sutiable variant
                        if (match && variant.available) {
                            found = variant;
                            break;
                        }
                    }
                }

                return found;
            },

            /**
             * Is there a variant that can be selected based on the current variant values
             */
            hasSelectableVariant(variantValues, currentVariantValues) {
                // Create deep copy of current variants
                const overloadedValues = currentVariantValues.map((currentVariantValue) => ({
                    attribute: currentVariantValue.attribute,
                    value: currentVariantValue.value,
                }));

                // Get the attribute obj that should be overloaded with the variant value
                const attributeToUpdate = overloadedValues.find((value) => value.attribute === variantValues.attribute);

                // Check if the variant value has already been selected
                const currentVarientValue = attributeToUpdate.value;
                const variantValue = variantValues.value;
                if (variantValue === currentVarientValue) {
                    return true;
                }

                // Check for variant with updated value
                attributeToUpdate.value = variantValues.value;
                const variant = this.hasVariant(overloadedValues);
                return variant !== null;
            },

            /**
             * Selects a size component, and makes sure that sizes are properly selected.
             */
            selectSize(attribute, option) {
                const values = this.availableOptions.map((a) => ({
                    attribute: a.attribute,
                    value: this.variant[a.attribute],
                }));

                this.lastAttribute = attribute.attribute;
                values.find((v) => v.attribute === attribute.attribute).value = option.value;

                // Accept the change.
                Vue.set(this.variant, attribute.attribute, option.value);

                // Is there an exact selection.
                const found = this.hasVariant(values);

                if (found == null) {
                    // Clear out attributes that don't match the current one.
                    Object.keys(this.variant).forEach((key) => {
                        if (key !== attribute.attribute) {
                            Vue.set(this.variant, key, null);
                        }
                    });
                }
            },

            /**
             * Show sizing chart
             */
            showSizingChart() {
                this.$store.dispatch('showSizingChart');
            },

            /**
             * Compares two numbers
             */
            comparePossibleNumbers(a, b) {
                const aInt = parseFloat(a.value, 10);
                const bInt = parseFloat(b.value, 10);
                if (!Number.isNaN(aInt) && !Number.isNaN(bInt)) {
                    return aInt - bInt;
                }
                return a - b;
            },

            /**
             * Compares values from a fixed set, allowing for exceptions
             */
            sortFromSet(values, orderingRef) {
                return values.sort((a, b) => {
                    const aOrder = orderingRef.indexOf(a.value);
                    const bOrder = orderingRef.indexOf(b.value);

                    // Either value not found in set
                    if (aOrder === -1 || bOrder === -1) {
                        // Try comparing as numbers first
                        const numberCompare = this.comparePossibleNumbers(a, b);
                        if (numberCompare !== 0) {
                            return numberCompare;
                        }

                        // A is unknown string
                        if (aOrder === -1) { return 1; }

                        // B is unknown string
                        return 1;
                    }

                    return aOrder - bOrder;
                });
            },
        },
    };
</script>

var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"cz2__panel cz2__panel--secondary cz2__colors"},[_c('focus-lock',[_c('div',{staticClass:"cz2__panel__body cz2__colors__body",attrs:{"tabindex":"0"}},[_c('div',{staticClass:"cz2__colors__body__inner"},_vm._l((_vm.availableColors),function(color){return _c('button',{key:color.component.code,class:[
                    'cz2__color-option',
                    {
                        'cz2__color-option--selected': color.selected,
                        'cz2__color-option--not-available': !color.available,
                    }
                ],on:{"click":function($event){$event.preventDefault();return _vm.selectColor(color)}}},[_c('span',{staticClass:"cz2__color-option__color"},[_c('span',{staticClass:"cz2__color-option__color__inner",style:(_vm.getSwatchBackground(color))})]),_c('span',{staticClass:"cz2__color-option__name"},[_vm._v(" "+_vm._s(color.lot.name)+" ")])])}),0)]),_c('div',{staticClass:"cz2__panel__actions"},[_c('button',{staticClass:"cz2__panel-action",on:{"click":function($event){$event.preventDefault();return _vm.cancel.apply(null, arguments)}}},[_c('span',[_vm._v(_vm._s(_vm.$t('ACTION_CANCEL')))])]),_c('button',{staticClass:"cz2__panel-action cz2__panel-action--primary",on:{"click":function($event){$event.preventDefault();return _vm.accept.apply(null, arguments)}}},[_c('span',[_vm._v(_vm._s(_vm.$t('ACTION_SAVE')))])])])])],1)
}
var staticRenderFns = []

export { render, staticRenderFns }
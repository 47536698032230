<template>
    <div class="cz2__header-bar">
        <button class="cz2__header-bar__close" :aria-label="$t('MENU_CLOSE')" @click.prevent="$emit('close')">
        </button>
        <!-- <div class="cz2__header-bar__title">{{$t(title, { index: '' })}}</div> -->
        <div class="cz2__header-bar__title">{{$t('MENU_HEADER')}}</div>
    </div>
</template>

<style lang="scss">
    @import "../style/variables.scss";

    #cz2.cz2 {

        .cz2__header-bar {
            position: relative;

            display: flex;
            align-items: center;
            justify-content: center;

            background: $color-primary-light;
            padding: 8px 60px;

            @include respond-above(sm) {
                position: absolute;

                top: 20px;
                left: 20px;
                height: auto;

                background: transparent;
                padding: 0;
            }
        }

        .cz2__header-bar__close {
            position: absolute;
            left: 20px;
            top: 50%;
            z-index: 2;

            width: 22px;
            height: 22px;
            display: flex;
            align-items: center;
            justify-content: center;

            transform: translate(0, -50%);

            cursor: pointer;

            @include respond-above(tablet) {
                width: 40px;
                height: 40px;
            }

            @include respond-above(sm) {
                position: static;
                transform: translate(0);
            }

            &:after {
                content: '';

                width: 17px;
                height: 17px;

                background: transparent url(../assets/close-red@2x.png) no-repeat center/contain;

                @include respond-above(tablet) {
                    width: 20px;
                    height: 20px;
                }

            }
        }

        .cz2__header-bar__title {
            color: $color-primary-dark;
            font-family: $font-header;
            font-size: 24px;
            font-weight: 600;
            line-height: 32px;

            @include respond-above(tablet) {
                font-size: 24px;
                font-weight: 500;
                line-height: 28px;
            }

            @include respond-above(sm) {
                display: none;
            }
        }
    }
</style>

<script>
    export default {
        computed: {
            title() {
                const { selectedStep } = this.$store.state;

                // Check for edit emboridery across placements
                if (selectedStep && selectedStep.indexOf('edit-embroidery-') !== -1) {
                    return 'MENU_EMBROIDERY_EDIT';
                }

                const stepTitles = {
                    colors: 'MENU_CHOOSE_COLOR',
                    sizes: 'SIZE_CHOOSE_FIT',
                    'new-art': 'MENU_ART_ADD',
                    'edit-art': 'MENU_ART',
                    'new-logo': 'MENU_LOGO_ADD',
                    'edit-logo': 'MENU_LOGO',
                    'new-embroidery': 'MENU_EMBROIDERY_ADD',
                };

                const stepTitle = stepTitles[selectedStep];
                return stepTitle !== undefined ? stepTitle : 'MENU_HEADER';
            },
        },
    };
</script>

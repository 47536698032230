import Vue from 'vue';
import Vuex from 'vuex';
import axios from 'axios';
import VueI18n from 'vue-i18n';
import PortalVue from 'portal-vue';
import AsyncComputed from 'vue-async-computed';

import Multiselect from 'vue-multiselect';

import './style/shared.scss';
import 'vue-multiselect/dist/vue-multiselect.min.css';

import App from './App.vue';
import store from './store';

import i18n from './strings';

Vue.use(Vuex);
Vue.use(VueI18n);
Vue.use(PortalVue);
Vue.use(AsyncComputed);

// eslint-disable-next-line vue/multi-word-component-names
Vue.component('multiselect', Multiselect);

Vue.config.productionTip = false;

// Reusable transitions.
Vue.component('FadeTransition', {
    functional: true,
    render(createElement, context) {
        const data = {
            props: {
                name: 'cz2__fade',
            },
        };

        return createElement('transition', data, context.children);
    },
});

/**
 * Loads Customizer backend script, also checking if it was already loaded.
 * @param  {[type]} url [description]
 * @return {[type]}     [description]
 */
function loadScript(url) {
    return new Promise((resolve) => {
        // Use a simple script import.
        // UMD is not supported yet since Vue library mode relies on document.currentScript being available,
        // which doesn't work currently inside requirejs modules.
        // Check if already loaded.
        if ('DriveCustomizer' in window) {
            setTimeout(() => {
                resolve(window.DriveCustomizer);
            });
        } else {
            const script = document.createElement('script');
            script.addEventListener('load', () => resolve(window.DriveCustomizer));
            script.src = url;
            document.head.appendChild(script);
        }
    });
}

// Main entry point to the widget.
function createWidget(options) {
    let rootInstance = null;

    const rootStore = new Vuex.Store(store);

    // Load the API script and other dependencies.
    Promise.all([
        loadScript('//api.customizer.drivecommerce.com/api/v2/runtime/client?type=production'),
    ]).then(([DriveCustomizer]) => {
        options.DriveCustomizer = DriveCustomizer;

        if (options.getVariantsEndpoint) {
            options.getVariants = (product) => {
                const url = `${options.getVariantsEndpoint}?pid=${product}`;

                return axios.get(url).then((response) => response.data);
            };
        }

        if (options.formatPriceEndpoint) {
            const prices = {};

            options.formatPrice = (price) => {
                if (prices[price]) {
                    return Promise.resolve(prices[price]);
                }

                const url = `${options.formatPriceEndpoint}?value=${price}`;

                return axios.get(url).then((response) => response.data.price);
            };
        }

        if (options.productDataEndpoint) {
            options.getProductData = (products) => {
                const url = `${options.productDataEndpoint}?products=${products.join(',')}`;

                return axios.get(url).then((response) => response.data.productData);
            };
        }

        if (options.artworkEndpoint) {
            options.getArtworkData = () => {
                const url = options.artworkEndpoint;

                return axios.get(url).then((response) => response.data);
            };
        }

        rootInstance = new Vue({
            // Create a new store each time.
            store: rootStore,
            i18n: i18n(options.locale || 'en', options.translations),
            render: (h) => h(App, {
                props: {
                    options,
                },
                on: {
                    selfDestruct() {
                        rootInstance.$destroy();

                        // Unmount from DOM manually since there is no counterpart in Vue API.
                        if (rootInstance.$el.parentNode) {
                            rootInstance.$el.parentNode.removeChild(rootInstance.$el);
                        }
                    },
                },
            }),
        }).$mount(options.container);
    });

    function cancel() {
        if (rootStore) {
            rootStore.dispatch('globalCancel');
        }
    }

    return {
        cancel,
    };
}

function renderProgram(options) {
    const rootStore = new Vuex.Store(store);

    // Load the API script and other dependencies.
    Promise.all([
        loadScript('//api.customizer.drivecommerce.com/api/v2/runtime/client?type=production'),
    ]).then(([DriveCustomizer]) => {
        options.DriveCustomizer = DriveCustomizer;

        if (options.getVariantsEndpoint) {
            options.getVariants = (product) => {
                const url = `${options.getVariantsEndpoint}?pid=${product}`;

                return axios.get(url).then((response) => response.data);
            };
        }

        if (options.formatPriceEndpoint) {
            const prices = {};

            options.formatPrice = (price) => {
                if (prices[price]) {
                    return Promise.resolve(prices[price]);
                }

                const url = `${options.formatPriceEndpoint}?value=${price}`;

                return axios.get(url).then((response) => response.data.price);
            };
        }

        if (options.productDataEndpoint) {
            options.getProductData = (products) => {
                const url = `${options.productDataEndpoint}?products=${products.join(',')}`;

                return axios.get(url).then((response) => response.data.productData);
            };
        }

        rootStore.dispatch('setOptions', options);
        rootStore.dispatch('setCustomizerOptions', options);
        rootStore.dispatch('setEcommerceOptions', options);

        // Manually kick off the flow.
        rootStore.dispatch('loadProgram').then(() => {
        });
    });
}

// Expose the widget library namespace.
export default {
    createWidget,
    renderProgram,
};

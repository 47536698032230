<template>
    <div class="cz2__panel cz2__panel--secondary cz2__edit-text">
        <focus-lock>
            <div class="cz2__panel__body cz2__edit-text__body" tabindex="0">
                <div class="cz2__panel-section__header cz2__type__small-header">
                    {{$t('EDIT_CHOOSE_LOCATION')}}
                </div>

                <div class="cz2__edit-locations cz2__sub-menu__options">
                    <button v-for="l in availableLocations" :key="l.location.location" :class="[
                        'cz2__edit-location',
                        'cz2__sub-menu-option',
                        {
                            'cz2__edit-location--selected': l.selected,
                        },
                        {
                            'cz2__sub-menu-option--selected': l.selected,
                        },
                    ]"  :disabled="!l.available"
                        :title="l.name"
                        @click.prevent="selectLocation(l)">
                        <span class="cz2__edit-location__image cz2__sub-menu-option__image" :style="{
                            backgroundImage: `url(${l.image})`
                        }"></span>
                        <span class="cz2__edit-location__name cz2__sub-menu-option__name">
                            {{$t('ACTION_ADD')}} {{l.location.name}}
                        </span>
                    </button>
                </div>

                <template v-if="selectedLocation">
                    <div class="cz2__panel-section">
                        <div class="cz2__panel-section__header cz2__type__small-header">
                            {{$t('EDIT_TEXT_CHOOSE_STYLE')}}
                        </div>

                        <div class="cz2__text-options cz2__sub-menu__options">
                            <button
                                :class="[
                                    'cz2__text-option',
                                    'cz2__sub-menu-option',
                                    {
                                        'cz2__text-option--selected': selectedStyle === 'ED',
                                    },
                                    {
                                        'cz2__sub-menu-option--selected': selectedStyle === 'ED',
                                    },
                                ]"
                                :title="$t('EDIT_TEXT_STYLE_ED')"
                                @click.prevent="selectStyle('ED')"
                            >
                                <span class="cz2__text-option__image cz2__text-option__image--style-ed cz2__sub-menu-option__image"></span>

                                <span class="cz2__edit-location__name cz2__sub-menu-option__name">
                                    {{$t('EDIT_TEXT_STYLE_ED')}}
                                </span>
                            </button>

                            <button
                                :class="[
                                    'cz2__text-option',
                                    'cz2__sub-menu-option',
                                    {
                                        'cz2__text-option--selected': selectedStyle === 'EM-R',
                                    },
                                    {
                                        'cz2__sub-menu-option--selected': selectedStyle === 'EM-R',
                                    },
                                ]"
                                :title="$t('EDIT_TEXT_STYLE_EM_R')"
                                @click.prevent="selectStyle('EM-R')"
                            >
                                <span class="cz2__text-option__image cz2__text-option__image--style-em-r cz2__sub-menu-option__image"></span>

                                <span class="cz2__edit-location__name cz2__sub-menu-option__name">
                                    {{$t('EDIT_TEXT_STYLE_EM_R')}}
                                </span>
                            </button>

                            <button
                                :class="[
                                    'cz2__text-option',
                                    'cz2__sub-menu-option',
                                    {
                                        'cz2__text-option--selected': selectedStyle === 'EM-O',
                                    },
                                    {
                                        'cz2__sub-menu-option--selected': selectedStyle === 'EM-O',
                                    },
                                ]"
                                :title="$t('EDIT_TEXT_STYLE_EM_O')"
                                @click.prevent="selectStyle('EM-O')"
                            >
                                <span class="cz2__text-option__image cz2__text-option__image--style-em-o cz2__sub-menu-option__image"></span>

                                <span class="cz2__edit-location__name cz2__sub-menu-option__name">
                                    {{$t('EDIT_TEXT_STYLE_EM_O')}}
                                </span>
                            </button>
                        </div>
                    </div>

                    <div v-if="availablePatches.length > 0">
                        <div class="cz2__text-patches">
                            <div class="cz2__form-field">
                                <multiselect v-model="selectedPatchSize"
                                    :options="availablePatchSizes"
                                    :searchable="false"
                                    :allow-empty="false"
                                    placeholder=""
                                    selectLabel=""
                                    deselectLabel=""
                                    id="cz2__select-patch-size"
                                ></multiselect>

                                <label for="cz2__select-patch-size" class="cz2__form-label cz2__form-label--overlaid">{{$t('EDIT_TEXT_SIZE')}}</label>
                            </div>

                            <div class="cz2__form-field">

                                <multiselect v-model="selectedPatchColor"
                                    :options="availablePatchColors"
                                    :searchable="false"
                                    :allow-empty="false"
                                    placeholder=""
                                    selectLabel=""
                                    deselectLabel=""
                                    id="cz2__select-patch-color"
                                >
                                    <template slot="singleLabel" slot-scope="props">
                                        <span class="cz2__text-option__swatch" :style="{ background: '#' + props.option.hex }"></span>
                                        <span class="option__desc">
                                            <span class="option__title">{{props.option.name}}</span>
                                        </span>
                                    </template>
                                    <template slot="option" slot-scope="props">
                                        <span class="cz2__text-option__swatch" :style="{ background: '#' + props.option.hex }"></span>
                                        <span class="option__desc">
                                            <span class="option__title">{{props.option.name}}</span>
                                        </span>
                                    </template>
                                </multiselect>

                                <label for="cz2__select-patch-color" class="cz2__form-label cz2__form-label--overlaid">{{$t('EDIT_TEXT_COLOR')}}</label>
                            </div>
                        </div>
                    </div>

                    <div v-if="selectedStyle && textPlacement" class="cz2__panel-section">
                        <div class="cz2__panel-section__header cz2__type__small-header">
                            {{$t('EDIT_TEXT_EDIT_TEXT')}}
                        </div>

                        <div class="cz2__stylize-text">
                            <div v-if="isProgramMode" class="cz2__form-field">
                                <label class="cz2__form-label">
                                    {{$t('PROGRAM_PICK_TEMPLATE')}}
                                </label>

                                <div class="cz2__form-field">
                                    <div class="cz2__warning">
                                        <div class="cz2__warning-icon" aria-hidden="true"></div>
                                        <span class="cz2__warning-text" v-html="$t('PROGRAM_TEMPLATE_HINT')"></span>
                                    </div>
                                </div>

                                <div class="cz2__form-field cz2__templates-limits">
                                    <div class="cz2__warning">
                                        <div class="cz2__warning-icon" aria-hidden="true"></div>
                                        <template v-if="selectedFontSize">
                                            <div>
                                                <div class="cz2__warning-text cz2__form-label__hint-line-1">{{multilineHint}}</div>
                                                <div class="cz2__warning-text cz2__form-label__hint-line-2">{{charactersHint}}</div>
                                            </div>
                                        </template>
                                        <template v-else>
                                            <div class="cz2__warning-text cz2__form-label__hint-line--long">{{$t('EDIT_TEXT_FONT_LIMIT_CHANGE')}}</div>
                                        </template>
                                    </div>
                                </div>

                                <div class="cz2__form-field">
                                    <div class="cz2__templates">
                                        <div v-for="(line, index) in programLines" :key="index">
                                            <program-line
                                                :index="index"
                                                :line="line"
                                                :templates="textTemplates"
                                                :samples="textSamples"
                                                :placement="textPlacement"
                                                @update="updateLine($event, line)"
                                            ></program-line>
                                        </div>
                                    </div>
                                </div>

                                <div v-if="customTextTemplateSelected" class="cz2__templates-variables">
                                    {{$t('PROGRAM_VARIABLES')}} {{textVariables}}
                                </div>
                            </div>

                            <div v-if="!isProgramMode" class="cz2__form-field">
                                <!-- empty placeholder is necessary for the fancy label transition - do not remove :) -->
                                <input
                                    v-if="multiline === 1"
                                    type="text"
                                    id="cz2__embroidery-text"
                                    class="cz2__form-text"
                                    placeholder=" "
                                    v-model.trim="selectedText"
                                    @input="updatePersonalization"
                                />
                                <textarea v-else class="cz2__form-textarea"
                                    id="cz2__embroidery-text"
                                    :rows="multiline"
                                    placeholder=" "
                                    v-model.trim="selectedText"
                                    @input="updatePersonalization"
                                />

                                <label for="cz2__embroidery-text" class="cz2__form-label cz2__form-label--overlaid">
                                    {{$t('EDIT_TEXT_ENTER')}}
                                </label>

                                <template v-if="selectedFontSize">
                                    <span class="cz2__form-label__hint cz2__form-label__hint--2-lines">
                                        <span class="cz2__form-label__hint-line-1">{{multilineHint}}</span>

                                        <span class="cz2__form-label__hint-line-2">{{charactersHint}}</span>
                                    </span>
                                </template>
                                <template v-else>
                                    <span class="cz2__form-label__hint">
                                        <span class="cz2__form-label__hint-line--long">{{$t('EDIT_TEXT_FONT_LIMIT_CHANGE')}}</span>
                                    </span>
                                </template>

                                <div v-if="invalid" class="cz2__warning cz2__warning--critical">
                                    {{errorText}}
                                </div>

                                <div class="cz2__warning">
                                    <div class="cz2__warning-icon" aria-hidden="true"></div>

                                    <span class="cz2__warning-text">{{$t('EDIT_TEXT_EXACT')}}</span>
                                </div>
                            </div>

                            <div class="cz2__form-field">
                                <multiselect v-model="selectedFontName"
                                    :options="availableFontNames"
                                    :searchable="false"
                                    :allow-empty="false"
                                    placeholder=""
                                    selectLabel=""
                                    deselectLabel=""
                                    id="cz2__embroidery-font"
                                >
                                    <template slot="singleLabel" slot-scope="props">
                                        <span class="option__desc">
                                            <span class="option__title" :style="{ fontFamily: getFontFamily(props.option) }">
                                                {{props.option}}
                                            </span>
                                        </span>
                                    </template>
                                    <template slot="option" slot-scope="props">
                                        <span class="option__desc">
                                            <span class="option__title" :style="{ fontFamily: getFontFamily(props.option) }">
                                                {{props.option}}
                                            </span>
                                        </span>
                                    </template>
                                </multiselect>

                                <label class="cz2__form-label cz2__form-label--overlaid" for="cz2__embroidery-font">
                                    {{$t('EDIT_TEXT_FONT')}}
                                </label>
                            </div>

                            <div class="cz2__form-field">
                                <multiselect v-model="selectedFontSize"
                                    :options="availableFontSizes"
                                    track-by="code"
                                    label="description"
                                    :searchable="false"
                                    :allow-empty="false"
                                    placeholder=""
                                    selectLabel=""
                                    deselectLabel=""
                                    id="cz2__embroidery-size"
                                >
                                </multiselect>

                                <label class="cz2__form-label cz2__form-label--overlaid" for="cz2__embroidery-size">
                                    {{$t('EDIT_TEXT_FONT_SIZE')}}
                                </label>
                            </div>

                            <div class="cz2__form-field">
                                <multiselect v-model="selectedThreadColor"
                                    :options="availableThreads"
                                    track-by="hex"
                                    :searchable="false"
                                    :allow-empty="false"
                                    placeholder=""
                                    selectLabel=""
                                    deselectLabel=""
                                    id="cz2__embroidery-color"
                                >
                                    <template slot="singleLabel" slot-scope="props">
                                        <span class="cz2__text-option__swatch" :style="{ background: '#' + props.option.hex }"></span>
                                        <span class="option__desc">
                                            <span class="option__title">{{props.option.definition.description}}</span>
                                        </span>
                                    </template>
                                    <template slot="option" slot-scope="props">
                                        <span class="cz2__text-option__swatch" :style="{ background: '#' + props.option.hex }"></span>
                                        <span class="option__desc">
                                            <span class="option__title">{{props.option.definition.description}}</span>
                                        </span>
                                    </template>
                                </multiselect>

                                <label class="cz2__form-label cz2__form-label--overlaid" for="cz2__embroidery-color">
                                    {{$t('EDIT_TEXT_THREAD')}}
                                </label>
                            </div>
                        </div>
                    </div>
                </template>

                <template v-if="isComplete">
                    <div class="cz2__edit-text__flex-space">
                    </div>

                    <div class="cz2__remove-component">
                        <button @click.prevent="removeText"><span>{{$t('EDIT_TEXT_REMOVE_ICON')}}</span></button>
                    </div>
                </template>
            </div>

            <div class="cz2__panel__actions">
                <button class="cz2__panel-action" @click.prevent="cancel"><span>{{$t('ACTION_CANCEL')}}</span></button>
                <button class="cz2__panel-action cz2__panel-action--primary"
                    :disabled="!isComplete || isValidating"
                    @click.prevent="accept">
                    <span v-if="isValidating">
                        <!-- eslint-disable -->
                        <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 512 512"><!--!Font Awesome Pro 6.5.1 by @fontawesome - https://fontawesome.com License - https://fontawesome.com/license (Commercial License) Copyright 2024 Fonticons, Inc.--><path class="fa-secondary" opacity=".4" d="M346.5 120.2c-12.5 12.5-12.5 32.8 0 45.3s32.8 12.5 45.3 0L437 120.2c12.5-12.5 12.5-32.8 0-45.3s-32.8-12.5-45.3 0l-45.3 45.3zM384 256c0 17.7 14.3 32 32 32h64c17.7 0 32-14.3 32-32s-14.3-32-32-32H416c-17.7 0-32 14.3-32 32zM256 384c-17.7 0-32 14.3-32 32v64c0 17.7 14.3 32 32 32s32-14.3 32-32V416c0-17.7-14.3-32-32-32zm135.8-37.5c-12.5-12.5-32.8-12.5-45.3 0s-12.5 32.8 0 45.3L391.8 437c12.5 12.5 32.8 12.5 45.3 0s12.5-32.8 0-45.3l-45.3-45.3zM75 437c12.5 12.5 32.8 12.5 45.3 0l45.3-45.3c12.5-12.5 12.5-32.8 0-45.3s-32.8-12.5-45.3 0L75 391.8c-12.5 12.5-12.5 32.8 0 45.3z"/><path class="fa-primary" d="M256 0c-17.7 0-32 14.3-32 32V96c0 17.7 14.3 32 32 32s32-14.3 32-32V32c0-17.7-14.3-32-32-32zM0 256c0 17.7 14.3 32 32 32H96c17.7 0 32-14.3 32-32s-14.3-32-32-32H32c-17.7 0-32 14.3-32 32zM120.2 75C107.7 62.5 87.5 62.5 75 75s-12.5 32.8 0 45.3l45.3 45.3c12.5 12.5 32.8 12.5 45.3 0s12.5-32.8 0-45.3L120.2 75z"/></svg>
                        <!-- eslint-enable -->
                    </span>
                    <span v-else>{{$t('ACTION_SAVE')}}</span>
                </button>
            </div>
        </focus-lock>
    </div>
</template>

<style lang="scss">
    @import "../style/variables.scss";

    #cz2.cz2 {
        .cz2__panel__body.cz2__edit-text__body {
            display: flex;

            flex-direction: column;
            justify-content: flex-start;
            align-items: stretch;

            & > * {
                flex: 0 0 auto;
            }

            & > .cz2__edit-text__flex-space {
                height: 1px;

                flex: 1 1 auto;
            }

            .cz2__panel-section__header {
                font-family: $font-header;
                font-size: 20px;
                line-height: 30px;
            }
        }

        .cz2__templates-variables {
            margin-top: 10px;

            color: #656565;
            font-family: $font-main;
            font-size: 12px;
            font-weight: 400;
            line-height: 22px;
        }

        .cz2__template-button {
            position: relative;

            flex: 1 1 45%;

            padding: 8px;

            border: 1px solid #c1c1c1;
            border-radius: 0;

            color: #656565;
            font-family: $font-main;
            font-size: 14px;
            font-weight: 400;
            line-height: 16px;

            border-radius: 4px;

            &:after {
                content: '';

                position: absolute;

                right: -4px;
                top: -4px;

                width: 17px;
                height: 17px;

                background: transparent url(../assets/selected@2x.png) no-repeat center/contain;

                opacity: 0;

                z-index: 2;

                transition: opacity $transition-fast ease-out;
            }

            &.cz2__template-button--selected {
                &:after {
                    opacity: 1;
                }
            }
        }

        .cz2__templates-limits {
            color: #656565;
            font-family: $font-main;
            font-size: 12px;
            font-weight: 400;
            line-height: 22px;

            letter-spacing: 0px;
            text-transform: none;

            div {
                max-width: none;
            }
        }

        .cz2__text-options {
            display: flex;
            flex-wrap: wrap;

            margin-top: 17px;

            margin-left: -4px;
            margin-right: -4px;
        }

        .cz2__text-option {
            flex: 0 0 auto;

            &:disabled {
                opacity: 0.2;
            }
        }

        .cz2__text-option__image {

            &.cz2__text-option__image--style-ed {
                background-image: url(../assets/style-text-only@2x.png);
            }

            &.cz2__text-option__image--style-em-r {
                background-image: url(../assets/style-patch-rect@2x.png);
            }

            &.cz2__text-option__image--style-em-o {
                background-image: url(../assets/style-patch-oval@2x.png);
            }
        }

        .cz2__text-patches {
            margin-top: 25px;
        }

        .cz2__text-option__swatch {
            display: inline-block;

            width: 22px;
            height: 22px;

            margin-right: 9px;

            border-radius: 22px;

            vertical-align: -5px;
        }

        .cz2__stylize-text {
            margin-top: 36px;
            display: flex;
            flex-direction: column;
        }

        .cz2__form-field {
            position: relative;

            .multiselect--active + .cz2__form-label--overlaid,
            .multiselect:has(.multiselect__single) + .cz2__form-label--overlaid {
                font-size: 10px;
                line-height: 16px;

                transform: translate(0, 0);
            }

            .multiselect--active:not(.multiselect--above) + .cz2__form-label--overlaid {
                // necessary for maintaining visibility
                // on the MultiSelect labels throughout
                // the transition
                z-index: 51;
            }
        }

        .cz2__form-text {
            padding: 16px 10px;
            border: 1px solid $color-accent-light-gray;
            border-radius: 4px;

            color: $color-primary-dark;
            background: $color-primary-light;

            font-family: $font-main;
            font-weight: 500;
            font-size: 14px;
            line-height: 20px;

            &:focus + .cz2__form-label--overlaid,
            &:not(:placeholder-shown) + .cz2__form-label--overlaid {
                font-size: 10px;
                line-height: 16px;

                transform: translate(0, 0);
            }
        }

        .cz2__form-label {
            color: $color-primary-dark;

            font-family: $font-main;
            font-weight: 500;
            font-size: 14px;
            line-height: 20px;
            letter-spacing: 0;

            opacity: 0.6;

            &.cz2__form-label--overlaid {
                position: absolute;
                // something of a magic number, -17px appears to
                // match the label placement in the comps.
                // we'll utilize transform to move it to its initial
                // placement of being centered within the input.
                top: -17px;
                left: 0;

                margin: unset;

                text-transform: capitalize;
                letter-spacing: initial;

                transform: translate(10px, 33px);
                transition: all 0.25s ease-in-out;
                pointer-events: none;
            }
        }

        .cz2__form-reminder {
            position: absolute;
            top: 0;
            left: 0;

            display: none;

            color: $color-primary-dark;

            font-family: $font-main;
            font-weight: 500;
            font-size: 10px;
            line-height: 16px;

            opacity: 0;
        }
    }
</style>

<script>
    import Vue from 'vue';
    import FocusLock from 'vue-focus-lock';

    import * as constants from '../constants';

    import ProgramLine from './ProgramLine.vue';

    import { getLocationImageUrl, makeComponentThumbnailUrl } from './imagehelpers';

    import isFilled from '../utilities/isFilled';

    const CUSTOM_TEXT = '{{custom}}';

    export default {
        components: {
            FocusLock,
            ProgramLine,
        },
        data() {
            let startingLocation = null;

            const { selectedStep } = this.$store.state;
            const isExisting = selectedStep.startsWith('edit-');

            if (isExisting) {
                const code = selectedStep.replace('edit-embroidery-', '');

                startingLocation = this.$store.getters.filledEmbroidery.find((l) => l.location === code);
            }

            return {
                selectedLocation: startingLocation,
                startingLocation,

                selectedStyle: null,

                selectedPatchSize: null,
                selectedPatchColor: null,

                selectedText: null,

                selectedThreadColor: null,

                selectedFontName: null,
                selectedFontSize: null,

                limitPerLine: null,

                isValidating: false,

                invalid: false,
                errorText: null,

                programLines: [],
            };
        },
        computed: {
            /**
             * Current view controller.
             */
            viewController() {
                return this.$store.state.customizer.viewController;
            },

            /**
             * Selected step.
             */
            selectedStep() {
                return this.$store.state.selectedStep;
            },

            /**
             * New customization or edit existing?
             */
            isNew() {
                return this.selectedStep.startsWith('new-');
            },

            /**
             * Get a list of locations available for selection.
             */
            availableLocations() {
                const locations = this.$store.getters.allEmbroidery.map((l) => ({
                    location: l,
                    image: getLocationImageUrl(this.viewController, l),
                    available: !l.filled
                        || (this.startingLocation && l.location === this.startingLocation.location)
                        || (this.selectedLocation && l.location === this.selectedLocation.location),
                    selected: this.selectedLocation ? l.location === this.selectedLocation.location : null,
                }));

                return locations;
            },

            /**
             * Calculate the actual placement based on the selected location and expected activity code.
             */
            selectedPlacements() {
                if (this.selectedLocation == null) {
                    return null;
                }

                let activities = constants.ACTIVITY_EMBROIDERY_ED;

                if (this.selectedStyle === 'ED') {
                    activities = constants.ACTIVITY_EMBROIDERY_ED;
                }

                if (this.selectedStyle === 'EM-R') {
                    activities = constants.ACTIVITY_EMBROIDERY_EM_R;
                }

                if (this.selectedStyle === 'EM-O') {
                    activities = constants.ACTIVITY_EMBROIDERY_EM_O;
                }

                const placement = this.selectedLocation.placements.filter((x) => activities.includes(x.placement.custom.activity));

                return placement;
            },

            /**
             * Calculate a selected placement that supports patches.
             * @return {[type]} [description]
             */
            patchPlacement() {
                if (this.selectedPlacements == null) {
                    return null;
                }

                return this.selectedPlacements.find((x) => constants.ACTIVITY_EMBROIDERY_PATCHES.includes(x.placement.custom.activity)
                    && x.placement.code.endsWith(constants.PATCH_PLACEMENT_SUFFIX));
            },

            /**
             * Calculate a selected placement that supports text.
             * @return {[type]} [description]
             */
            textPlacement() {
                if (this.selectedPlacements == null) {
                    return null;
                }

                return this.selectedPlacements.find((x) => x.placement.code.endsWith(constants.TEXT_PLACEMENT)
                    || x.placement.code.endsWith(constants.TEXT_PLACEMENT_SUFFIX));
            },

            /**
             * Patches available for selection.
             */
            availablePatches() {
                const options = [];

                if (this.patchPlacement == null) {
                    return options;
                }

                const currentSelection = this.viewController.selectedAtPlacement(this.patchPlacement.placement.code);

                this.patchPlacement.placement.components.forEach((c) => {
                    if (c.placeholder) {
                        return;
                    }

                    options.push({
                        component: c,
                        image: makeComponentThumbnailUrl(1, c),
                        selected: c.code === currentSelection.code,
                    });
                });

                return options;
            },

            /**
             * Available patch sizes.
             */
            availablePatchSizes() {
                const sizes = [];

                this.availablePatches.forEach((p) => {
                    const needType = this.selectedStyle === 'EM-O' ? 'O' : 'R';

                    const type = p.component.custom['shape-type'] || 'R';

                    if (type === needType && p.component.custom.size && !sizes.includes(p.component.custom.size)) {
                        sizes.push(p.component.custom.size);
                    }
                });

                return sizes;
            },

            /**
             * Gets the list of available fonts for the current selection.
             */
            availableFonts() {
                const options = [];

                if (this.textPlacement == null) {
                    return options;
                }

                this.textPlacement.placement.components.forEach((c) => {
                    if (c.placeholder) {
                        return;
                    }

                    const definition = this.getByType(c, constants.DEFINITION_FONT);

                    if (definition) {
                        let { description } = definition.definition;

                        const parenIndex = description.indexOf('(');
                        if (parenIndex > 0) {
                            description = description.substr(0, parenIndex).trim();
                        }

                        options.push({
                            definition: definition.definition,
                            description,
                            code: definition.definition.code,
                            font: c.custom['personalization-font'],
                            filter: c.custom['personalization-filter'],
                        });
                    }
                });

                return options;
            },

            /**
             * Gets a list of available font names.
             */
            availableFontNames() {
                const names = [];

                this.availableFonts.forEach((f) => {
                    const { name } = f.definition;

                    if (!names.includes(name)) {
                        names.push(name);
                    }
                });

                return names;
            },

            /**
             * Gets a list of available font sizes.
             */
            availableFontSizes() {
                if (this.selectedFontName) {
                    const sizes = [];

                    this.availableFonts.forEach((f) => {
                        if (f.definition.name !== this.selectedFontName) {
                            return;
                        }

                        if (!sizes.some((s) => s.code === f.code)) {
                            sizes.push(f);
                        }
                    });

                    return sizes;
                }

                return [];
            },

            /**
             * Available patch sizes.
             */
            availablePatchColors() {
                const colors = [];

                if (this.selectedPatchSize) {
                    this.availablePatches.forEach((p) => {
                        if (p.component.custom.size === this.selectedPatchSize) {
                            if (p.component.custom.hex && !colors.some((c) => c.hex === p.component.custom.hex)) {
                                if (p.component.custom['trim-name'] && p.component.custom['trim-name'].length > 0) {
                                    colors.push({
                                        hex: p.component.custom.hex,
                                        name: p.component.custom['trim-name'],
                                        component: p.component,
                                    });
                                }
                            }
                        }
                    });
                }

                return colors;
            },

            /**
             * Thread colors available for selection.
             */
            availableThreads() {
                const options = [];

                if (this.textPlacement == null) {
                    return options;
                }

                // const currentSelection = this.viewController.selectedAtPlacement(this.textPlacement.placement.code);

                this.textPlacement.placement.components.forEach((c) => {
                    if (c.placeholder) {
                        return;
                    }

                    const definition = this.getByType(c, constants.DEFINITION_THREAD);

                    if (definition) {
                        const color = c.custom['personalization-color'];
                        if (!options.some((o) => o.hex === color)) {
                            options.push({
                                definition: definition.definition,
                                code: definition.definition.code,
                                hex: color,
                            });
                        }
                    }
                });

                return options;
            },

            /**
             * Is the current selection complete?
             */
            isComplete() {
                if (this.selectedPlacements == null || this.selectedPlacements.length === 0) {
                    return false;
                }

                let good = true;

                this.selectedPlacements.forEach((p) => {
                    const selection = this.viewController.selectedAtPlacement(p.placement.code);
                    if (p.placement.custom['widget-type'] === 'personalization') {
                        if (selection.custom['personalization-text'] == null || selection.custom['personalization-text'].length === 0) {
                            good = false;
                        }
                    } else if (selection == null || selection.placeholder) {
                        good = false;
                    }
                });

                return good;
            },

            /**
             * Calculate the style description.
             */
            selectedStyleDescription() {
                if (this.selectedStyle === 'ED') {
                    return this.$t('EDIT_TEXT_STYLE_ED');
                }

                if (this.selectedStyle === 'EM-O') {
                    return this.$t('EDIT_TEXT_STYLE_EM_O');
                }

                if (this.selectedStyle === 'EM-R') {
                    return this.$t('EDIT_TEXT_STYLE_EM_R');
                }

                return null;
            },

            /**
             * Number of editable lines.
             */
            multiline() {
                if (this.textPlacement == null) {
                    return 1;
                }

                if (this.textPlacement.placement.custom['widget-multiline']) {
                    return +this.textPlacement.placement.custom['widget-multiline'];
                }

                return 1;
            },

            /**
             * Multiline hint.
             */
            multilineHint() {
                if (this.multiline === 1) {
                    return this.$t('EDIT_TEXT_ENTER_HINT');
                }

                return this.$t('EDIT_TEXT_ENTER_HINT_MANY', {
                    lines: this.multiline,
                });
            },

            /**
             * Characters per line hint.
             */
            charactersHint() {
                if (this.limitPerLine) {
                    return this.$t('EDIT_TEXT_ENTER_HINT_CHARS', {
                        characters: this.limitPerLine,
                    });
                }

                return '';
            },

            /**
             * Are we in the program mode?
             */
            isProgramMode() {
                return this.$store.getters.isProgramMode;
            },

            /**
             * Get a list template samples.
             */
            textSamples() {
                try {
                    const templates = JSON.parse(this.viewController.blueprint.custom.templates);

                    if (templates.samples) {
                        return templates.samples;
                    }
                } catch (e) {
                    // Nothing.
                }

                return {};
            },

            /**
             * Make a list of possible text variables.
             */
            textVariables() {
                const vars = [];

                Object.keys(this.textSamples).forEach((k) => {
                    vars.push(`{{${k}}}`);
                });

                return vars.length > 0 ? vars.join(', ') : null;
            },

            /**
             * Text templates.
             */
            textTemplates() {
                try {
                    const templates = JSON.parse(this.viewController.blueprint.custom.templates);

                    const list = templates.templates;

                    list.forEach((t) => {
                        t.sample = this.makeSample(t.template);
                    });

                    return list;
                } catch (e) {
                    // Nothing.
                }

                return [];
            },

            customTextTemplateSelected() {
                return this.programLines.some((x) => x.type === 'custom');
            },
        },
        watch: {
            /**
             * When a selected location changes, clear out options in the old place.
             * Finds the matching placement and component in the new location, and attempts to update load that new component
             * as well as copy over custom attributes.
             */
            selectedLocation(newValue, oldValue) {
                // Skip reset if no old value present
                if (!oldValue) {
                    return;
                }

                // Match components between old and new locations
                oldValue.placements.forEach((x) => {
                    const oldActivity = x.placement.custom.activity;
                    const selected = this.viewController.selectedAtPlacement(x.placement.code);
                    const oldCustomAttirbutes = { ...selected.custom };
                    const independentCode = selected.placementIndependentCode;

                    if (isFilled(selected)) {
                        // Find matching placement by activity and independent code
                        const newPlacementObj = newValue.placements.find((y) => {
                            const activitiesMatch = y.placement.custom.activity === oldActivity;
                            return activitiesMatch && y.placement.components.find((c) => c.placementIndependentCode === independentCode);
                        });
                        const newPlacement = newPlacementObj.placement;

                        // Find matching component in new placement
                        const newComponent = newPlacement.components.find((c) => c.placementIndependentCode === independentCode);

                        // Update new placement with matching component
                        this.viewController.updateComponent(newPlacement.code, newComponent.code);

                        // Copy custom data from old component
                        this.viewController.commitCustomAttributes(newPlacement.code, oldCustomAttirbutes);

                        // Call update location to force a rerender
                        this.updatePlacementLocation();

                        // Clear the old placement
                        this.clearPlacement(x);
                    }
                });
            },

            /**
             * When a style changes, clear out options in the old place.
             */
            selectedStyle() {
                if (this.loaded) {
                    this.clearPlacements();

                    this.updateLimit();
                }
            },

            /**
             * When selected placement and location changes, zoom to that.
             */
            selectedPlacements() {
                if (this.loaded) {
                    if (this.selectedPlacements.length > 0) {
                        this.$store.dispatch('setFocusedPlacement', {
                            placement: this.selectedPlacements[0],
                        });
                    } else {
                        this.$store.dispatch('setFocusedPlacement', {
                            placement: null,
                        });
                    }
                }
            },

            /**
             * Ensure selected color.
             */
            selectedPatchSize() {
                if (this.loaded) {
                    const oldColor = this.selectedPatchColor;

                    this.$nextTick(() => {
                        if (oldColor) {
                            const matching = this.availablePatchColors.find((c) => c.hex === oldColor.hex);

                            if (matching) {
                                this.selectedPatchColor = matching;
                            } else {
                                this.selectedPatchColor = null;
                            }
                        }
                    });
                }
            },

            /**
             * Select patch.
             */
            selectedPatchColor() {
                if (this.loaded) {
                    this.selectPatch();
                }
            },

            /**
             * Ensure selected font size.
             */
            selectedFontName() {
                if (this.loaded) {
                    this.$nextTick(() => {
                        if (this.availableFontSizes && this.availableFontSizes.length > 0) {
                            // eslint-disable-next-line
                            this.selectedFontSize = this.availableFontSizes[0];
                        } else {
                            this.selectedFontSize = null;
                        }
                    });
                }

                this.updateLimit();
            },

            /**
             * Select font.
             */
            selectedFontSize() {
                if (this.loaded) {
                    this.selectFont();
                }
            },

            /**
             * Select thread color.
             */
            selectedThreadColor() {
                if (this.loaded) {
                    this.updateColor();
                }
            },

            /**
             * Update template lines.
             */
            multiline() {
                this.updateProgramLines();
            },

            /**
             * Update template lines.
             */
            selectedText() {
                this.updateProgramLines();
            },
        },
        mounted() {
            // When the component is loaded, capture the current state of the view controller and save it in case
            // we need to revert to the previous state and toss away all customizations.
            //
            // At any given time there should be only one module that can do the revert.
            this.captureController();

            // Zoom in initial placement.
            if (this.selectedPlacements && this.selectedPlacements.length > 0) {
                this.$store.dispatch('setFocusedPlacement', {
                    placement: this.selectedPlacements[0],
                });
            }

            this.setInitial();

            this.$nextTick(() => {
                this.loaded = true;
            });
        },
        methods: {
            /**
             * Captures the current state and active controller so we could cancel changes if necessary.
             */
            captureController() {
                if (this.viewController) {
                    this.revertController = this.viewController.clone();
                }
            },

            /**
             * Restores initial state.
             */
            setInitial() {
                let selectedStyle = null;
                let selectedText = null;
                let selectedFontName = null;
                let selectedFontSize = null;
                let selectedThreadColor = null;

                if (this.selectedLocation) {
                    this.selectedLocation.placements.forEach((x) => {
                        const selected = this.viewController.selectedAtPlacement(x.placement.code);

                        // Check for ED placements if there is any text.
                        if (constants.ACTIVITY_EMBROIDERY_ED.includes(x.placement.custom.activity)) {
                            if (!selected.placeholder && selected.custom['personalization-text'] && selected.custom['personalization-text'].length > 0) {
                                selectedStyle = 'ED';

                                selectedText = selected.custom['personalization-text'];

                                if (this.isProgramMode) {
                                    if (selected.custom['personalization-template']) {
                                        selectedText = selected.custom['personalization-template'];
                                    }
                                }

                                const font = selected.description.find((d) => d.definition === constants.DEFINITION_FONT);

                                if (font) {
                                    selectedFontName = font.name;

                                    selectedFontSize = this.availableFonts.find((f) => f.definition.name === selectedFontName && f.code === font.code);
                                }

                                const thread = selected.description.find((d) => d.definition === constants.DEFINITION_THREAD);

                                if (thread) {
                                    selectedThreadColor = this.availableThreads.find((t) => t.code === thread.code);
                                }
                            }
                        }

                        // Check for EM placements in case if there are patch selections.
                        if (constants.ACTIVITY_EMBROIDERY_EM_R.includes(x.placement.custom.activity) && x.placement.custom['activity-type'] === 'patch') {
                            if (!selected.placeholder) {
                                if (selected.custom['shape-type'] === 'O') {
                                    selectedStyle = 'EM-O';
                                } else {
                                    selectedStyle = 'EM-R';
                                }

                                this.selectedPatchSize = selected.custom.size;
                                this.selectedPatchColor = {
                                    hex: selected.custom.hex,
                                    name: selected.custom['trim-name'],
                                    component: selected,
                                };
                            }
                        }

                        if (constants.ACTIVITY_EMBROIDERY_EM_R.includes(x.placement.custom.activity) && x.placement.custom['activity-type'] === 'text') {
                            if (!selected.placeholder && selected.custom['personalization-text'] && selected.custom['personalization-text'].length > 0) {
                                selectedText = selected.custom['personalization-text'];

                                if (this.isProgramMode) {
                                    if (selected.custom['personalization-template']) {
                                        selectedText = selected.custom['personalization-template'];
                                    }
                                }

                                const font = selected.description.find((d) => d.definition === constants.DEFINITION_FONT);

                                if (font) {
                                    selectedFontName = font.name;

                                    selectedFontSize = this.availableFonts.find((f) => f.definition.name === selectedFontName && f.code === font.code);
                                }

                                const thread = selected.description.find((d) => d.definition === constants.DEFINITION_THREAD);

                                if (thread) {
                                    selectedThreadColor = this.availableThreads.find((t) => t.code === thread.code);
                                }
                            }
                        }
                    });
                }

                this.selectedStyle = selectedStyle;
                this.selectedText = selectedText;
                this.selectedFontName = selectedFontName;
                this.selectedFontSize = selectedFontSize;
                this.selectedThreadColor = selectedThreadColor;

                this.updateLimit();
            },

            /**
             * Accept color choice.
             */
            accept() {
                // Review text.
                if (this.selectedText?.length > 0) {
                    this.isValidating = true;

                    // Review text.
                    this.viewController.reviewText(this.selectedText).then((result) => {
                        this.isValidating = false;

                        if (result.hasProfanity) {
                            this.invalid = true;
                            this.errorText = this.$t('ERROR_PROFANITY');
                        } else {
                            // Keep the current configured view controller, and go back to the main screen.
                            this.$store.dispatch('selectStep', null);
                        }
                    }).catch(() => {
                        this.isValidating = false;

                        this.invalid = true;
                        this.errorText = this.$t('ERROR_PROFANITY');
                    });
                } else {
                    // Keep the current configured view controller, and go back to the main screen.
                    this.$store.dispatch('selectStep', null);
                }
            },

            /**
             * Reject color changes and restore the original selection.
             */
            cancel() {
                this.cancelled = true;

                // Revert back to the state of the controller as was captured when the component was mounted.
                this.$store.dispatch('showController', this.revertController);

                // Go back to the main screen.
                this.$store.dispatch('selectStep', null);
            },

            /**
             * Selects a location for personalization.
             */
            selectLocation(l) {
                this.selectedLocation = l.location;
            },

            /**
             * Selects a style.
             */
            selectStyle(style) {
                this.selectedStyle = style;
            },

            /**
             * Selects a patch for the selected placement.
             */
            selectPatch() {
                if (this.patchPlacement && this.selectedPatchColor) {
                    this.viewController.updateComponent(this.patchPlacement.placement.code, this.selectedPatchColor.component.code);

                    this.updateLimit();

                    this.updatePlacementLocation();
                }
            },

            /**
             * Selects a font size for the selected placement.
             */
            selectFont() {
                if (this.textPlacement && this.selectedFontSize) {
                    this.viewController.updateSimilarComponent(this.textPlacement.placement.code, constants.DEFINITION_FONT, this.selectedFontSize.code);

                    this.updateLimit();

                    this.updatePlacementLocation();
                }
            },

            /**
             * Adjustments placement location according to selected alignment.
             */
            updatePlacementLocation() {
                if (this.textPlacement) {
                    // Adjust placement location according to placement alignment.
                    if (!this.textPlacement.placement.custom['personalization-y-original']) {
                        this.textPlacement.placement.custom['personalization-y-original'] = this.textPlacement.placement.custom['personalization-y'];
                    }

                    const textY = this.textPlacement.placement.custom['personalization-y-original'];

                    let imageHeight = (constants.DEFAULT_ART_HEIGHT)
                        * (+this.textPlacement.placement.custom['placement-scale']);

                    if (this.patchPlacement) {
                        const patchSelected = this.viewController.selectedAtPlacement(this.patchPlacement.placement.code);
                        if (patchSelected && patchSelected.custom && patchSelected.custom['size-height']) {
                            imageHeight = ((+patchSelected.custom['size-height']) * constants.STANDARD_PIXELS)
                                * (+this.textPlacement.placement.custom['placement-scale']);
                        }
                    }

                    const placementHeight = this.textPlacement.placement.custom['personalization-height'];

                    let dy = 0;

                    switch (this.textPlacement.placement.custom['placement-vertical-alignment']) {
                    case 'top': {
                        dy = -(placementHeight - imageHeight) * 0.5;
                        break;
                    }
                    case 'bottom': {
                        dy = (placementHeight - imageHeight) * 0.5;
                        break;
                    }
                    default:
                        break;
                    }

                    this.textPlacement.placement.custom['personalization-y'] = (+textY).toString();

                    if (constants.ACTIVITY_EMBROIDERY_ED.includes(this.textPlacement.placement.custom.activity)) {
                        this.viewController.commitCustomAttributes(this.textPlacement.placement.code, {
                            'personalization-vertical-alignment': this.textPlacement.placement.custom['placement-vertical-alignment'],
                        });
                    }

                    if (this.patchPlacement) {
                        this.textPlacement.placement.custom['personalization-y'] = (+textY + dy).toString();

                        if (!this.patchPlacement.placement.custom['placement-y-original']) {
                            this.patchPlacement.placement.custom['placement-y-original'] = this.patchPlacement.placement.custom['placement-y'];
                        }

                        const patchY = this.patchPlacement.placement.custom['placement-y-original'];

                        this.patchPlacement.placement.custom['placement-y'] = (+patchY + dy).toString();

                        this.viewController.commitCustomAttributes(this.textPlacement.placement.code, {
                            'personalization-vertical-alignment': 'center',
                        });
                    }
                }
            },

            /**
             * Clears placement.
             */
            clearPlacements(location) {
                this.selectedPatchSize = null;
                this.selectedPatchColor = null;

                this.selectedFontName = null;
                this.selectedFontSize = null;

                this.selectedThreadColor = null;

                this.selectedText = '';

                const l = location || this.selectedLocation;

                l.placements.forEach((placement) => {
                    this.clearPlacement(placement);
                });
            },

            /**
             * Clears placement.
             */
            clearPlacement(placement) {
                let code = placement;
                let p = placement;

                if (typeof p === 'string') {
                    p = {
                        placement: {
                            code: placement,
                        },
                    };
                } else {
                    ({ code } = placement.placement);
                }

                this.$store.dispatch('clearPlacement', p);

                this.viewController.findPlacement(code).components.forEach((component) => {
                    for (let i = 1; i <= 5; i += 1) {
                        delete component.custom[`personalization-text-${i}`];
                    }
                });
            },

            /**
             * Removes selected art from the current location.
             */
            removeText() {
                this.clearPlacements();

                // Go back to the main screen.
                this.$store.dispatch('selectStep', null);
            },

            /**
             * Update personalization field.
             */
            updatePersonalization() {
                if (this.selectedText != null && this.textPlacement) {
                    // Save template.
                    if (this.isProgramMode) {
                        this.viewController.commitCustomAttributes(this.textPlacement.placement.code, {
                            'personalization-template': this.selectedText || '',
                        }, {
                            propagate: true,
                        });
                    }

                    if (this.isProgramMode) {
                        // Convert text.
                        const useText = this.makeSample(this.selectedText);

                        // Enforce line limits.
                        const component = this.viewController.selectedAtPlacement(this.textPlacement.placement.code);

                        const lines = (useText || '').split('\n').map((x) => x.trim());
                        let valid = true;

                        if (component.custom['personalization-limit-lines'] && component.custom['personalization-limit-lines'].length > 0) {
                            const linesMax = +component.custom['personalization-limit-lines'];

                            if (lines.length > linesMax) {
                                valid = false;
                            }
                        }

                        if (!valid) {
                            this.selectedText = component.custom['personalization-text'];
                        } else {
                            // Don't enforce limits. Those would be done at later phase.
                            this.viewController.updatePersonalization(this.textPlacement.placement.code, useText, { noEnforceLimit: true }).then((result) => {
                                if (result && !result.accepted) {
                                    // Nothing.
                                } else {
                                    this.splitText(this.textPlacement.placement.code, useText);
                                }
                            });
                        }
                    } else {
                        this.viewController.updatePersonalization(this.textPlacement.placement.code, this.selectedText).then((result) => {
                            if (result && !result.accepted) {
                                this.selectedText = result.lastValid;
                            } else {
                                this.splitText(this.textPlacement.placement.code, this.selectedText);
                            }
                        });
                    }

                    this.updatePlacementLocation();
                }
            },

            /**
             * Updates personalization color.
             */
            updateColor() {
                if (this.selectedThreadColor) {
                    this.viewController.updateSimilarComponent(this.textPlacement.placement.code, constants.DEFINITION_THREAD, this.selectedThreadColor.code);

                    this.updatePlacementLocation();
                }
            },

            /**
             * Updates personalization limits.
             */
            updateLimit() {
                if (this.textPlacement) {
                    const selected = this.viewController.selectedAtPlacement(this.textPlacement.placement.code);

                    if (selected) {
                        const lines = this.multiline.toString();

                        this.textPlacement.placement.components.forEach((c) => {
                            Vue.set(c.custom, 'personalization-limit-lines', lines);
                        });

                        let limitType = null;

                        if (this.selectedStyle === 'ED') {
                            limitType = 'ED';
                        }

                        // PS - Patch small
                        // PL - Patch large

                        if (this.selectedStyle === 'EM-R') {
                            limitType = 'PS';

                            if (this.selectedPatchSize) {
                                if (this.selectedPatchSize.includes('Large')) {
                                    limitType = 'PL';
                                }
                            }
                        }

                        if (this.selectedStyle === 'EM-O') {
                            limitType = 'PL';
                        }

                        if (limitType) {
                            if (selected.custom[`personalization-limit-line-${limitType}`]) {
                                const limit = +selected.custom[`personalization-limit-line-${limitType}`];

                                this.limitPerLine = limit;

                                this.textPlacement.placement.components.forEach((c) => {
                                    Vue.set(c.custom, 'personalization-limit-line', selected.custom[`personalization-limit-line-${limitType}`]);
                                });

                                let text = selected.custom['personalization-text'] || '';

                                if (this.isProgramMode) {
                                    if (selected.custom['personalization-template']) {
                                        text = selected.custom['personalization-template'];
                                    }
                                }

                                let filter = selected.custom['personalization-filter'];

                                if (filter && filter.length > 0) {
                                    if (this.isProgramMode) {
                                        filter += '|[{}]';
                                    }

                                    filter = new RegExp(filter);

                                    const filteredText = text.split('').map((x) => (filter.test(x) ? x : '')).join('');

                                    text = filteredText;
                                }

                                text = text.split('\n');
                                const textTrimmed = [];

                                text.forEach((t) => {
                                    if (this.isProgramMode) {
                                        textTrimmed.push(t);
                                    } else if (t.length > limit) {
                                        textTrimmed.push(t.substr(0, limit));
                                    } else {
                                        textTrimmed.push(t);
                                    }
                                });

                                if (this.isProgramMode) {
                                    this.viewController.updatePersonalization(this.textPlacement.placement.code, textTrimmed.join('\n'), {
                                        noEnforceLimit: true,
                                    }).then((result) => {
                                        if (result && result.accepted) {
                                            this.splitText(this.textPlacement.placement.code, textTrimmed.join('\n'));
                                        }
                                    });
                                } else {
                                    this.viewController.updatePersonalization(this.textPlacement.placement.code, textTrimmed.join('\n')).then((result) => {
                                        if (result && result.accepted) {
                                            this.splitText(this.textPlacement.placement.code, textTrimmed.join('\n'));
                                        }
                                    });
                                }

                                this.selectedText = textTrimmed.join('\n');

                                this.updatePlacementLocation();
                            }
                        }
                    }
                }
            },

            /**
             * Splits personalization text in lines.
             */
            splitText(placement, text) {
                this.viewController.findPlacement(placement).components.forEach((component) => {
                    for (let i = 1; i <= 5; i += 1) {
                        delete component.custom[`personalization-text-${i}`];
                    }

                    text.split('\n').forEach((v, index) => {
                        component.custom[`personalization-text-${index + 1}`] = v;
                    });
                });
            },

            /**
             * Gets description by type.
             */
            getByType(component, type) {
                let found = null;

                if (component && component.description) {
                    component.description.forEach((d) => {
                        if (d.definition === type) {
                            found = {
                                definition: d,
                            };
                        }
                    });
                }

                return found;
            },

            /**
             * Retrieves font family name.
             */
            getFontFamily(name) {
                const font = this.availableFonts.find((f) => f.definition.name === name);

                if (font) {
                    return font.font.replace('.ttf', '');
                }

                return null;
            },

            /**
             * Selects a template.
             */
            selectTemplate(t) {
                if (t) {
                    this.selectedText = t.template;

                    this.updatePersonalization();
                } else {
                    this.selectedText = '';

                    this.updatePersonalization();
                }
            },

            makeSample(text) {
                const samples = this.textSamples;

                let value = text;

                // eslint-disable-next-line prefer-regex-literals
                value = value.replace(new RegExp('{{custom}}', 'g'), '');

                Object.keys(samples).forEach((k) => {
                    value = value.replace(new RegExp(`{{${k}}}`, 'g'), samples[k]);
                });

                return value;
            },

            updateProgramLines() {
                if (this.isProgramMode) {
                    const lines = (this.selectedText || '').split('\n').map((x) => x.trim());

                    // Get rid of leading empty lines.
                    while (lines.length > 0 && lines[0].length === 0) {
                        lines.shift();
                    }

                    while (lines.length < this.multiline) {
                        lines.push('');
                    }

                    while (lines.length > this.multiline) {
                        lines.pop();
                    }

                    const programLines = [];

                    // eslint-disable-next-line no-restricted-syntax
                    for (const l of lines) {
                        if (l.startsWith(CUSTOM_TEXT)) {
                            programLines.push({
                                type: 'custom',
                                value: l,
                            });
                        } else {
                            programLines.push({
                                type: 'template',
                                value: l,
                            });
                        }
                    }

                    this.programLines = programLines;
                } else {
                    this.programLines = [];
                }
            },

            updateLine(data, line) {
                line.type = data.type;
                line.value = data.value;

                // Assemble the text back.
                const text = this.programLines.map((x) => x.value).join('\n');

                this.selectedText = text;

                this.updatePersonalization();
            },
        },
    };
</script>

import * as constants from '../constants';

/**
 * Creates an image link from an angle and component.
 */
export function makeThumbnailUrl(angle, component) {
    // eslint-disable-next-line max-len
    return `https://api.images.drivecommerce.com/api/v1/image/vfw/CZ-${angle}-${component.placementIndependentCode}?size=144,144&fmt=jpg&qlt=70&bgc=255,255,255`;
}

/**
 * Creates an image link from an angle and component.
 */
export function makeComponentThumbnailUrl(angle, component) {
    // eslint-disable-next-line max-len
    return `https://api.images.drivecommerce.com/api/v1/image/vfw/CZ-${angle}-${component.placementIndependentCode}?size=132,132&fmt=jpg&qlt=70&bgc=76,76,76`;
}

/**
 * Generates a location placeholder image.
 */
export function getLocationImageUrl(controller, location) {
    let frame = constants.FRAME_FRONT;

    if (constants.LOCATIONS_BACK.includes(location.location)) {
        frame = constants.FRAME_BACK;
    }

    const selectedProduct = controller.selectedAtPlacement(constants.PLACEMENT_PRODUCT);

    const scale = constants.IMAGE_PLACEMENT_SCALE;

    const x = +location.x;
    const y = +location.y;
    const w = +location.width;
    const h = +location.height;

    const dx = (constants.IMAGE_PLACEMENT_SIZE * 0.5) - ((x + (w * 0.5)) * scale);// + (w / 2 / scale);
    const dy = (constants.IMAGE_PLACEMENT_SIZE * 0.5) - ((y + (h * 0.5)) * scale);// + (h / 2 / scale);

    const pw = constants.BASE_IMAGE_SIZE * location.scale * scale;
    const px = (constants.IMAGE_PLACEMENT_SIZE - pw) * 0.5;

    const url = [
        'https://api.images.drivecommerce.com/api/v1/image/vfw/',
        `?layer=0&src=CZ-${frame}-BLANK600`,
        `&layer=1&src=CZ-${frame}-${selectedProduct.placementIndependentCode}&scale=${scale}&pos=${dx},${dy}`,
        `&layer=2&src=CZ-${frame}-PLACEHOLDER&scale=${location.scale * scale}&pos=${px},${px}`,
        '&rwid=132&rhei=132&fmt=jpg&qlt=70&bgc=246,246,246',
    ];

    return url.join('');
}

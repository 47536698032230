<template>
    <div
        :class="[
            'cz2__panel',
            'cz2__menu',
            {
                'cz2__menu--with-active': selectedStep != null
            }
        ]"
    >
        <div :class="[
            'cz2__panel__body',
            'cz2__menu__body',
        ]">
            <div class="cz2__menu__header">
                <div class="cz2__type__header">{{$t('MENU_HEADER')}}</div>
                <div class="cz2__type__normal" v-html="$t('MENU_SUBHEADER')"></div>
            </div>

            <div class="cz2__menu__items">

                <!-- Colors -->
                <menu-item
                    :isActive="selectedStep === 'colors'"
                    :title="$t('MENU_CHOOSE_COLOR')"
                    :description="selectedColor"
                    :isComplete="true"
                    @buttonClick="selectColor"
                ></menu-item>

                <!-- Sizes -->
                <menu-item v-if="!isProgramMode"
                    :isActive="selectedStep === 'sizes'"
                    :title="$t('MENU_CHOOSE_SIZE')"
                    :description="selectedSizes || this.$t('MENU_CHOOSE_A_SIZE')"
                    :capitalize="selectedSizes != null"
                    :isComplete="selectedSizes != null"
                    :hasErrors="showErrors && canBuildReason === 'size'"
                    @buttonClick="selectSize"
                ></menu-item>

                <menu-item v-if="isProgramMode"
                    :isActive="selectedStep === 'sizes'"
                    :title="$t('PROGRAM_MENU_SIZE')"
                    :description="this.$t('PROGRAM_MENU_SIZE_INFO')"
                    :capitalize="false"
                    :multilineDescription="true"
                    :readonly="true"
                    @buttonClick="nothing"
                ></menu-item>

                <!-- Filled Embroidery -- TEMP DEFAULT TO COMPLETE -->
                <menu-item
                    v-for="selected in filledEmbroidery"
                    :key="selected.location"
                    :isActive="selectedStep !== null && selectedStep.startsWith('edit-embroidery-' + selected.location)"
                    :title="$t('MENU_EMBROIDERY')"
                    :price="getLocationPrice(selected)"
                    :description="formatEmbroideryLocation(selected)"
                    :isComplete="true"
                    @buttonClick="editEmbroidery(selected)"
                ></menu-item>

                <!-- Add Embroidery -->
                <menu-item
                    v-if="freeEmbroidery.length > 0"
                    :isActive="selectedStep != null && selectedStep.startsWith('new-embroidery')"
                    :title="addEmbroideryText"
                    :description="$t('MENU_OPIONAL')"
                    @buttonClick="addEmbroidery"
                    :tip="filledEmbroidery.length > 0 ? $t('TIP_SECOND_EMBROIDERY') : $t('TIP_EMBROIDERY')"
                ></menu-item>

                <!-- Filled Art -- TEMP DEFAULT TO COMPLETE -->
                <menu-item
                    v-for="selected in filledArt"
                    :key="selected.location"
                    :isActive="selectedStep !== null && selectedStep.startsWith('edit-art-' + selected.location)"
                    :title="$t('MENU_ART')"
                    :price="getLocationPrice(selected)"
                    :description="formatArtLocation(selected)"
                    :isComplete="true"
                    @buttonClick="editArt(selected)"
                ></menu-item>

                <!-- Add Art -->
                <menu-item
                    v-if="freeArt.length > 0"
                    :isActive="selectedStep != null && selectedStep.startsWith('new-art')"
                    :title="addArtText"
                    :description="$t('MENU_OPIONAL')"
                    @buttonClick="addArt"
                    :tip="filledArt.length > 0 ? $t('TIP_SECOND_ART') : $t('TIP_ART')"
                ></menu-item>

                <!-- Filled Logo -- TEMP DEFAULT TO COMPLETE -->
                <menu-item
                    v-for="selected in filledLogo"
                    :key="selected.location"
                    :isActive="selectedStep !== null && selectedStep.startsWith('edit-logo-' + selected.location)"
                    :title="$t('MENU_LOGO')"
                    :price="getLocationPrice(selected)"
                    :description="formatLogoLocation(selected)"
                    :isComplete="true"
                    @buttonClick="editLogo(selected)"
                ></menu-item>

                <!-- Add Logo -->
                <menu-item
                    v-if="freeLogo.length > 0"
                    :isActive="selectedStep != null && selectedStep.startsWith('new-logo')"
                    :title="addLogoText"
                    :description="$t('MENU_OPIONAL')"
                    @buttonClick="addLogo"
                    :tip="filledLogo.length > 0 ? $t('TIP_SECOND_LOGO') : $t('TIP_LOGO')"
                ></menu-item>

                <!-- Quantity -->
                <div v-if="!isProgramMode" class="cz2__menu-quantity">
                    <label class="cz2__menu-quantity__title cz2__type__small-header" for="cz2__menu-quantity__value"
                        :aria-label="$t('CHECKOUT_QUANTITY_LONG')"
                    >
                        {{$t('CHECKOUT_QUANTITY')}}
                    </label>

                    <div class="menu-quantity__counter">
                        <button class="cz2__menu-quantity__minus" @click.prevent="adjustQuantity(-1)" :title="$t('CHECKOUT_MINUS_TITLE')">
                            <span>{{$t('CHECKOUT_MINUS')}}</span>
                        </button>

                        <!-- eslint-disable-next-line max-len -->
                        <input type="number" id="cz2__menu-quantity__value" class="cz2__menu-quantity__value" v-model.number="quantity" @input="updateQuantity" />

                        <button class="cz2__menu-quantity__plus" @click.prevent="adjustQuantity(1)" :title="$t('CHECKOUT_PLUS_TITLE')">
                            <span>{{$t('CHECKOUT_PLUS')}}</span>
                        </button>
                    </div>
                </div>

                <!-- Business popup -->
                <!-- Hidden when we added B2B
                <div v-if="!isProgramMode" class="cz2__menu-business-order">
                    <button class="cz2__type__normal cz2__type__underline" @click.prevent="businessOrder">{{$t('BUSINESS_ORDER')}}</button>
                </div>
                -->

                <!-- Switch to program? -->
                <div v-if="allowPrograms && !isProgramMode" class="cz2__program-switch">
                    <button class="cz2__type__normal cz2__type__underline" @click.prevent="switchToProgram">{{$t('PROGRAM_SWITCH')}}</button>
                </div>

                <!-- Totals -->
                <div class="cz2__menu-total">
                    <div class="cz2__menu-total__row">
                        <div class="cz2__menu-total__row-header"><span v-html="$t('CHECKOUT_PRODUCT')"></span></div>
                        <div class="cz2__menu-total__row-value">{{productPrice}}</div>
                    </div>

                    <div class="cz2__menu-total__row">
                        <div class="cz2__menu-total__row-header"><span v-html="$t('CHECKOUT_CUSTOMIZATIONS')"></span></div>
                        <div class="cz2__menu-total__row-value">{{customizationsPrice}}</div>
                    </div>

                    <div v-if="digitizationsPrice" class="cz2__menu-total__row">
                        <div class="cz2__menu-total__row-header"><span v-html="$t('CHECKOUT_DIGITIZATION')"></span></div>
                        <div class="cz2__menu-total__row-value">{{digitizationsPrice}}</div>
                    </div>

                    <div class="cz2__menu-total__row cz2__menu-total__row--important">
                        <div class="cz2__menu-total__row-header"><span v-html="$t('CHECKOUT_SUBTOTAL')"></span></div>
                        <div class="cz2__menu-total__row-value">{{subtotalPrice}}</div>
                    </div>
                </div>

                <!-- Promotion Label -->
                <div class="cz2__menu-promotion-label" v-if="hasPromotionLabel">
                    {{promotionLabel}}
                </div>
            </div>
        </div>

        <div class="cz2__panel__actions">
            <!-- <button class="cz2__panel-action" @click.prevent="save"><span>{{$t('ACTION_SAVE')}}</span></button> -->
            <button
                class="cz2__panel-action cz2__panel-action--primary"
                @click.prevent="accept"
                :disabled="selectedStep != null"
            >
                <span>{{$t(isProgramMode ? 'ACTION_ADD_TO_PROGRAM' : 'ACTION_ADD_TO_CART')}}</span>
            </button>
        </div>
    </div>
</template>

<style lang="scss">
    @import "../style/variables.scss";

    #cz2.cz2 {
        .cz2__panel.cz2__menu {
            background: $color-primary-light;

            overflow: hidden;

            @include respond-above(sm) {
                position: absolute;
                top: 0;
                right: 0;
                z-index: 10;

                display: flex;
                flex-direction: column;
                width: 390px;
                height: 100%;
            }

            &:after {
                content: '';

                position: absolute;
                left: 0;
                top: 0;
                z-index: 100;

                width: 100%;
                height: 100%;

                background: $color-primary-light;

                opacity: 0;
                visibility: hidden;
                transition: all $transition-fast ease-out;
            }

            &.cz2__menu--with-active {
                display: none;

                 @include respond-above(sm) {
                    display: flex;
                }

                &:after {
                    visibility: visible;
                    opacity: 0.8;
                }
            }
        }

        .cz2__menu__body {
            position: relative;

            padding: 0 0 10px;

            overflow: auto;
            -webkit-overflow-scrolling: touch;

            @include respond-above(tablet) {
                padding: 15px 0 30px;
            }

            @include respond-above(sm) {
                width: 100%;

                padding: 24px 0;
            }
        }

        .cz2__menu__header,
        .cz2__menu-quantity,
        .cz2__menu-total,
        .cz2__menu-business-order {
            padding: 0 40px;

            @include respond-above(sm) {
                padding: 0 56px;
            }
        }

        .cz2__menu__header {
            display: none;

            @include respond-above(sm) {
                display: block;
            }

            .cz2__type__normal {
                margin-top: 8px;
                font-weight: 500;
            }
        }

        .cz2__menu__items {
            // 40px of total spacing,
            // with 1px coming from the border
            // on .cz2__menu-items
            margin-top: 39px;
        }

        .cz2__menu-total {
             margin-top: 23px;
        }

        .cz2__menu-quantity {
            display: flex;
            flex-direction: column;
        }

        .cz2__menu-quantity__title {
            padding: 16px 0 8px;
            border-top: 1px solid $color-accent-light-gray;
            flex: 1 0 auto;
        }

        .menu-quantity__counter {
            width: 128px;
            width: fit-content;
            padding: 15px 21px;
            display: flex;
            gap: 14px;
            border: 1px solid $color-accent-light-gray;
            border-radius: 4px;
        }

        .cz2__menu-quantity__plus, .cz2__menu-quantity__minus {
            position: relative;

            width: 12px;
            height: 24px;
            border: none;

            background: transparent;

            span {
                display: none;
            }

            &:after {
                content: '';

                position: absolute;
                left: 50%;
                top: 50%;

                width: 100%;
                height: 100%;

                transform: translate(-50%, -50%);
            }
        }

        .cz2__menu-quantity__plus:after {
            background: transparent url(../assets/plus@2x.png) no-repeat center/contain;
        }

        .cz2__menu-quantity__minus:after {
            background: transparent url(../assets/minus@2x.png) no-repeat center/contain;
        }

        .cz2__menu-quantity__value {
            display: inline-block;

            width: 30px;
            height: 24px;
            border: none;

            color: $color-primary-dark;
            background-color: $color-primary-light;

            font-family: $font-header;
            font-size: 16px;
            font-weight: 600;
            line-height: 24px;
            text-align: center;

            &::-webkit-outer-spin-button, &::-webkit-inner-spin-button {
                -webkit-appearance: none;
                margin: 0;
            }

            -moz-appearance: textfield;
        }

        .cz2__menu-business-order {
            margin-top: 11px;

            button {
                display: inline-block;
                text-align: left;
                width: auto;
                height: auto;
            }
        }

        .cz2__menu-total__row {
            height: 48px;
            padding: 16px 0 8px;
            border-top: 1px solid $color-accent-light-gray;
            display: flex;

            &.cz2__menu-total__row--important {
                .cz2__menu-total__row-header, .cz2__menu-total__row-value {
                    color: $color-primary-dark;
                    font-family: $font-main;
                    font-size: 14px;
                    font-weight: 500;
                    line-height: 20px;

                    opacity: 1;
                }
            }
        }

        .cz2__menu-total__row-header {
            flex: 1 0 auto;

            opacity: 0.6;
            color: $color-primary-dark;
            font-family: $font-main;
            font-size: 12px;
            font-weight: 500;
            line-height: 18px;

            span {
                display: inline-block;
                line-height: 1;
            }
        }

        .cz2__menu-total__row-value {
            color: $color-primary-dark;
            font-family: $font-main;
            font-size: 12px;
            font-weight: 500;
            line-height: 24px;
        }

        .cz2__menu-promotion-label {
            font-family: $font-main;
            font-size: 18px;
            text-align: center;
            margin-top: 10px;
            margin-bottom: 20px;
        }

        .cz2__program-switch {
            margin-top: 20px;

            padding: 0 $menu__pad-h__handheld;

            @include respond-above(tablet) {
                padding: 0 $menu__pad-h__tablet;
            }

            @include respond-above(sm) {
                padding: 0 $menu__pad-h__desktop 0 ($menu__pad-h__desktop + 19px) ;
            }
        }
    }
</style>

<script>
    import MenuItem from './MenuItem.vue';

    import isFilled from '../utilities/isFilled';

    import * as constants from '../constants';

    export default {
        components: {
            MenuItem,
        },
        data() {
            return {
                quantity: this.$store.state.customizer.quantity,

                showErrors: false,
            };
        },
        computed: {
            /**
             * Current controller.
             */
            viewController() {
                return this.$store.state.customizer.viewController;
            },

            /**
             * Can we build this configuration?
             */
            canBuild() {
                return this.$store.getters.canBuild;
            },

            /**
             * Can we build this configuration?
             */
            canBuildReason() {
                return this.$store.getters.canBuildReason;
            },

            /**
             * Current variants list.
             */
            variants() {
                return this.$store.state.customizer.variants;
            },

            /**
             * Current selected step.
             */
            selectedStep() {
                return this.$store.state.selectedStep;
            },

            /**
             * Currently selected color name.
             */
            selectedColor() {
                return this.$store.getters.selectedColor;
            },

            /**
             * Does controlled product have sizes.
             */
            hasSizes() {
                return this.$store.getters.hasSizes;
            },

            /**
             * Are we in the program mode?
             */
            isProgramMode() {
                return this.$store.getters.isProgramMode;
            },

            /**
             * Can we do programs?
             */
            allowPrograms() {
                return this.$store.state.customizer.options.allowPrograms;
            },

            /**
             * Are there free embroidery placements?
             */
            freeEmbroidery() {
                return this.$store.getters.freeEmbroidery;
            },

            /**
             * Are there taken embroidery placements?
             */
            filledEmbroidery() {
                return this.$store.getters.filledEmbroidery;
            },

            /**
             * Are there free art placements?
             */
            freeArt() {
                return this.$store.getters.freeArt;
            },

            /**
             * Are there taken art placements?
             */
            filledArt() {
                return this.$store.getters.filledArt;
            },

            /**
             * Are there free logo placements?
             */
            freeLogo() {
                return this.$store.getters.freeLogo;
            },

            /**
             * Are there taken embroidery placements?
             */
            filledLogo() {
                return this.$store.getters.filledLogo;
            },

            /**
             * Currently selected sizes.
             */
            selectedSizes() {
                if (this.selectedSize == null) {
                    return null;
                }

                const sizeTypes = ['size', 'body', 'neck', 'sleeve'];

                const suffixes = {
                    neck: this.$t('SIZE_NECK'),
                    sleeve: this.$t('SIZE_SLEEVE'),
                };

                // Filter and map to avoid empty values
                return sizeTypes
                    .filter((sizeType) => this.selectedSize[sizeType])
                    .map((sizeType) => {
                        const suffix = suffixes[sizeType];
                        return this.selectedSize[sizeType] + (suffix ? ` ${suffix}` : '');
                    })
                    .join(', ');
            },

            /**
             * Currently selected sizes.
             */
            selectedSize() {
                return this.$store.state.customizer.selectedSize;
            },

            /**
             * Location prices.
             */
            locationPrices() {
                return this.$store.state.customizer.locationPrices;
            },

            /**
             * Product price.
             */
            productPrice() {
                return this.$store.state.customizer.productPrice;
            },

            /**
             * Customizations price.
             */
            customizationsPrice() {
                return this.$store.state.customizer.customizationsPrice;
            },

            /**
             * All digitization fees.
             */
            digitizationsPrice() {
                return this.$store.state.customizer.digitizationsPrice;
            },

            /**
             * Subtotal price.
             */
            subtotalPrice() {
                return this.$store.state.customizer.subtotalPrice;
            },

            /**
             * Returns a promotion label for the selected variant if there is one, else null.
             */
            promotionLabel() {
                const selectedVariant = this.$store.state.customizer.selectedSize;
                if (!selectedVariant) { return null; }
                const { promotionLabel } = selectedVariant;
                return promotionLabel || null;
            },

            /**
             * Returns if there is a currently applicable promotion label.
             */
            hasPromotionLabel() {
                return this.promotionLabel !== null;
            },

            /**
             * Calculate the embroidery button text.
             */
            addEmbroideryText() {
                const index = this.filledEmbroidery.length;

                return this.$t('MENU_EMBROIDERY_ADD', {
                    index: this.$t(`ORDINAL_${index + 1}`),
                });
            },

            /**
             * Calculate the logo button text.
             */
            addLogoText() {
                const index = this.filledLogo.length;

                return this.$t('MENU_LOGO_ADD', {
                    index: this.$t(`ORDINAL_${index + 1}`),
                });
            },

            /**
             * Calculate the art button text.
             */
            addArtText() {
                const index = this.filledArt.length;

                return this.$t('MENU_ART_ADD', {
                    index: this.$t(`ORDINAL_${index + 1}`),
                });
            },
        },
        methods: {
            /**
             * Enter color selection mode.
             */
            selectColor() {
                this.$store.dispatch('selectStep', 'colors');
            },

            /**
             * Select variant selection mode.
             */
            selectSize() {
                this.$store.dispatch('selectStep', 'sizes');
            },

            /**
             * Saves the design.
             */
            save() {

            },

            /**
             * Prepares the design for adding to cart.
             */
            accept() {
                if (this.canBuild) {
                    this.$store.dispatch('customizerDone');
                } else {
                    this.showErrors = true;
                }
            },

            /**
             * Adds new embroidery config.
             */
            addEmbroidery() {
                this.$store.dispatch('selectStep', 'new-embroidery');
            },

            /**
             * Edits edits existing embroidery location.
             */
            editEmbroidery(location) {
                this.$store.dispatch('selectStep', `edit-embroidery-${location.location}`);
            },

            /**
             * Adds new logo config.
             */
            addLogo() {
                this.$store.dispatch('selectStep', 'new-logo');
            },

            /**
             * Edits edits existing logo location.
             */
            editLogo(location) {
                this.$store.dispatch('selectStep', `edit-logo-${location.location}`);
            },

            /**
             * Add new art config.
             */
            addArt() {
                this.$store.dispatch('selectStep', 'new-art');
            },

            /**
             * Edits edits existing art location.
             */
            editArt(location) {
                this.$store.dispatch('selectStep', `edit-art-${location.location}`);
            },

            /**
             * Makes a list of component descriptions.
             */
            pushDescriptions(component, parts, definitions) {
                if (component.description) {
                    definitions.forEach((definition) => {
                        const description = component.description.find((d) => d.definition === definition);

                        if (description) {
                            parts.push(description.description);
                        }
                    });
                }
            },

            /**
             * Formats filled art location info.
             */
            formatArtLocation(location) {
                const parts = [];

                location.placements.forEach((placement) => {
                    const component = this.viewController.selectedAtPlacement(placement.placement.code);

                    if (isFilled(component)) {
                        this.pushDescriptions(component, parts, [constants.DEFINITION_PATCH]);
                    }
                });

                parts.push(location.name);

                return parts.join(', ');
            },

            /**
             * Formats filled embroidery location info.
             */
            formatEmbroideryLocation(location) {
                const parts = [];

                location.placements.forEach((placement) => {
                    const component = this.viewController.selectedAtPlacement(placement.placement.code);

                    if (component && !component.placeholder) {
                        if (isFilled(component)) {
                            this.pushDescriptions(component, parts, [constants.DEFINITION_PATCH, constants.DEFINITION_THREAD, constants.DEFINITION_FONT]);
                        }
                    }
                });

                parts.push(location.name);

                return parts.join(', ');
            },

            /**
             * Formats filled logo location info.
             */
            formatLogoLocation(location) {
                const parts = [];

                location.placements.forEach((placement) => {
                    const component = this.viewController.selectedAtPlacement(placement.placement.code);

                    if (component && component.custom['image-name-original'] && component.custom['image-name-original'].length > 0) {
                        parts.push(component.custom['image-name-original']);
                    }
                });

                parts.push(location.name);

                return parts.join(', ');
            },

            /**
             * Adjust quantity.
             */
            adjustQuantity(v) {
                this.quantity += v;

                if (this.quantity < 1) {
                    this.quantity = 1;
                }

                this.$store.dispatch('setQuantity', this.quantity);
            },

            /**
             * Update quantity from input.
             */
            updateQuantity() {
                if (this.quantity < 1) {
                    this.quantity = 1;
                }
                this.$store.dispatch('setQuantity', this.quantity);
            },

            /**
             * Get location price.
             */
            getLocationPrice(location) {
                return this.locationPrices ? this.locationPrices[location.location] : '';
            },

            /**
             * Show business order popup.
             */
            businessOrder() {
                this.$store.dispatch('globalBusinessOrder');
            },

            /**
             * Does nothing.
             */
            nothing() {
            },

            switchToProgram() {
                this.$store.dispatch('showSwitchProgramConfirmation', true);
            },
        },
    };
</script>

<template>
    <div class="cz2__program-line">
        <label class="cz2__program-line__label cz2__type__small-header">{{$t('PROGRAM_CUSTOM_LINE', { line: index + 1})}}</label>

        <multiselect v-model="selected"
            :options="options"
            :searchable="false"
            :allow-empty="false"
            label="name"
            placeholder=""
            selectLabel=""
            deselectLabel=""
            :maxHeight="400"
        ></multiselect>

        <div v-if="selected && selected.type === 'custom'" class="cz2__program-line__custom">
            <input type="text"
                class="cz2__form-text"
                v-model.trim="selectedText"
                @input="updatePersonalization"
            />

            <div v-if="selectedText.length === 0" class="cz2__program-line__custom cz2__type__normal">
                {{$t('PROGRAM_ENTER_TEMPLATE')}}
            </div>
            <div v-else-if="selectedText.includes('{{')" class="cz2__program-line__custom cz2__type__normal">
                {{$t('PROGRAM_CUSTOM_EXAMPLE')}} {{sample}}
            </div>
        </div>
    </div>
</template>

<style lang="scss">
    @import "../style/variables.scss";

    #cz2.cz2 {
        .cz2__program-line {
            margin-bottom: 15px;
        }

        .cz2__program-line__label {
            display: block;

            margin-bottom: 5px;
        }

        .cz2__program-line__custom {
            margin-top: 5px;
        }

        .cz2__program-line__custom {
            margin-top: 5px;
        }
    }
</style>

<script>
    const CUSTOM_TEXT = '{{custom}}';

    export default {
        props: {
            index: Number,
            line: Object,
            templates: Array,
            samples: Object,
            placement: Object,
        },

        data() {
            return {
                selected: null,
                options: [],
                selectedText: '',
                sample: '',
            };
        },

        computed: {
            /**
             * Current view controller.
             */
            viewController() {
                return this.$store.state.customizer.viewController;
            },
        },

        watch: {
            line() {
                this.buildOptions();
            },

            templates() {
                this.buildOptions();
            },

            selected() {
                this.updateType();
            },
        },

        mounted() {
            this.buildOptions();
        },

        methods: {
            buildOptions() {
                const list = [];

                list.push({
                    name: this.$t('PROGRAM_CUSTOM_EMPTY'),
                    selected: this.line.value === '',
                });

                this.templates.forEach((t) => {
                    list.push({
                        name: t.sample,
                        template: t.template,
                        selected: this.line.type === 'template' && this.line.value === t.template,
                    });
                });

                list.push({
                    type: 'custom',
                    name: this.$t('PROGRAM_CUSTOM_ENTRY'),
                    selected: this.line.type === 'custom',
                });

                this.options = list;

                this.selected = list.find((x) => x.selected);

                if (this.selected.type === 'custom') {
                    this.selectedText = this.line.value.substr(CUSTOM_TEXT.length);

                    this.sample = this.makeSample(this.selectedText);
                } else {
                    this.selectedText = '';
                }
            },

            makeSample(text) {
                let value = text;

                if (value.startsWith(CUSTOM_TEXT)) {
                    value = value.substr(CUSTOM_TEXT.length);
                }

                Object.keys(this.samples).forEach((k) => {
                    value = value.replace(new RegExp(`{{${k}}}`, 'g'), this.samples[k]);
                });

                return value;
            },

            updateType() {
                if (this.selected.type === 'custom') {
                    const selected = this.viewController.selectedAtPlacement(this.placement.placement.code);

                    let filter = selected.custom['personalization-filter'];

                    if (filter && filter.length > 0) {
                        filter += '|[{}]';

                        filter = new RegExp(filter);

                        const filteredText = this.selectedText.split('').map((x) => (filter.test(x) ? x : '')).join('');

                        if (filteredText !== this.selectedText) {
                            this.selectedText = filteredText;
                        }
                    }

                    this.$emit('update', {
                        type: 'custom',
                        value: `${CUSTOM_TEXT}${this.selectedText}`,
                    });
                } else {
                    this.selectedText = '';

                    this.$emit('update', {
                        type: 'template',
                        value: this.selected.template ?? '',
                    });
                }
            },

            updatePersonalization() {
                this.sample = this.makeSample(this.selectedText);

                this.$emit('update', {
                    type: 'custom',
                    value: `${CUSTOM_TEXT}${this.selectedText}`,
                });
            },
        },
    };
</script>

var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',{class:[
    'cz2__menu-item',
    {
        'cz2__menu-item__complete': _vm.isComplete,
        'cz2__menu-item--active': _vm.isActive,
        'cz2__menu-item--with-error': _vm.hasErrors,
        'cz2__menu-item--multiline': _vm.multilineDescription,
    }
]},[_c('div',{staticClass:"cz2__menu-item__inner"},[_c('span',{staticClass:"cz2__menu-item__complete-indicator"}),(_vm.hasErrors)?_c('span',{staticClass:"cz2__menu-item__error-icon"}):_c('span',{staticClass:"cz2__menu-item__placeholder"}),_c('button',{on:{"click":function($event){$event.preventDefault();return _vm.selectItem.apply(null, arguments)}}},[_c('span',{staticClass:"cz2__menu-item__button-content"},[_c('span',{staticClass:"cz2__type__small-header"},[_vm._v(" "+_vm._s(_vm.title)+" "),(_vm.tip)?[_c('span',{class:[
                                'cz2__menu-item__tip',
                                {
                                    'cz2__menu-item__tip--active': _vm.tipActive,
                                }
                            ],on:{"click":function($event){$event.preventDefault();$event.stopPropagation();return _vm.showTip.apply(null, arguments)}}},[_c('span',{staticClass:"cz2__menu-item__tooltip"},[_vm._v(" "+_vm._s(_vm.tip)+" ")])])]:_vm._e(),(_vm.price !== null)?_c('span',{staticClass:"cz2__menu-item__price"},[_vm._v(_vm._s(_vm.price))]):_vm._e()],2),_c('span',{class:['cz2__menu-item__description', 'cz2__type__normal', {
                    'cz2__menu-item__description--no-caps': _vm.capitalize == false,
                    'cz2__menu-item__description--multiline': _vm.multilineDescription
                }]},[_vm._v(" "+_vm._s(_vm.description)+" ")])])]),(!_vm.readonly)?_c('span',{staticClass:"cz2__menu-item__expand-indicator"}):_vm._e()])])
}
var staticRenderFns = []

export { render, staticRenderFns }
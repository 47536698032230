import Vue from 'vue';

import Uri from 'jsuri';
import axios from 'axios';

import * as constants from '../../constants';

// Debounce timer for price updates.
let updatePriceTimer = null;

/**
 * Checks if component is filled.
 */
function isFilled(component) {
    if (component.custom['widget-type'] === 'personalization') {
        return (component.custom['personalization-text'] || '').length > 0;
    }

    if (component.custom['widget-type'] === 'dynamic-image') {
        return (component.custom['dynamic-image-url'] || '').length > 0;
    }

    return !component.placeholder;
}

function getLocationsWithActivity(controller, activities) {
    // Group placements by location.
    const locations = [];

    if (controller == null) {
        return locations;
    }

    controller.state.forEach((s) => {
        const l = s.placement.custom.location;
        const a = s.placement.custom.activity;

        if (s.placement.custom['placement-enabled'] === 'false') {
            return;
        }

        if (l && a) {
            const existing = locations[l];
            if (existing) {
                existing.filled = existing.filled || isFilled(s.component);
                existing.placements.push(s);
                existing.activities.push(a);
            } else {
                locations[l] = {
                    location: l,
                    name: s.placement.custom['location-name'],
                    x: s.placement.custom['placement-x'],
                    y: s.placement.custom['placement-y'],
                    width: s.placement.custom['placement-width'],
                    height: s.placement.custom['placement-height'],
                    scale: s.placement.custom['placement-scale'],
                    filled: isFilled(s.component),
                    placements: [s],
                    activities: [a],
                };
            }
        }
    });

    return Object.values(locations).filter((l) => l.activities.some((a) => activities.includes(a)));
}

function filledWithActivity(location, activities) {
    return location.filter((l) => {
        let active = null;

        l.placements.forEach((placement) => {
            if (activities.includes(placement.placement.custom.activity)) {
                if (isFilled(placement.component)) {
                    active = placement.component;
                }
            }
        });

        return active;
    });
}

export default {
    state() {
        return {
            // Widget option as passed from the host site.
            options: {},

            // Running in program recipe generator mode?
            programGenerator: false,

            // Currently outstanding program requests.
            programRequests: [],

            // Collected program recipes.
            programRecipes: [],

            // Default controller parameters.
            defaultControllerOptions: {},

            // Controller that drives the image view.
            viewController: null,

            // Selected size data.
            selectedSize: null,

            // Product variants.
            variants: null,

            // Quantity.
            quantity: 1,

            // Location prices.
            locationPrices: null,

            // Product price.
            productPrice: null,

            // Customizations price.
            customizationsPrice: null,

            // Digitization fees.
            digitizationsPrice: null,

            // Subtotal price.
            subtotalPrice: null,

            // Logged status.
            isLoggedIn: false,

            // Digitization price.
            digitizationPrice: 50,

            // Digitization price.
            digitizationPriceFormatted: '$50.00',

            // Currently focused placement.
            focusedPlacement: null,

            // Show a placement overlay for a placement.
            overlayPlacement: null,

            overlayPlacementHeader: null,

            overlayPlacementBody: null,
        };
    },

    getters: {
        /**
         * Gets a list of available lots/colors.
         */
        availableLots(state) {
            const lots = [];

            state.variants.variants.forEach((v) => {
                if (!v.available) {
                    return;
                }

                const lot = lots.find((l) => l.lot === v.lot);
                if (lot == null) {
                    lots.push({
                        lot: v.lot,
                        name: v.color.name,
                        hex: v.color.hex,
                        images: v.color.images,
                    });
                }
            });

            return lots;
        },

        /**
         * Gets currently selected color name.
         */
        selectedColor(state) {
            if (state.viewController == null) {
                return '';
            }

            const product = state.viewController.selectedAtPlacement(constants.PLACEMENT_PRODUCT);

            if (product != null) {
                const lot = product.code;

                const variant = state.variants.variants.find((v) => v.lot === lot);
                if (variant && variant.color) {
                    return variant.color.name;
                }

                return lot;
            }

            return '';
        },

        /**
         * Does controlled product have sizes.
         */
        hasSizes(state) {
            if (state.variants == null) {
                return false;
            }

            for (let i = 0; i < state.variants.variants.length; i += 1) {
                const v = state.variants.variants[i];

                if (v.size != null || v.body !== null) {
                    return true;
                }
            }

            return false;
        },

        /**
         * Could the design be built?
         */
        canBuild(state, getters) {
            return getters.canBuildReason == null;
        },

        /**
         * Could the design be built?
         */
        canBuildReason(state, getters) {
            if (state.viewController) {
                if (!state.viewController.canBuild({ ignoreOptional: true })) {
                    return 'components';
                }
            } else {
                return 'controller';
            }

            if (!getters.isProgramMode) {
                if (state.selectedSize == null) {
                    return 'size';
                }
            }

            return null;
        },

        /**
         * Does product have sizes?
         */
        supportsSizes() {
            return true;
        },

        /**
         * All embroiderable locations;
         */
        allEmbroidery(state) {
            return getLocationsWithActivity(state.viewController, constants.ACTIVITY_EMBROIDERY);
        },

        /**
         * Are there free embroidery placements?
         */
        freeEmbroidery(state) {
            return getLocationsWithActivity(state.viewController, constants.ACTIVITY_EMBROIDERY)
                .filter((s) => !s.filled);
        },

        /**
         * Are there taken embroidery placements?
         */
        filledEmbroidery(state) {
            return filledWithActivity(
                getLocationsWithActivity(state.viewController, constants.ACTIVITY_EMBROIDERY),
                constants.ACTIVITY_EMBROIDERY,
            );
        },

        /**
         * All art locations.
         */
        allArt(state) {
            return getLocationsWithActivity(state.viewController, constants.ACTIVITY_ART);
        },

        /**
         * Are there free art placements?
         */
        freeArt(state) {
            return getLocationsWithActivity(state.viewController, constants.ACTIVITY_ART)
                .filter((s) => !s.filled);
        },

        /**
         * Are there taken art placements?
         */
        filledArt(state) {
            return filledWithActivity(
                getLocationsWithActivity(state.viewController, constants.ACTIVITY_ART),
                constants.ACTIVITY_ART,
            );
        },

        /**
         * All logo locations.
         */
        allLogo(state) {
            return getLocationsWithActivity(state.viewController, constants.ACTIVITY_LOGO);
        },

        /**
         * Are there free logo placements?
         */
        freeLogo(state) {
            return getLocationsWithActivity(state.viewController, constants.ACTIVITY_LOGO)
                .filter((s) => !s.filled);
        },

        /**
         * Are there taken embroidery placements?
         */
        filledLogo(state) {
            return filledWithActivity(
                getLocationsWithActivity(state.viewController, constants.ACTIVITY_LOGO),
                constants.ACTIVITY_LOGO,
            );
        },

        /**
         * Are there any product customizations at all?
         */
        hasAnyActivities(state) {
            return state.viewController
                    && state.viewController.state.some((placement) => placement.placement.code !== 'Product' && isFilled(placement.component));
        },

        /**
         * Should the logo size be limited to 2" height?
         */
        isLogo2High(state) {
            return state.viewController
                && state.viewController.blueprint
                && state.viewController.blueprint.custom
                && state.viewController.blueprint.custom['storefront.essential'] === 'Bulwark';
        },

        /**
         * Are we in the program mode?
         */
        isProgramMode(state) {
            return state.options.mode === 'program';
        },
    },

    mutations: {
        /**
         * Saves options to state.
         */
        SET_CUSTOMIZER_OPTIONS(state, options) {
            state.options = options;

            state.defaultControllerOptions = {
                apiKey: options.apiKey,
                products: options.products,
                preview: options.preview,
                disableCaching: options.disableCaching,
            };
        },

        /**
         * Save program mode.
         */
        SET_PROGRAM_GENERATOR(state, v) {
            state.programGenerator = v;
        },

        /**
         * Update program requests.
         */
        SET_PROGRAM_REQUESTS(state, v) {
            state.programRequests = v;
        },

        /**
         * Update program recipes.
         */
        SET_PROGRAM_RECIPES(state, v) {
            state.programRecipes = v;
        },

        /**
         * Switches a controller that is displayed in the image view.
         */
        SET_VIEW_CONTROLLER(state, controller) {
            if (controller !== state.viewController) {
                // Disconnect all event listeners from the old controller.
                if (state.viewController) {
                    state.viewController.off();
                }

                state.viewController = controller;
            }
        },

        /**
         * Saves selected size data.
         */
        SET_SELECTED_SIZE(state, v) {
            state.selectedSize = v;
        },

        /**
         * Saves variants.
         */
        SET_VARIANTS(state, v) {
            state.variants = v;
        },

        /**
         * Saves quantity.
         */
        SET_QUANTITY(state, v) {
            state.quantity = v;
        },

        /**
         * Saves prices.
         */
        SET_PRICES(state, {
            locations,
            product,
            customizations,
            digitizations,
            subtotal,
        }) {
            state.locationPrices = locations;
            state.productPrice = product;
            state.customizationsPrice = customizations;
            state.digitizationsPrice = digitizations;
            state.subtotalPrice = subtotal;
        },

        /**
         * Save focused placement.
         */
        SET_FOCUSED_PLACEMENT(state, placement) {
            if (placement) {
                state.focusedPlacement = placement.placement;
            } else {
                state.focusedPlacement = null;
            }
        },

        /**
         * Save placement overlay data.
         */
        SET_PLACEMENT_OVERLAY(state, placement) {
            if (placement) {
                state.overlayPlacement = placement.placement;
                state.overlayPlacementHeader = placement.header;
                state.overlayPlacementBody = placement.body;
            } else {
                state.overlayPlacement = null;
            }
        },

        SET_DIGITIZATION_PRICE(state, { price, priceFormatted }) {
            state.digitizationPrice = price;
            state.digitizationPriceFormatted = priceFormatted;
        },

        SWITCH_TO_PROGRAM(state) {
            Vue.set(state.options, 'mode', 'program');
        },
    },

    actions: {
        /**
         * Saves options.
         */
        setCustomizerOptions({ commit }, options) {
            if (options.products) {
                if (!Array.isArray(options.products)) {
                    options.products = [options.products];
                }

                options.products = options.products.slice(0, 1);
            }

            commit('SET_CUSTOMIZER_OPTIONS', options);
        },

        /**
         * Begins Loading the customizer.
         */
        load({ dispatch }) {
            dispatch('loadCustomizeFlow');
        },

        /**
         * Load the customizer, format the program recipes, and exit.
         */
        loadProgram({ commit, dispatch, state }) {
            commit('SET_PROGRAM_GENERATOR', true);

            if (state.options.recipe) {
                // Get product ids from the recipe.
                axios.get(`//cz.drrv.co/recipe/${state.options.recipe}.json`).then((recipeData) => {
                    const recipe = recipeData.data;

                    commit('SET_CUSTOMIZER_OPTIONS', {
                        ...state.options,
                        ...{
                            products: [recipe.styleCode],
                        },
                    });

                    // Continue loading. We'll pick back up at the program mode at the end of loadColors.
                    dispatch('loadCustomizeFlow');
                });
            }
        },

        /**
         * Continue loading the main controller.
         */
        loadCustomizeFlowContinue({ state, dispatch, commit }, { startWithProducts, recipe }) {
            const loadProducts = startWithProducts;

            const controller = new state.options.DriveCustomizer.Controller(
                ({
                    ...state.defaultControllerOptions,
                    ...loadProducts,
                }),
            );

            controller.on('afterInitializeState', () => {
                if (recipe) {
                    // eslint-disable-next-line
                    console.log('Restoring the state from a recipe');

                    recipe.components.forEach((component) => {
                        if (component.component) {
                            // Find matching component in the blueprint, otherwise isAvailable doesn't work
                            // since recipes dont include availability information.
                            const placement = controller.findPlacement(component.code);
                            if (placement && placement.components) {
                                const match = placement.components.find((c) => c.code === component.component.code);
                                if (match && !match.placeholder) {
                                    if (controller.isAvailable(match)) {
                                        controller.updateComponent(component.code, component.component.code);

                                        Object.assign(placement.custom, {
                                            'placement-x': component.custom['placement-x'],
                                            'placement-y': component.custom['placement-y'],
                                            'placement-x-original': component.custom['placement-x-original'],
                                            'placement-y-original': component.custom['placement-y-original'],
                                            'personalization-x': component.custom['personalization-x'],
                                            'personalization-y': component.custom['personalization-y'],
                                            'personalization-x-original': component.custom['personalization-x-original'],
                                            'personalization-y-original': component.custom['personalization-y-original'],
                                        });

                                        // Copy personalization text.
                                        if (component.component.custom && component.component.custom['personalization-text']) {
                                            const copy = {
                                                'personalization-template': component.component.custom['personalization-template'],
                                                'personalization-limit-lines': component.component.custom['personalization-limit-lines'],
                                                'personalization-limit-line': component.component.custom['personalization-limit-line'],
                                                'personalization-text': component.component.custom['personalization-text'],
                                                'personalization-text-1': component.component.custom['personalization-text-1'],
                                                'personalization-text-2': component.component.custom['personalization-text-2'],
                                                'personalization-text-3': component.component.custom['personalization-text-3'],
                                                'personalization-text-4': component.component.custom['personalization-text-4'],
                                                'personalization-text-5': component.component.custom['personalization-text-5'],
                                                'personalization-vertical-alignment': component.component.custom['personalization-vertical-alignment'],
                                            };
                                            placement.components.forEach((c) => {
                                                Object.assign(c.custom, copy);
                                            });
                                        }

                                        // Copy logo.
                                        if (component.component.custom && component.component.custom['dynamic-image-url']) {
                                            const copy = {
                                                'dynamic-image-title': component.component.custom['dynamic-image-title'],
                                                'dynamic-image-name': component.component.custom['dynamic-image-name'],
                                                'dynamic-image-url': component.component.custom['dynamic-image-url'],
                                                'dynamic-image-pngUrl': component.component.custom['dynamic-image-pngUrl'],
                                                'dynamic-image-jpgUrl': component.component.custom['dynamic-image-jpgUrl'],
                                                'dynamic-image-width': component.component.custom['dynamic-image-width'],
                                                'dynamic-image-height': component.component.custom['dynamic-image-height'],
                                                'dynamic-image-x': component.component.custom['dynamic-image-x'],
                                                'dynamic-image-y': component.component.custom['dynamic-image-y'],
                                                'dynamic-image-scale': component.component.custom['dynamic-image-scale'],
                                                'dynamic-image-rotate': component.component.custom['dynamic-image-rotate'],
                                                'dynamic-image-density': component.component.custom['dynamic-image-density'],
                                                'dynamic-image-area-width': component.component.custom['dynamic-image-area-width'],
                                                'dynamic-image-area-height': component.component.custom['dynamic-image-area-height'],
                                                'dynamic-image-area-x': component.component.custom['dynamic-image-area-x'],
                                                'dynamic-image-area-y': component.component.custom['dynamic-image-area-y'],
                                                'dynamic-image-area-center-x': component.component.custom['dynamic-image-area-center-x'],
                                                'dynamic-image-area-center-y': component.component.custom['dynamic-image-area-center-y'],
                                                'digitization-needed': component.component.custom['digitization-needed'],
                                                'image-name-original': component.component.custom['image-name-original'],
                                                'consent-ownership': component.component.custom['consent-ownership'],
                                            };

                                            placement.components.forEach((c) => {
                                                Object.assign(c.custom, copy);
                                            });
                                        }
                                    }
                                }
                            }
                        }
                    });

                    if (recipe.custom['selected-variant']) {
                        const variantId = recipe.custom['selected-variant'];

                        const variant = state.variants.variants.find((v) => v.variantId === variantId);

                        if (variant) {
                            commit('SET_SELECTED_SIZE', variant);
                        }
                    }
                } else if (state.options.selectedVariant) {
                    const variantId = state.options.selectedVariant;

                    const variant = state.variants.variants.find((v) => v.variantId === variantId);

                    if (variant) {
                        if (variant.lot) {
                            const productPlacement = controller.findPlacement(constants.PLACEMENT_PRODUCT);

                            const selectedComponent = productPlacement.components.find((x) => x.code === variant.lot);

                            if (selectedComponent) {
                                controller.updateComponent(constants.PLACEMENT_PRODUCT, selectedComponent.code);
                            }
                        }
                        commit('SET_SELECTED_SIZE', variant);
                    }
                } else if (state.options.selectedLot) {
                    const lot = state.options.selectedLot;

                    const productPlacement = controller.findPlacement(constants.PLACEMENT_PRODUCT);

                    const selectedComponent = productPlacement.components.find((x) => x.code === lot);

                    if (selectedComponent) {
                        controller.updateComponent(constants.PLACEMENT_PRODUCT, selectedComponent.code);
                    }
                }
            });

            controller.on('afterInitializeBlueprint', () => {
                // Fix up backgrounds.
                controller.blueprint.views.forEach((v) => {
                    if (v.custom['image-file-format-frame']) {
                        v.custom['image-file-format-frame'] = v.custom['image-file-format-frame'].replace('&bgc=244,244,244', '&bgc=E2E2DD');
                    }
                });

                // Load actual product options.
                dispatch('loadColors', {
                    controllers: [controller],
                });
            });

            // Load controller.
            dispatch('loadController', controller);
        },

        /**
         * Loads customization flow.
         */
        loadCustomizeFlow({ dispatch, commit, state }) {
            state.options.getVariants(state.options.products[0]).then((variants) => {
                commit('SET_VARIANTS', variants);

                const uri = new Uri(window.location.href);

                let recipeId = uri.getQueryParamValue('recipe');

                if (state.options.recipe) {
                    recipeId = state.options.recipe;
                }

                if (recipeId) {
                    uri.deleteQueryParam('recipe');

                    window.history.replaceState(null, '', uri.toString());

                    axios.get(`//cz.drrv.co/recipe/${recipeId}.json`).then((recipeData) => {
                        dispatch('loadCustomizeFlowContinue', {
                            startWithProducts: state.options.products,
                            recipe: recipeData.data,
                        });
                    });
                } else {
                    dispatch('loadCustomizeFlowContinue', {
                        startWithProducts: state.options.products,
                    });
                }
            });
        },

        /*
         * Loads product options.
         */
        loadColors({ state, dispatch }, { controllers }) {
            let showController = null;

            if (controllers.length > 0) {
                // eslint-disable-next-line
                showController = controllers[0];
            }

            if (showController) {
                dispatch('showController', showController);
            }

            if (state.programGenerator) {
                dispatch('processProgramRequests');
            }

            dispatch('globalBusy', false);
        },

        /**
         * Displays the controller in the render view.
         */
        showController({ commit, dispatch }, controller) {
            commit('SET_VIEW_CONTROLLER', controller);

            if (controller) {
                controller.off('.store');

                controller.on('error.store', (e) => {
                    // eslint-disable-next-line
                    console.log(JSON.stringify(e));
                });

                // Listen to controller level events.
                // We wouldn't need to turn off listeners when an active controller changes since
                // previous controllers would be disabled automatically.
                controller.on('stateChanged.store', () => {
                    dispatch('updatePriceDebounce', controller);
                });

                dispatch('updatePrice', controller);
            }
        },

        /**
         * Debounced price update.
         */
        updatePriceDebounce({ dispatch }, controller) {
            if (updatePriceTimer) {
                clearTimeout(updatePriceTimer);
            }

            updatePriceTimer = setTimeout(() => {
                dispatch('updatePrice', controller);
            });
        },

        /**
         * Updates current pricing with controller information.
         */
        updatePrice({
            dispatch,
            commit,
            state,
            rootState,
        }, controller) {
            // Update price based on quantity.
            if (rootState.ecommerce.products) {
                const { quantity } = state;

                const quantities = {};

                // Compute a number of quantities for each product.
                controller.state.forEach((s) => {
                    if (isFilled(s.component)) {
                        const { activity } = s.placement.custom;

                        const activityType = s.placement.custom['activity-type'];

                        const product = controller.blueprint.custom[`activity-product-${activity}`];

                        if (product && activityType !== 'patch') {
                            if (quantities[product] == null) {
                                quantities[product] = 0;
                            }

                            quantities[product] += 1;
                        }
                    }
                });

                rootState.ecommerce.products.forEach((product) => {
                    if (product.pricing && product.pricing.length > 0) {
                        let found = null;

                        const q = quantity * (quantities[product.productId] || 1) + (product.basketQuantity || 0);

                        for (let i = 0; i < product.pricing.length; i += 1) {
                            if (product.pricing[i].quantity <= q) {
                                found = product.pricing[i];
                            }
                        }

                        if (found) {
                            product.price = found.price;
                        }
                    }
                });
            }

            // Calculate the current product price.
            dispatch('getStatePrice', {
                controller,
            }).then((resolved) => {
                const locations = {};
                let subtotal = 0;
                let product = 0;
                let customizations = 0;
                let digitizations = 0;

                resolved.breakdown.forEach((placement) => {
                    subtotal += placement.current;

                    if (placement.placement === constants.PLACEMENT_PRODUCT) {
                        product = placement.current;
                    } else {
                        customizations += placement.current;

                        const p = controller.findPlacement(placement.placement);
                        if (p) {
                            const { location } = p.custom;

                            if (locations[location]) {
                                locations[location] += placement.current;
                            } else {
                                locations[location] = placement.current;
                            }
                        }
                    }
                });

                const seenImages = {};

                // Check about digitization prices.
                controller.state.forEach((s) => {
                    if (isFilled(s.component)) {
                        if (s.component.custom && s.component.custom['digitization-recent'] === 'true') {
                            const url = s.component.custom['dynamic-image-url'];

                            if (!seenImages[url]) {
                                seenImages[url] = true;

                                digitizations += state.digitizationPrice;
                            }
                        }
                    }
                });

                Promise.all([
                    dispatch('formatPrice', (subtotal * state.quantity + digitizations).toFixed(2)).then((v) => {
                        subtotal = v;
                    }),
                    dispatch('formatPrice', (product * state.quantity).toFixed(2)).then((v) => {
                        product = v;
                    }),
                    dispatch('formatPrice', (customizations * state.quantity).toFixed(2)).then((v) => {
                        customizations = v;
                    }),
                    digitizations > 0
                        ? dispatch('formatPrice', (digitizations).toFixed(2)).then((v) => {
                            digitizations = v;
                        })
                        : Promise.resolve(null),
                    ...Object.keys(locations).map((name) => dispatch('formatPrice', locations[name]).then((v) => {
                        locations[name] = v;
                    })),
                ]).then(() => {
                    commit('SET_PRICES', {
                        locations,
                        subtotal,
                        product,
                        customizations,
                        digitizations,
                    });
                });
            });
        },

        /**
         * Save recipe.
         */
        customizerDone({
            dispatch,
            state,
            getters,
        }) {
            return new Promise((resolve) => {
                function continueSaving() {
                    state.viewController.saveRecipe({
                        translateCode(componentCode) {
                            return componentCode;
                        },

                        translateRecipe(recipe) {
                            if (state.selectedSize) {
                                recipe.custom['selected-variant'] = state.selectedSize.variantId;
                            }

                            recipe.custom.quantity = state.quantity.toString();

                            if (getters.isProgramMode) {
                                recipe.custom.mode = 'program';

                                const product = state.viewController.selectedAtPlacement(constants.PLACEMENT_PRODUCT);

                                const lot = product.code;

                                recipe.custom['selected-lot'] = lot;

                                let useVariant = null;

                                state.variants.variants.forEach((v) => {
                                    if (v.lot === lot) {
                                        if (useVariant == null) {
                                            useVariant = v;
                                        }
                                    }
                                });

                                if (useVariant) {
                                    recipe.custom['selected-variant'] = useVariant.variantId;
                                }
                            }
                        },
                    }).then((recipe) => {
                        dispatch('globalBusy', false);

                        if (getters.isProgramMode) {
                            dispatch('globalDone', {
                                type: 'custom',
                                mode: 'program',
                                recipe,
                            });
                        } else {
                            dispatch('globalDone', {
                                type: 'custom',
                                recipe,
                            });
                        }

                        if (getters.isProgramMode) {
                            // Return straight back to host.
                            dispatch('globalClose');
                        } else {
                            // Show confirmation screen.
                            dispatch('showDoneConfirmation', true);
                        }

                        resolve();
                    });
                }

                if (state.viewController) {
                    dispatch('globalBusy', true);

                    continueSaving();
                } else {
                    dispatch('globalCancel');

                    resolve();
                }
            });
        },

        /**
         * Selects a size.
         */
        selectSize({ commit, dispatch, state }, v) {
            commit('SET_SELECTED_SIZE', v);

            dispatch('updatePrice', state.viewController);
        },

        /**
         * Validates that the current size selection is valid for the lot.
         */
        validateSizeForLot({ state, dispatch }) {
            if (state.selectedSize) {
                const product = state.viewController.selectedAtPlacement(constants.PLACEMENT_PRODUCT);
                const lot = product.code;
                const {
                    variants,
                    variationAttributes,
                } = state.variants;

                let found = null;

                // Look at every color variant match to see if one matches previously selected sizes (if set)
                for (let variantIndex = 0; variantIndex < variants.length; variantIndex += 1) {
                    const variant = variants[variantIndex];

                    if (variant.lot === lot) {
                        let match = null;

                        // Need two variables, because no variant could be selected
                        let isSizeSelected = false;
                        let variantIsMatch = true;
                        for (let i = 0; i < variationAttributes.length; i += 1) {
                            const sizeAttrKey = variationAttributes[i];
                            const stateSizeVal = state.selectedSize[sizeAttrKey];
                            // If there is a selected value for that size attribute, but it doesn't match the variant attribute value
                            if (stateSizeVal !== null) {
                                isSizeSelected = true;
                                if (variant[sizeAttrKey] !== stateSizeVal) {
                                    variantIsMatch = false;
                                }
                            }
                        }

                        if (isSizeSelected && variantIsMatch) {
                            match = variant;
                        }

                        if (match) {
                            found = variant;
                            break;
                        }
                    }
                }

                dispatch('selectSize', found);
            }
        },

        /**
         * Set order quantity.
         */
        setQuantity({ commit, state, dispatch }, v) {
            commit('SET_QUANTITY', v);

            if (state.viewController) {
                dispatch('updatePriceDebounce', state.viewController);
            }
        },

        /**
         * Finds a filled placement for a location.
         */
        getFilledPlacement(_, location) {
            let active = null;

            location.placements.forEach((placement) => {
                if (isFilled(placement.component)) {
                    active = placement;
                }
            });

            return active;
        },

        /**
         * Clears placement.
         */
        clearPlacement({ state }, placement) {
            const placementCode = placement.placement.code;
            const selectedComponent = state.viewController.selectedAtPlacement(placementCode);
            if (selectedComponent.custom['dynamic-image-url'] && selectedComponent.custom['dynamic-image-url'].length > 0) {
                state.viewController.updateDynamicImage(placementCode, {
                    title: '',
                    name: '',
                    url: '',
                    pngUrl: '',
                    jpgUrl: '',
                    width: '',
                    height: '',
                });

                Vue.set(selectedComponent.custom, 'digitization-needed', '');
                Vue.set(selectedComponent.custom, 'image-name-original', '');
            }

            if (selectedComponent.custom['personalization-text'] && selectedComponent.custom['personalization-text'].length > 0) {
                state.viewController.updatePersonalization(placementCode, '');
            }

            state.viewController.clearComponent(placementCode);
        },

        /**
         * Resets Customizations and revert selected step
         */
        resetCustomizations({ state, dispatch }) {
            if (!state.viewController) {
                return;
            }

            const placements = state.viewController.state;

            placements.forEach((placement) => {
                const { code } = placement.placement;
                if (code !== 'Product') {
                    dispatch('clearPlacement', placement);
                }
            });

            dispatch('selectStep', null);
        },

        /**
         * Sets a placement to focus on or reverts back.
         */
        setFocusedPlacement({ commit }, placement) {
            commit('SET_FOCUSED_PLACEMENT', placement);
        },

        /**
         * Sets a placement overlay.
         */
        showPlacementOverlay({ commit }, placement) {
            commit('SET_PLACEMENT_OVERLAY', placement);
        },

        /**
         * Save digitization price.
         */
        setDigitizationPrice({ commit }, price) {
            commit('SET_DIGITIZATION_PRICE', price);
        },

        /**
         * Get artwork data.
         */
        getSavedArtwork({ state }) {
            return new Promise((resolve) => {
                state.options.getArtworkData().then((result) => {
                    resolve(result);
                });
            });
        },

        /**
         * Request to login.
         */
        login({ state, dispatch }) {
            function continueSaving() {
                state.viewController.saveRecipe({
                    translateCode(componentCode) {
                        return componentCode;
                    },

                    translateRecipe(recipe) {
                        if (state.selectedSize) {
                            recipe.custom['selected-variant'] = state.selectedSize.variantId;
                        }
                    },
                }).then((recipe) => {
                    dispatch('globalBusy', false);

                    const loginLink = Array.from(document.querySelectorAll('.page-header a')).find((link) => link.href.indexOf('login') >= 0);

                    if (loginLink) {
                        const uri = new Uri(loginLink);

                        const currentPage = new Uri(window.location.href);

                        currentPage.deleteQueryParam('recipe');
                        currentPage.addQueryParam('recipe', recipe.id);

                        uri.addQueryParam('returnTo', currentPage.toString());

                        window.location.href = uri.toString();
                    }
                });
            }

            if (state.viewController) {
                dispatch('globalBusy', true);

                continueSaving();
            }
        },

        /**
         * Continue customization process for another person.
         */
        continueCustomizing({ state, dispatch }) {
            // Reload pricing.
            dispatch('getPrices', {
                controller: state.viewController,
            }).then(() => {
                dispatch('updatePrice', state.viewController);
            });

            // Reset digitization flag since it will be handled by another product.
            state.viewController.state.forEach((s) => {
                if (s.component.custom['digitization-needed'] === 'true') {
                    state.viewController.commitCustomAttributes(s.placement.code, {
                        'digitization-needed': 'false',
                    });
                }
            });
        },

        /**
         * Switch from standalone mode to the program.
         */
        switchToProgram({ state, dispatch, commit }) {
            commit('SWITCH_TO_PROGRAM');

            if (!state.viewController) {
                return;
            }

            const placements = state.viewController.state;

            placements.forEach((placement) => {
                const { code } = placement.placement;

                if (constants.ACTIVITY_EMBROIDERY.some((x) => code.includes(`-${x}`))) {
                    dispatch('clearPlacement', placement);
                }
            });

            dispatch('selectStep', null);
        },

        /**
         * Make program recipes using substitutions.
         */
        processProgramRequests({ state, dispatch, commit }) {
            // eslint-disable-next-line
            console.log('Load program requests...');

            const team = state.options.team || [];

            commit('SET_PROGRAM_REQUESTS', team);

            dispatch('processNextProgramRequest', 0);
        },

        /**
         * Grab the next team member, render it, and continue.
         */
        processNextProgramRequest({ state, commit, dispatch }, index) {
            const team = [...state.programRequests];

            if (team.length === 0) {
                // Done.
                if (state.options.onProgramDone) {
                    state.options.onProgramDone(state.programRecipes);
                }
            } else {
                const request = team.shift();

                commit('SET_PROGRAM_REQUESTS', team);

                dispatch('makeProgramRecipe', {
                    request,
                    index: index + 1,
                });
            }
        },

        /**
         * Substitute templates and make a recipe.
         */
        makeProgramRecipe({ state, commit, dispatch }, { request, index }) {
            // Debugging flag.
            if (request.ignore) {
                dispatch('processNextProgramRequest', index);

                return;
            }

            // Clone the current controller so we could safely modify it.
            const controller = state.viewController.clone();

            const warnings = [];

            // Select sizes.
            const selectedLot = controller.selectedAtPlacement(constants.PLACEMENT_PRODUCT);
            if (selectedLot != null) {
                const lot = selectedLot.placementIndependentCode;

                const candidates = state.variants.variants.filter((v) => v.lot === lot && v.available > 0);

                let useVariant = null;

                // eslint-disable-next-line no-restricted-syntax
                for (const variant of candidates) {
                    let any = false;
                    let all = true;

                    // eslint-disable-next-line no-restricted-syntax
                    for (const size of request.size) {
                        if (variant[size.name] === size.value) {
                            any = true;
                        } else {
                            all = false;
                        }
                    }

                    if (any && all) {
                        useVariant = variant;
                    }
                }

                if (useVariant) {
                    commit('SET_SELECTED_SIZE', useVariant);
                } else {
                    warnings.push(`The request at position ${index} does not have an in-stock size`);

                    commit('SET_PROGRAM_RECIPES', [...state.programRecipes, {
                        warnings,
                    }]);

                    dispatch('processNextProgramRequest', index);

                    return;
                }
            }

            // Scan all states and find ones with a template.
            // eslint-disable-next-line no-restricted-syntax
            for (const s of controller.state) {
                if (s.component.custom?.['personalization-template'] && s.component.custom?.['personalization-template'].length > 0) {
                    // Found one!
                    const template = s.component.custom?.['personalization-template'];
                    let value = template;

                    // eslint-disable-next-line prefer-regex-literals
                    value = value.replace(new RegExp('{{custom}}', 'g'), '');

                    // eslint-disable-next-line no-restricted-syntax
                    for (const variable of request.variables) {
                        value = value.replace(new RegExp(`{{${variable.name}}}`, 'g'), variable.value);
                    }

                    if (value.includes('{{') || value.includes('}}')) {
                        warnings.push(`The request at position ${index} contains unfulfilled template variables: ${value}`);

                        // eslint-disable-next-line no-continue
                        continue;
                    }

                    // Filter to allowed characters.
                    const lines = value.split('\n').map((x) => x.trim());

                    const filteredLines = [];

                    let filter = s.component.custom['personalization-filter'];

                    if (filter && filter.length > 0) {
                        filter = new RegExp(filter);

                        // eslint-disable-next-line no-restricted-syntax
                        for (const l of lines) {
                            const filteredText = l.split('').map((x) => (filter.test(x) ? x : '')).join('');

                            if (l !== filteredText) {
                                // eslint-disable-next-line max-len
                                warnings.push(`The request at position ${index} contains characters that would not be supported by the embroidery font: ${l} on template ${template}`);
                            }

                            filteredLines.push(filteredText);
                        }
                    }

                    // Validate length.
                    if (s.component.custom['personalization-limit-line'] && s.component.custom['personalization-limit-line'].length > 0) {
                        const limit = +s.component.custom['personalization-limit-line'];

                        // eslint-disable-next-line no-restricted-syntax
                        for (const l of filteredLines) {
                            if (l.length > limit) {
                                // eslint-disable-next-line max-len
                                warnings.push(`The request at position ${index} has a line with too many characters for the selected font and size: ${l} on template ${template}`);
                            }
                        }
                    }

                    const combined = filteredLines.join('\n');

                    controller.updatePersonalization(s.placement.code, combined);
                }
            }

            if (warnings.length > 0) {
                commit('SET_PROGRAM_RECIPES', [...state.programRecipes, {
                    warnings,
                }]);

                dispatch('processNextProgramRequest', index);

                return;
            }

            // If we got here, we are probably ok to generate a new recipe.
            controller.saveRecipe({
                translateRecipe(recipe) {
                    if (state.selectedSize) {
                        recipe.custom['selected-variant'] = state.selectedSize.variantId;
                    }

                    recipe.custom.quantity = '1';

                    const product = state.viewController.selectedAtPlacement(constants.PLACEMENT_PRODUCT);
                    const lot = product.code;

                    recipe.custom['selected-lot'] = lot;
                },
            }).then((recipe) => {
                // Grab the recipe.
                axios.get(recipe.location).then((data) => {
                    const requestRecipe = data.data;

                    const views = [];

                    // eslint-disable-next-line no-restricted-syntax
                    for (const view of requestRecipe.views) {
                        if (view.previewPng) {
                            views.push({
                                code: view.code,
                                name: view.name,
                                previewPng: view.previewPng,
                            });
                        }
                    }

                    commit('SET_PROGRAM_RECIPES', [...state.programRecipes, {
                        recipe,
                        views,
                    }]);

                    dispatch('processNextProgramRequest', index);
                });
            });
        },
    },
};

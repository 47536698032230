<template>
    <div class="cz2__panel cz2__panel--secondary cz2__edit-logo">
        <focus-lock>
            <div class="cz2__panel__body cz2__edit-logo__body" tabindex="0">
                <div class="cz2__panel-section__header cz2__type__small-header">
                    {{$t('EDIT_CHOOSE_LOCATION')}}
                </div>

                <div class="cz2__edit-locations cz2__sub-menu__options">
                    <button v-for="l in availableLocations" :key="l.location.location" :class="[
                        'cz2__edit-location',
                        'cz2__sub-menu-option',
                        {
                            'cz2__edit-location--selected': l.selected,
                        },
                        {
                            'cz2__sub-menu-option--selected': l.selected,
                        },
                    ]"  :disabled="!l.available"
                        :title="l.name"
                        @click.prevent="selectLocation(l)">
                        <span class="cz2__edit-location__image cz2__sub-menu-option__image" :style="{
                            backgroundImage: `url(${l.image})`
                        }"></span>
                        <span class="cz2__edit-location__name cz2__sub-menu-option__name">
                            {{$t('ACTION_ADD')}} {{l.location.name}}
                        </span>
                    </button>
                </div>

                <template v-if="selectedLocation">
                    <div class="cz2__panel-section">
                        <div class="cz2__panel-section__header cz2__type__small-header">
                            {{$t('EDIT_LOGO_CHOOSE_ICON')}}
                        </div>

                        <div v-if="isLoggedIn === false" class="cz2__panel-section__body cz2__edit-logo__login">
                            {{$t('EDIT_LOGO_LOGIN')}}
                            <button class="cz2__link-button" @click.prevent="login">{{$t('EDIT_LOGO_LOGIN_LOGIN')}}</button>
                            {{$t('EDIT_LOGO_LOGIN_OR')}}
                            <button class="cz2__link-button" @click.prevent="login">{{$t('EDIT_LOGO_LOGIN_SIGNUP')}}</button>
                            {{$t('EDIT_LOGO_LOGIN_NOW')}}
                        </div>

                        <div v-if="approvedArtwork.length > 0" class="cz2__edit-logo__saved">
                            <div class="cz2__panel-section__header cz2__panel-section__header--caps cz2__type__mini-header">
                                {{$t('EDIT_LOGO_SAVED')}}
                            </div>

                            <div class="cz2__text-options cz2__sub-menu__options">
                                <button v-for="art in approvedArtwork" :key="art.id"
                                    :class="[
                                        'cz2__text-option',
                                        'cz2__sub-menu-option',
                                        'cz2__sub-menu-option--no-label',
                                    ]"
                                    :title="art.fileName"
                                    @click.prevent="selectSaved(art)"
                                >
                                    <span class="cz2__text-option__image cz2__sub-menu-option__image cz2__sub-menu-option__image--small" :style="{
                                        backgroundImage: 'url(' + art.url + ')'
                                    }"></span>
                                </button>
                            </div>
                        </div>

                        <div v-if="recentArtwork.length > 0" class="cz2__edit-logo__saved">
                            <div class="cz2__panel-section__header cz2__panel-section__header--caps cz2__type__mini-header">
                                {{$t('EDIT_LOGO_RECENT')}}
                            </div>

                            <div class="cz2__info">
                                {{$t('EDIT_LOGO_MULTIPLE')}}
                            </div>

                            <div class="cz2__text-options cz2__sub-menu__options">
                                <button v-for="art in recentArtwork" :key="art.id"
                                    :class="[
                                        'cz2__text-option',
                                        'cz2__sub-menu-option',
                                        'cz2__sub-menu-option--no-label',
                                    ]"
                                    :title="art.fileName"
                                    @click.prevent="selectRecent(art)"
                                >
                                    <span class="cz2__text-option__image cz2__sub-menu-option__image cz2__sub-menu-option__image--small" :style="{
                                        backgroundImage: 'url(' + art.url + ')'
                                    }"></span>
                                </button>
                            </div>
                        </div>

                        <fieldset :disabled="isLoggedIn !== true" class="cz2__edit-logo__uploader">
                            <div class="cz2__panel-section__header cz2__panel-section__header--flex cz2__panel-section__header--caps cz2__type__mini-header">
                                <span>{{$t('EDIT_LOGO_UPLOAD_FILE')}}</span>

                                <span class="cz2__upload-limit">
                                    {{$t('EDIT_LOGO_UPLOAD_LIMIT')}}
                                </span>
                            </div>

                            <div class="cz2__upload"
                                :class="[{
                                    'cz2__upload--over': draggingOver,
                                }]"
                            >
                                <label class="cz2__upload__body" for="cz2__logo-upload-control">
                                    <span class="cz2__upload__icon"></span>

                                    <template v-if="hasImage">
                                        <span class="cz2__upload__drag">{{imageName}}</span>
                                    </template>

                                    <template v-else>
                                        <span class="cz2__upload__drag">
                                            {{$t('EDIT_LOGO_UPLOAD_DRAG')}}
                                        </span>
                                        <span class="cz2__upload__browse">
                                            {{$t('EDIT_LOGO_UPLOAD_BROWSE')}}
                                        </span>
                                    </template>
                                </label>

                                <input ref="file" type="file" class="cz2__upload__control" id="cz2__logo-upload-control"
                                    @dragenter="dragEnter($event)"
                                    @dragleave="dragLeave($event)"
                                    @drop="drop($event)"
                                    @dragover="dragOver($event)"
                                    @change="picked($event)"
                                />
                            </div>

                            <div class="cz2__upload__files">
                                {{$t('EDIT_LOGO_FILE_TYPES')}}
                            </div>

                            <template v-if="!hasImage">
                                <div class="cz2__warning">
                                    <div class="cz2__warning-icon" aria-hidden="true"></div>

                                    <span class="cz2__warning-text">{{isLogo2High ? $t('EDIT_LOGO_SIZE_WARN_2IN') : $t('EDIT_LOGO_SIZE_WARN')}}</span>
                                </div>

                                <div class="cz2__warning">
                                    <div class="cz2__warning-icon" aria-hidden="true"></div>

                                    <span class="cz2__warning-text">{{$t('EDIT_LOGO_REVIEW')}}</span>
                                </div>
                            </template>

                            <template v-else>
                                <div v-if="digitizationNeeded && !sizeDisabled" class="cz2__edit-logo__consent">
                                    <input
                                        id="cz2__consent-checkbox"
                                        class="cz2__consent-checkbox"
                                        type="checkbox"
                                        :checked="hasConsent"
                                        @change="updateConsent"
                                    />
                                    <label for="cz2__consent-checkbox" class="cz2__consent-label" @click.stop="updateConsent">{{$t('EDIT_LOGO_CONFIRM_RIGHTS')}}
                                        <!-- <span class="cz2__consent-checkbox__input">
                                            <input
                                                type="checkbox"
                                                v-model="uploadConsent"
                                                aria-describedby="cz2__consent__label"
                                            />

                                            <span class="cz2__consent-checkbox__control">
                                                <svg
                                                    xmlns="http://www.w3.org/2000/svg"
                                                    viewBox="0 0 24 24"
                                                    aria-hidden="true"
                                                    focusable="false"
                                                >
                                                    <path
                                                        fill="none"
                                                        stroke="currentColor"
                                                        stroke-width="3"
                                                        d="M1.73 12.91l6.37 6.37L22.79 4.59"
                                                    />
                                                </svg>
                                            </span>
                                        </span> -->

                                        <!-- <span id="cz2__consent__label" class="cz2__consent-checkbox__label">
                                            {{$t('EDIT_LOGO_CONFIRM_RIGHTS')}}
                                        </span> -->
                                    </label>
                                </div>

                                <div v-if="!alreadyUploaded && !sizeDisabled" class="cz2__warning">
                                    <div class="cz2__warning-icon" aria-hidden="true"></div>

                                    <span class="cz2__warning-text">{{$t('EDIT_LOGO_FEE', { price: digitizationPriceFormatted })}}</span>
                                </div>

                                <div v-if="!sizeDisabled" class="cz2__warning">
                                    <div class="cz2__warning-icon" aria-hidden="true"></div>

                                    <span class="cz2__warning-text">{{$t('EDIT_LOGO_REVIEW')}}</span>
                                </div>

                                <div class="cz2__form-field cz2__logo-height">
                                    <label class="cz2__form-label">{{$t('EDIT_LOGO_HEIGHT')}}</label>

                                    <multiselect v-model="selectedLogoSize"
                                        :options="availableLogoSizes"
                                        track-by="code"
                                        label="name"
                                        :searchable="false"
                                        :allow-empty="false"
                                        selectLabel=""
                                        deselectLabel=""
                                        :disabled="sizeDisabled"
                                    ></multiselect>
                                </div>

                                <div v-if="sizeLimited" class="cz2__warning">
                                    <div class="cz2__warning-icon" aria-hidden="true"></div>

                                    <span class="cz2__warning-text">{{sizeLimited}}</span>
                                </div>

                                <template v-if="!sizeDisabled">
                                    <div class="cz2__panel-section__header cz2__type__mini-header cz2__panel-section__header--notes">
                                        <span>{{$t('EDIT_LOGO_NOTES')}}</span>

                                        <span class="cz2__upload-limit">
                                            {{$t('EDIT_LOGO_NOTES_LIMIT', { characters: notesCharactersLeft })}}
                                        </span>
                                    </div>

                                    <!-- eslint-disable-next-line max-len -->
                                    <textarea class="cz2__edit-logo__notes cz2__form-textarea" rows="4" :value="notes" maxlength="140" @input="updateNotes($event)"
                                        :placeholder="$t('EDIT_LOGO_NOTES_PLACEHOLDER')"
                                    >
                                    </textarea>
                                </template>
                            </template>
                        </fieldset>
                    </div>
                </template>

                <template v-if="isComplete">
                    <div class="cz2__edit-logo__flex-space">
                    </div>

                    <div class="cz2__remove-component">
                        <button @click.prevent="removeLogo"><span>{{$t('EDIT_LOGO_REMOVE_ICON')}}</span></button>
                    </div>
                </template>
            </div>

            <div class="cz2__panel__actions">
                <button class="cz2__panel-action" @click.prevent="cancel"><span>{{$t('ACTION_CANCEL')}}</span></button>
                <button class="cz2__panel-action cz2__panel-action--primary"
                    :disabled="!isComplete"
                    @click.prevent="accept">
                    <span>{{$t('ACTION_SAVE')}}</span>
                </button>
            </div>
        </focus-lock>
    </div>
</template>

<style lang="scss">
    @import "../style/variables.scss";

    #cz2.cz2 {
        .cz2__panel__body.cz2__edit-logo__body {
            display: flex;

            flex-direction: column;
            justify-content: flex-start;
            align-items: stretch;

            & > * {
                flex: 0 0 auto;
            }

            & > .cz2__edit-logo__flex-space {
                height: 1px;

                flex: 1 1 auto;
            }
        }

        .cz2__logo-options {
            display: flex;
            flex-wrap: wrap;

            margin-top: 17px;

            margin-left: -4px;
            margin-right: -4px;
        }

        .cz2__logo-option {
            position: relative;

            flex: 0 0 auto;

            width: 66px;
            height: 66px;

            margin: 4px;

            border: none;
            background: #4c4c4c;

            &:disabled {
                opacity: 0.2;
            }

            &:after {
                content: '';

                position: absolute;

                right: -4px;
                top: -4px;

                width: 17px;
                height: 17px;

                background: transparent url(../assets/selected@2x.png) no-repeat center/contain;

                opacity: 0;

                z-index: 2;

                transition: opacity $transition-fast ease-out;
            }

            &.cz2__logo-option--selected {
                &:after {
                    opacity: 1;
                }
            }
        }

        .cz2__logo-option__image {
            position: absolute;

            left: 0;
            top: 0;

            width: 100%;
            height: 100%;

            background-repeat: no-repeat;
            background-size: contain;
        }

        .cz2__upload-limit {
            font-family: $font-main;
            font-weight: 400;
            font-size: 10px;
            line-height: 16px;
        }

        .cz2__upload {
            position: relative;

            width: 100%;
            margin-top: 4px;
            padding: 16px;

            background: $color-accent-light-gray;

            :has(input:focus) {
                box-shadow: 0 0 0 1px $color-primary-light, 0 0 1px 2px $color-accent-warm-gray;
            }

            &.cz2__upload--over {
                outline: 1px solid $color-accent-warm-gray!important;
            }

            .cz2__upload__icon {
                display: block;

                width: 15px;
                height: 15px;

                background: transparent url(../assets/upload@2x.png) no-repeat center/contain;
            }

            .cz2__upload__drag {
                margin-top: 8px;
            }

            .cz2__upload__drag, .cz2__upload__browse {
                display: block;

                color: $color-primary-dark;
                font-family: $font-main;
                font-size: 14px;
                font-weight: 400;
                line-height: 18px;
            }

            .cz2__upload__body {
                width: 100%;
                height: 100%;
                display: flex;
                flex-direction: column;
                justify-content: center;
                align-items: center;

                text-align: center;

                pointer-events: none;
            }

            input {
                position: absolute;

                left: 0;
                top: 0;

                width: 100%;
                height: 100%;

                opacity: 0;

                -webkit-appearance: none;
                appearance: none;

                cursor: pointer;

                z-index: 2;
            }
        }

        .cz2__upload__files {
            margin-top: 8px;

            font-family: $font-main;
            font-weight: 500;
            font-size: 10px;
            line-height: 16px;
            text-transform: uppercase;

            color: $color-primary-dark;

            + .cz2__warning {
                margin-top: 20px;
            }
        }

        .cz2__edit-logo__uploader {
            margin-top: 15px;

            &:disabled {
                opacity: 0.2;
            }
        }

        .cz2__panel-section__header--notes {
            margin-top: 25px;
        }

        .cz2__edit-logo__notes {
            // width: 100%;

            // margin-top: 10px;

            // padding: 15px;

            // border: 1px solid #c1c1c1;

            // color: #656565;
            // font-family: $font-main;
            // font-size: 14px;
            // font-weight: 400;
            // line-height: 20px;

            user-select: auto;

            &:focus::placeholder {
                color: transparent;
            }
        }

        .cz2__edit-logo__consent {
            display: flex;
            align-items: center;
            gap: 8px;
            margin-top: 18px;

            color: $color-primary-dark;

            font-family: $font-main;
            font-weight: 400;
            font-size: 10px;
            line-height: 16px;
        }

        .cz2__edit-logo__saved {
            margin-top: 20px;
            margin-bottom: 20px;
        }

        .cz2__logo-height {
            margin-top: 20px;
        }

        .cz2__consent-checkbox {
            width: 20px;
            height: 20px;
            margin: 0;
        }

        .cz2__consent-label {}

        // custom input styling for checkbox and radio
        // .cz2__consent-checkbox {
        //     display: grid;
        //     grid-template-columns: min-content auto;
        //     gap: 0.5em;

        //     color: $color-primary-dark;

        //     // font-size: 16px;

        //     cursor: pointer;

        //     .cz2__consent-checkbox__input {
        //         margin-top: -2px;
        //         display: grid;
        //         grid-template-areas: 'checkbox';

        //         > * {
        //             grid-area: checkbox;

        //             cursor: pointer;
        //         }

        //         input {
        //             width: 20px;
        //             height: 20px;

        //             opacity: 0;
        //             // pointer-events: none;

        //             &:checked + .cz2__consent-checkbox__control svg {
        //                 transform: scale(1);
        //             }

        //             &:focus + .cz2__consent-checkbox__control {
        //                 box-shadow: 0 0 0 1px $color-primary-light, 0 0 1px 2px currentColor;
        //             }
        //         }

        //         .cz2__consent-checkbox__control {
        //             display: inline-grid;
        //             width: 20px;
        //             height: 20px;
        //             border: 1px solid currentColor;
        //             border-radius: 2px;

        //             svg {
        //                 transition: transform 0.1s ease-in 25ms;
        //                 transform: scale(0);
        //                 transform-origin: bottom left;
        //             }
        //         }
        //     }

        //     .cz2__consent-checkbox__label {
        //         font-family: $font-main;
        //         font-weight: 400;
        //         font-size: 10px;
        //         line-height: 16px;
        //     }
        // }
    }
</style>

<script>
    import Vue from 'vue';
    import axios from 'axios';
    import FocusLock from 'vue-focus-lock';

    import * as constants from '../constants';

    import { getLocationImageUrl, makeComponentThumbnailUrl } from './imagehelpers';

    export default {
        components: {
            FocusLock,
        },
        data() {
            let startingLocation = null;

            const { selectedStep } = this.$store.state;
            const isExisting = selectedStep.startsWith('edit-');

            if (isExisting) {
                const code = selectedStep.replace('edit-logo-', '');

                startingLocation = this.$store.getters.filledLogo.find((l) => l.location === code);
            }

            return {
                selectedLocation: startingLocation,
                startingLocation,

                draggingOver: false,

                isLoggedIn: null,

                artwork: null,

                selectedLogoSize: null,

                uploadConsent: false,
            };
        },
        computed: {
            /**
             * Current view controller.
             */
            viewController() {
                return this.$store.state.customizer.viewController;
            },

            /**
             * Selected step.
             */
            selectedStep() {
                return this.$store.state.selectedStep;
            },

            /**
             * New customization or edit existing?
             */
            isNew() {
                return this.selectedStep.startsWith('new-');
            },

            /**
             * Get a list of locations available for selection.
             */
            availableLocations() {
                const locations = this.$store.getters.allLogo.map((l) => ({
                    location: l,
                    image: getLocationImageUrl(this.viewController, l),
                    available: !l.filled
                        || (this.startingLocation && l.location === this.startingLocation.location)
                        || (this.selectedLocation && l.location === this.selectedLocation.location),
                    selected: this.selectedLocation ? l.location === this.selectedLocation.location : null,
                }));

                return locations;
            },

            /**
             * Calculate the actual placement based on the selected location and expected activity code.
             */
            selectedPlacement() {
                if (this.selectedLocation == null) {
                    return null;
                }

                const placement = this.selectedLocation.placements.find((x) => constants.ACTIVITY_LOGO.includes(x.placement.custom.activity));

                return placement;
            },

            currentSelection() {
                return this.viewController?.selectedAtPlacement(this.selectedPlacement?.placement.code);
            },

            /**
             * Have we uploaded an image?
             */
            hasImage() {
                if (this.selectedPlacement == null) {
                    return false;
                }

                // const currentSelection = this.viewController.selectedAtPlacement(this.selectedPlacement.placement.code);

                if (this.currentSelection == null) {
                    return false;
                }

                if (this.currentSelection.placeholder) {
                    return false;
                }

                if (this.currentSelection.custom['dynamic-image-url'] == null || this.currentSelection.custom['dynamic-image-url'].length === 0) {
                    return false;
                }

                return true;
            },

            /**
             * Get the image name.
             */
            imageName() {
                if (!this.hasImage) {
                    return '';
                }

                // const currentSelection = this.viewController.selectedAtPlacement(this.selectedPlacement.placement.code);

                return this.currentSelection.custom['image-name-original'];
            },

            /**
             * Do we have ownership consent.
             */
            hasConsent() {
                if (!this.hasImage) {
                    return false;
                }

                // const currentSelection = this.viewController.selectedAtPlacement(this.selectedPlacement.placement.code);

                if (this.currentSelection.custom['consent-ownership'] !== 'true') {
                    return false;
                }

                return true;
            },

            /**
             * Is the current selection complete?
             */
            isComplete() {
                if (!this.hasImage) {
                    return false;
                }

                // const currentSelection = this.viewController.selectedAtPlacement(this.selectedPlacement.placement.code);

                if (this.currentSelection.custom['consent-ownership'] !== 'true') {
                    return false;
                }

                return true;
            },

            /**
             * Currently selected component.
             */
            selectedComponent() {
                if (this.selectedPlacement == null) {
                    return null;
                }

                const component = this.viewController.selectedAtPlacement(this.selectedPlacement.placement.code);

                return component;
            },

            /**
             * Check if digitization is needed.
             */
            digitizationNeeded() {
                if (!this.hasImage) {
                    return false;
                }

                // const currentSelection = this.viewController.selectedAtPlacement(this.selectedPlacement.placement.code);

                if (this.currentSelection.custom['digitization-needed'] === 'true') {
                    return true;
                }

                return false;
            },

            /**
             * Checks if the image is already uploaded.
             */
            alreadyUploaded() {
                if (!this.hasImage) {
                    return false;
                }

                // const currentSelection = this.viewController.selectedAtPlacement(this.selectedPlacement.placement.code);
                const imageName = this.currentSelection.custom['image-name-original'];

                if (this.currentSelection.custom['digitization-needed'] === 'false') {
                    return true;
                }

                if (imageName) {
                    let foundSame = false;
                    this.viewController.state.forEach((s) => {
                        if (s.placement.code !== this.selectedPlacement.placement.code) {
                            if (s.component.custom && s.component.custom['image-name-original'] === imageName) {
                                foundSame = true;
                            }
                        }
                    });

                    return foundSame;
                }

                return false;
            },

            /**
             * Gets digitization price.
             */
            digitizationPriceFormatted() {
                return this.$store.state.customizer.digitizationPriceFormatted;
            },

            /**
             * Gets notes.
             */
            notes() {
                // const currentSelection = this.viewController.selectedAtPlacement(this.selectedPlacement.placement.code);

                return this.currentSelection.custom['logo-notes'] || '';
            },

            /**
             * Check if size selection is disabled.
             */
            sizeDisabled() {
                // const currentSelection = this.viewController.selectedAtPlacement(this.selectedPlacement.placement.code);

                return this.currentSelection.custom['image-size-locked'] === 'true';
            },

            /**
             * Get amount of chracters left for the note field.
             */
            notesCharactersLeft() {
                return Math.max(0, constants.LOGO_NOTES_LIMIT - this.notes.length);
            },

            /**
             * Components available for selection.
             */
            availableOptions() {
                const options = [];

                if (this.selectedPlacement == null) {
                    return options;
                }

                // const currentSelection = this.viewController.selectedAtPlacement(this.selectedPlacement.placement.code);

                this.selectedPlacement.placement.components.forEach((c) => {
                    if (c.placeholder) {
                        return;
                    }

                    options.push({
                        component: c,
                        image: makeComponentThumbnailUrl(1, c),
                        selected: c.code === this.currentSelection.code,
                    });
                });

                return options;
            },

            approvedArtwork() {
                const maxSize = this.getAvailableSizes().reduce((max, current) => (max == null ? current.code : Math.max(max, current.code)), 0);

                return (this.artwork || []).filter((x) => x.lotName && x.lotName != null && (+x.artworkHeight) <= maxSize);
            },

            /**
             * Are there taken embroidery placements?
             */
            filledLogo() {
                return this.$store.getters.filledLogo;
            },

            recentArtwork() {
                const maxSize = this.getAvailableSizes().reduce((max, current) => (max == null ? current.code : Math.max(max, current.code)), 0);

                const recents = [];

                (this.artwork || []).forEach((art) => {
                    if (art.isNew && (+art.artworkHeight) <= maxSize) {
                        let { url } = art;

                        url = url.replace('https:', '').replace('http:', '');

                        if (!recents.some((r) => r.url.replace('https:', '').replace('http:', '') === url)) {
                            recents.push(art);
                        }
                    }
                });

                // Add just uploaded images.
                this.filledLogo.forEach((l) => {
                    l.placements.forEach((p) => {
                        if (p.component.custom['digitization-needed'] === 'true' || p.component.custom['digitization-recent'] === 'true') {
                            const id = p.component.custom['dynamic-image-name'];
                            const url = p.component.custom['dynamic-image-url'].replace('https:', '').replace('http:', '');

                            if (!recents.some((r) => r.url.replace('https:', '').replace('http:', '') === url)) {
                                const h = +p.component.custom['image-size-height'];

                                if (h <= maxSize) {
                                    recents.push({
                                        id,
                                        fileName: p.component.custom['image-name-original'],
                                        url,
                                        custom: { ...p.component.custom },
                                    });
                                }
                            }
                        }
                    });
                });

                return recents;
            },

            /**
             * Available logo sizes.
             */
            availableLogoSizes() {
                return this.getAvailableSizes();
            },

            /**
             * Checks if image size was limited because of the placement restrictions.
             */
            sizeLimited() {
                if (this.viewController == null || this.selectedComponent == null) {
                    return null;
                }

                const requested = +(this.selectedComponent.custom['image-size-height-requested'] || '1');
                const width = +(this.selectedComponent.custom['image-size-width'] || '1');
                const height = +(this.selectedComponent.custom['image-size-height'] || '1');

                if (requested === height) {
                    return null;
                }

                return this.$t('EDIT_IMAGE_LIMIT', {
                    x: `${width.toFixed(2)}"`,
                    y: `${height.toFixed(2)}"`,
                });
            },

            isLogo2High() {
                return this.$store.getters.isLogo2High;
            },
        },
        watch: {
            /**
             * When a selected location changes, clear out options in the old place.
             */
            selectedLocation(newValue, oldValue) {
                if (oldValue) {
                    oldValue.placements.forEach((placement) => {
                        this.clearPlacement(placement);
                    });
                }
            },

            /**
             * When selected placement and location changes, zoom to that.
             */
            selectedPlacement() {
                this.$store.dispatch('setFocusedPlacement', {
                    placement: this.selectedPlacement,
                });

                this.updatePlacementOverlay();
            },

            /**
             * Update placement overlay.
             */
            hasImage() {
                this.updatePlacementOverlay();
            },

            selectedLogoSize() {
                this.setImageSize(this.selectedLogoSize);
            },
        },
        mounted() {
            // When the component is loaded, capture the current state of the view controller and save it in case
            // we need to revert to the previous state and toss away all customizations.
            //
            // At any given time there should be only one module that can do the revert.
            this.captureController();

            // Zoom in initial placement.
            if (this.selectedPlacement) {
                this.$store.dispatch('setFocusedPlacement', {
                    placement: this.selectedPlacement,
                });

                this.updatePlacementOverlay();
            }

            this.getLoginStatus();

            this.uploadConsent = this.currentSelection?.custom['consent-ownership'] ?? false;
        },
        methods: {
            /**
             * Captures the current state and active controller so we could cancel changes if necessary.
             */
            captureController() {
                if (this.viewController) {
                    this.revertController = this.viewController.clone();
                }
            },

            /**
             * Accept color choice.
             */
            accept() {
                // Keep the current configured view controller, and go back to the main screen.
                this.$store.dispatch('selectStep', null);
            },

            /**
             * Reject color changes and restore the original selection.
             */
            cancel() {
                // Revert back to the state of the controller as was captured when the component was mounted.
                this.$store.dispatch('showController', this.revertController);

                // Go back to the main screen.
                this.$store.dispatch('selectStep', null);
            },

            /**
             * Selects a location for personalization.
             */
            selectLocation(l) {
                this.selectedLocation = l.location;
            },

            /**
             * Hande drag over events.
             */
            dragEnter() {
                this.draggingOver = true;
            },

            /**
             * Hande drag over events.
             */
            dragOver(e) {
                e.preventDefault();
            },

            /**
             * Hande drag over events.
             */
            dragLeave() {
                this.draggingOver = false;
            },

            /**
             * Uploads a dropped file.
             */
            drop(e) {
                e.preventDefault();

                this.draggingOver = false;

                if (e.dataTransfer && e.dataTransfer.files) {
                    this.handleFile([...e.dataTransfer.files]);
                }
            },

            /**
             * Picks a file.
             */
            picked() {
                if (this.$refs.file.files.length > 0) {
                    this.handleFile([...this.$refs.file.files]);

                    this.$refs.file.value = null;
                }
            },

            /**
             * Upload a file and attach it to the placement.
             */
            handleFile(files) {
                if (files == null || files.length < 0) {
                    return;
                }

                // eslint-disable-next-line
                const file = files[0];

                const formData = new FormData();
                formData.append('file', file);

                axios({
                    method: 'post',
                    url: '//api.images.drivecommerce.com/api/v1/image/vfw/upload/',
                    data: formData,
                    config: { headers: { 'Content-Type': 'multipart/form-data' } },
                }).then((response) => {
                    this.handleImageResponse(response, file.name);
                });
            },

            /**
             * Handles image upload response.
             */
            handleImageResponse(response, name) {
                return new Promise((resolve) => {
                    const image = response.data;

                    image.widthOriginal = image.width;
                    image.heightOriginal = image.height;

                    // Calculate the dynamic image placement based on VF parameters, copy from personalization settings.
                    const selected = this.viewController.selectedAtPlacement(this.selectedPlacement.placement.code);
                    const { placement } = this.selectedPlacement;

                    // Figure out the location size.
                    const { location } = placement.custom;
                    let locationSize = constants.DEFAULT_SIZES.findIndex((x) => x === location);
                    if (locationSize >= 0) {
                        locationSize = constants.DEFAULT_SIZES[locationSize + 1];
                    } else {
                        locationSize = constants.DEFAULT_SIZE;
                    }

                    locationSize = locationSize.split('x').map((v) => +v * constants.STANDARD_PIXELS);

                    // eslint-disable-next-line
                    selected.custom['dynamic-image-area-width'] = locationSize[0];
                    // eslint-disable-next-line
                    selected.custom['dynamic-image-area-height'] = locationSize[1];
                    selected.custom['dynamic-image-area-x'] = locationSize[0] * 0.5;
                    selected.custom['dynamic-image-area-y'] = locationSize[1] * 0.5;
                    selected.custom['dynamic-image-area-center-x'] = (+placement.custom['placement-x'])
                        + (+placement.custom['placement-width'] * 0.5);
                    selected.custom['dynamic-image-area-center-y'] = (+placement.custom['placement-y'])
                        + (+placement.custom['placement-height'] * 0.5);

                    // eslint-disable-next-line
                    console.log(`Uploading image ${image.width} x ${image.height}`);

                    // Configure the image.
                    this.viewController.updateDynamicImage(this.selectedPlacement.placement.code, {
                        title: image.title,
                        name: image.name,
                        url: image.imageUrl,
                        pngUrl: image.pngImageUrl,
                        jpgUrl: image.jpgImageUrl,
                        width: image.width,
                        height: image.height,
                    });

                    Vue.set(selected.custom, 'dynamic-image-density', image.density);
                    Vue.set(selected.custom, 'digitization-needed', 'true');
                    Vue.set(selected.custom, 'digitization-recent', 'true');
                    Vue.set(selected.custom, 'image-name-original', name);
                    Vue.set(selected.custom, 'image-size-locked', 'false');

                    // Adjust the scale again.
                    const density = image.density || constants.STANDARD_DPI;

                    if (density) {
                        const sizes = this.getAvailableSizes();

                        let preferredSize = this.selectedLogoSize;

                        if (preferredSize != null) {
                            if (!sizes.some((s) => s.code === preferredSize.code)) {
                                preferredSize = null;
                            }
                        }

                        if (preferredSize == null) {
                            preferredSize = sizes[sizes.length - 1];
                        }

                        if (preferredSize) {
                            this.setImageSize(preferredSize);
                        }
                    }

                    // Automatically set rotation.
                    selected.custom['dynamic-image-rotate'] = placement.custom['personalization-rotate'];

                    // Remove consent
                    Vue.set(selected.custom, 'consent-ownership', 'false');

                    resolve();
                });
            },

            /**
             * Updates notes.
             */
            updateNotes(e) {
                // const currentSelection = this.viewController.selectedAtPlacement(this.selectedPlacement.placement.code);

                Vue.set(this.currentSelection.custom, 'logo-notes', e.target.value);
            },

            /**
             * Update consent.
             */
            updateConsent() {
                if (!this.hasImage) {
                    return;
                }

                this.uploadConsent = !this.uploadConsent;

                // const currentSelection = this.viewController.selectedAtPlacement(this.selectedPlacement.placement.code);

                Vue.set(this.currentSelection.custom, 'consent-ownership', this.uploadConsent ? 'true' : 'false');
            },

            /**
             * Clears the placement.
             */
            clearPlacement(placement) {
                this.$store.dispatch('clearPlacement', placement);
            },

            /**
             * Selects an option for the selected placement.
             */
            selectOption(option) {
                if (this.selectedPlacement) {
                    this.viewController.updateComponent(this.selectedPlacement.placement.code, option.component.code);
                }
            },

            /**
             * Removes selected art from the current location.
             */
            removeLogo() {
                if (this.selectedPlacement) {
                    // If this image needed digitization, and there are some other placements with the same image
                    // make them require digitization instead.
                    if (this.selectedPlacement.component.custom['digitization-needed'] === 'true') {
                        const imageUrl = this.selectedPlacement.component.custom['dynamic-image-url'];

                        let found = false;

                        this.viewController.state.forEach((s) => {
                            if (!found && s.placement.code !== this.selectedPlacement.placement.code) {
                                if (s.component.custom['dynamic-image-url'] === imageUrl) {
                                    this.viewController.commitCustomAttributes(s.placement.code, {
                                        'digitization-needed': 'true',
                                    });

                                    found = true;
                                }
                            }
                        });
                    }

                    if (this.selectedPlacement.component.custom['digitization-recent'] === 'true') {
                        const imageUrl = this.selectedPlacement.component.custom['dynamic-image-url'];

                        let found = false;

                        this.viewController.state.forEach((s) => {
                            if (!found && s.placement.code !== this.selectedPlacement.placement.code) {
                                if (s.component.custom['dynamic-image-url'] === imageUrl) {
                                    this.viewController.commitCustomAttributes(s.placement.code, {
                                        'digitization-recent': 'true',
                                    });

                                    found = true;
                                }
                            }
                        });
                    }

                    this.clearPlacement(this.selectedPlacement);

                    // Go back to the main screen.
                    this.$store.dispatch('selectStep', null);
                }
            },

            /**
             * Updates placement overlay;
             */
            updatePlacementOverlay() {
                if (this.selectedPlacement && !this.hasImage) {
                    this.$store.dispatch('showPlacementOverlay', {
                        placement: this.selectedPlacement,
                        header: this.$t('EDIT_LOGO_PLACEMENT_OVERLAY_HEADER'),
                        body: this.$t('EDIT_LOGO_PLACEMENT_OVERLAY_BODY'),
                    });
                } else {
                    this.$store.dispatch('showPlacementOverlay', null);
                }
            },

            /**
             * Check logged in status.
             */
            getLoginStatus() {
                this.$store.dispatch('getSavedArtwork').then((response) => {
                    this.isLoggedIn = response.authenticated === true;

                    if (response.authenticated) {
                        this.artwork = response.artwork;

                        if (this.selectedPlacement) {
                            const selected = this.viewController.selectedAtPlacement(this.selectedPlacement.placement.code);

                            const size = selected.custom['image-size-height-requested'];

                            if (size != null && this.availableLogoSizes != null) {
                                const selectedSize = this.availableLogoSizes.find((s) => s.code === size);

                                if (selectedSize) {
                                    this.selectedLogoSize = selectedSize;
                                }
                            }
                        }
                    }
                });
            },

            /**
             * Request to login.
             */
            login() {
                this.$store.dispatch('login');
            },

            /**
             * Select a saved file.
             */
            selectSaved(art) {
                return new Promise((resolve) => {
                    const formData = new FormData();

                    let { url } = art;

                    if (url.startsWith('//')) {
                        url = `https:${url}`;
                    }

                    formData.append('copy-from', url);

                    // Reupload the file since we need additional data like width and stuff.
                    axios({
                        method: 'post',
                        url: '//api.images.drivecommerce.com/api/v1/image/vfw/upload/',
                        data: formData,
                        config: { headers: { 'Content-Type': 'multipart/form-data' } },
                    }).then((response) => {
                        this.handleImageResponse(response, art.fileName).then(() => {
                            const selected = this.viewController.selectedAtPlacement(this.selectedPlacement.placement.code);

                            // Reuse url of the art file to enable linking back to the art file.
                            Vue.set(selected.custom, 'dynamic-image-url', art.url);
                            Vue.set(selected.custom, 'digitization-recent', 'false');
                            Vue.set(selected.custom, 'image-name-original', art.fileName);
                            Vue.set(selected.custom, 'consent-ownership', 'true');
                            Vue.set(selected.custom, 'image-size-locked', 'true');
                            if (art.lotName) {
                                Vue.set(selected.custom, 'digitization-lot', art.lotName);
                                Vue.set(selected.custom, 'digitization-needed', 'false');
                            } else {
                                Vue.set(selected.custom, 'digitization-needed', 'true');
                            }

                            const oldName = art.url.substr(art.url.lastIndexOf('/') + 1);
                            const newName = selected.custom['dynamic-image-name'];

                            Vue.set(selected.custom, 'dynamic-image-name', oldName);
                            Vue.set(selected.custom, 'dynamic-image-pngUrl', selected.custom['dynamic-image-pngUrl'].replace(newName, oldName));
                            Vue.set(selected.custom, 'dynamic-image-jpgUrl', selected.custom['dynamic-image-jpgUrl'].replace(newName, oldName));

                            const where = {
                                placement: this.selectedPlacement.placement,
                                component: selected,
                            };

                            this.guessSize(where, art);

                            resolve(where);
                        });
                    });
                });
            },

            /**
             * Select a recently uploaded file.
             */
            selectRecent(art) {
                if (art.custom == null) {
                    this.selectSaved(art);
                    return;
                }

                const selected = this.viewController.selectedAtPlacement(this.selectedPlacement.placement.code);

                const incomingUrl = art.custom['dynamic-image-url'];
                const currentUrl = selected.custom['dynamic-image-url'];

                if (incomingUrl !== currentUrl) {
                    // Mock up image response.
                    const response = {
                        data: {
                            title: art.custom['dynamic-image-title'],
                            name: art.custom['dynamic-image-name'],
                            url: art.custom['dynamic-image-url'],
                            pngUrl: art.custom['dynamic-image-pngUrl'],
                            jpgUrl: art.custom['dynamic-image-jpgUrl'],
                            width: art.custom['dynamic-image-width'],
                            height: art.custom['dynamic-image-height'],
                        },
                    };

                    this.handleImageResponse(response, art.fileName).then(() => {
                        // Reuse url of the art file to enable linking back to the art file.
                        Vue.set(selected.custom, 'image-size-locked', 'true');
                        Vue.set(selected.custom, 'dynamic-image-url', art.url);
                        Vue.set(selected.custom, 'digitization-needed', 'true');
                        Vue.set(selected.custom, 'digitization-recent', 'true');
                        Vue.set(selected.custom, 'image-name-original', art.fileName);
                        Vue.set(selected.custom, 'consent-ownership', 'true');

                        if (art.custom['image-size-height-requested']) {
                            let otherSize = art.custom['image-size-height-requested'];

                            otherSize = this.availableLogoSizes.find((s) => s.code === otherSize);

                            if (otherSize) {
                                this.setImageSize(otherSize);
                            }
                        }
                    });
                }
            },

            /**
             * Guesses a selected logo size from artwork.
             */
            guessSize(where, art) {
                // eslint-disable-next-line
                console.log(`Saved art size ${art.artworkHeight}`);

                const h = +art.artworkHeight;

                let size = [...this.getAvailableSizes()].find((s) => s.code >= h);

                if (size == null) {
                    // eslint-disable-next-line
                    size = this.getAvailableSizes()[0];
                }

                // eslint-disable-next-line
                console.log(`Guessing it was ${size.code}`);

                this.selectedLogoSize = size;

                this.viewController.commitCustomAttributes(where.placement.code, {
                    'image-size-height-requested': size.code,
                    'image-size-width': art.artworkWidth,
                    'image-size-height': art.artworkHeight,
                });
            },

            /**
             * Get available logo sizes.
             */
            getAvailableSizes() {
                const sizes = [];

                if (this.selectedPlacement) {
                    // Figure out the location size.
                    const { location } = this.selectedPlacement.placement.custom;
                    let locationSize = constants.DEFAULT_SIZES.findIndex((x) => x === location);
                    if (locationSize >= 0) {
                        locationSize = constants.DEFAULT_SIZES[locationSize + 1];
                    } else {
                        locationSize = constants.DEFAULT_SIZE;
                    }

                    locationSize = locationSize.split('x').map((v) => +v);

                    const heightLimit = this.isLogo2High ? 2 : 100;

                    for (let height = 1; ; height += 1) {
                        if (height > heightLimit) {
                            break;
                        }

                        if (locationSize[1] >= height) {
                            sizes.push({
                                code: height,
                                name: `${height}"`,
                            });
                        } else {
                            break;
                        }
                    }
                }

                return sizes;
            },

            /**
             * Sets image physical size.
             */
            setImageSize(size, options) {
                let { placement } = this.selectedPlacement;

                if (options && options.placement) {
                    // eslint-disable-next-line
                    placement = options.placement;
                }

                const selected = this.viewController.selectedAtPlacement(placement.code);

                const w = +selected.custom['dynamic-image-width'];
                const h = +selected.custom['dynamic-image-height'];

                const areaWidth = +selected.custom['dynamic-image-area-width'];
                const areaHeight = +selected.custom['dynamic-image-area-height'];

                let targetWidth = size.code * constants.STANDARD_PIXELS;
                let targetHeight = size.code * constants.STANDARD_PIXELS;

                targetWidth = (w * targetHeight) / h;

                if (targetWidth > areaWidth) {
                    const f = areaWidth / targetWidth;

                    targetWidth *= f;
                    targetHeight *= f;
                }

                if (targetHeight > areaHeight) {
                    const f = areaHeight / targetHeight;

                    targetWidth *= f;
                    targetHeight *= f;
                }

                if (this.isLogo2High) {
                    if (targetWidth > 3 * constants.STANDARD_PIXELS) {
                        const f = (3 * constants.STANDARD_PIXELS) / targetWidth;

                        targetWidth *= f;
                        targetHeight *= f;
                    }
                }

                const scale = targetWidth / w;

                // eslint-disable-next-line
                console.log(`Selected size ${size.name}, fitting as ${targetWidth.toFixed(2)} x ${targetHeight.toFixed(2)}, original image ${w} x ${h} with scale ${scale}`);

                // Adjust placement location according to placement alignment.
                const placementY = (+placement.custom['placement-y']) + (+placement.custom['placement-height'] * 0.5);
                const imageHeight = h * scale * (+placement.custom['placement-scale']);
                const placementHeight = placement.custom['personalization-height'];

                let dy = 0;

                switch (placement.custom['placement-vertical-alignment']) {
                case 'top': {
                    // eslint-disable-next-line
                    console.log(`Align to top, total ${placementHeight}, fit ${imageHeight}`);

                    dy = -(placementHeight - imageHeight) * 0.5;
                    break;
                }
                case 'bottom': {
                    // eslint-disable-next-line
                    console.log(`Align to bottom, total ${placementHeight}, fit ${imageHeight}`);

                    dy = (placementHeight - imageHeight) * 0.5;
                    break;
                }
                default:
                    break;
                }

                // eslint-disable-next-line
                console.log(`Physical size, requested ${size.code}, final ${(targetWidth / constants.STANDARD_PIXELS).toFixed(2)} x ${(targetHeight / constants.STANDARD_PIXELS).toFixed(2)}`);

                this.viewController.commitCustomAttributes(placement.code, {
                    'dynamic-image-scale': scale,
                    'dynamic-image-area-center-y': (+placementY + dy).toString(),
                    'image-size-height-requested': size.code,
                    'image-size-width': (targetWidth / constants.STANDARD_PIXELS).toFixed(2),
                    'image-size-height': (targetHeight / constants.STANDARD_PIXELS).toFixed(2),
                });

                if (options == null) {
                    this.selectedLogoSize = size;
                }

                // Scan other placements.
                const thisImage = selected.custom['dynamic-image-url'];

                if (options == null) {
                    this.viewController.state.forEach((s) => {
                        if (s.placement.code !== placement.code) {
                            if (s.component.custom && s.component.custom['dynamic-image-url'] === thisImage) {
                                this.setImageSize(size, {
                                    placement: s.placement,
                                });
                            }
                        }
                    });
                }
            },
        },
    };
</script>

var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"cz2__panel cz2__panel--secondary cz2__edit-art"},[_c('focus-lock',[_c('div',{staticClass:"cz2__panel__body cz2__edit-art__body",attrs:{"tabindex":"0"}},[_c('div',{staticClass:"cz2__panel-section__header cz2__type__small-header"},[_vm._v(" "+_vm._s(_vm.$t('EDIT_CHOOSE_LOCATION'))+" ")]),_c('div',{staticClass:"cz2__edit-locations cz2__sub-menu__options"},_vm._l((_vm.availableLocations),function(l){return _c('button',{key:l.location.location,class:[
                    'cz2__edit-location',
                    'cz2__sub-menu-option',
                    {
                        'cz2__edit-location--selected': l.selected,
                    },
                    {
                        'cz2__sub-menu-option--selected': l.selected,
                    },
                ],attrs:{"disabled":!l.available,"title":l.name},on:{"click":function($event){$event.preventDefault();return _vm.selectLocation(l)}}},[_c('span',{staticClass:"cz2__edit-location__image cz2__sub-menu-option__image",style:({
                        backgroundImage: `url(${l.image})`
                    })}),_c('span',{staticClass:"cz2__edit-location__name cz2__sub-menu-option__name"},[_vm._v(" "+_vm._s(_vm.$t('ACTION_ADD'))+" "+_vm._s(l.location.name)+" ")])])}),0),(_vm.selectedLocation)?[_c('div',{staticClass:"cz2__panel-section"},[_c('div',{staticClass:"cz2__panel-section__header cz2__type__small-header"},[_vm._v(" "+_vm._s(_vm.$t('EDIT_ART_CHOOSE_ICON'))+" "),(_vm.selectedComponent && !_vm.selectedComponent.placeholder)?_c('span',{staticClass:"cz2__panel-section__selected cz2__type__small-print"},[_vm._v(" "+_vm._s(_vm.selectedComponent.description[0].description)+" ")]):_vm._e()]),_c('div',{staticClass:"cz2__art-options cz2__sub-menu__options"},_vm._l((_vm.availableOptions),function(option){return _c('button',{key:option.component.code,class:[
                                'cz2__art-option',
                                'cz2__sub-menu-option',
                                {
                                    'cz2__art-option--selected': option.selected,
                                },
                                {
                                    'cz2__sub-menu-option--selected': option.selected,
                                },
                            ],attrs:{"title":option.component.description[0].description},on:{"click":function($event){$event.preventDefault();return _vm.selectOption(option)}}},[_c('span',{staticClass:"cz2__art-option__image cz2__sub-menu-option__image",style:({
                                backgroundImage: `url(${option.image})`
                            })}),_c('span',{staticClass:"cz2__art-option__name cz2__sub-menu-option__name"},[_vm._v(" "+_vm._s(option.component.description[0].description)+" ")])])}),0)])]:_vm._e(),(_vm.isComplete)?[_c('div',{staticClass:"cz2__edit-art__flex-space"}),_c('div',{staticClass:"cz2__remove-component"},[_c('button',{on:{"click":function($event){$event.preventDefault();return _vm.removeArt.apply(null, arguments)}}},[_c('span',[_vm._v(_vm._s(_vm.$t('EDIT_ART_REMOVE_ICON')))])])])]:_vm._e()],2),_c('div',{staticClass:"cz2__panel__actions"},[_c('button',{staticClass:"cz2__panel-action",on:{"click":function($event){$event.preventDefault();return _vm.cancel.apply(null, arguments)}}},[_c('span',[_vm._v(_vm._s(_vm.$t('ACTION_CANCEL')))])]),_c('button',{staticClass:"cz2__panel-action cz2__panel-action--primary",attrs:{"disabled":!_vm.isComplete},on:{"click":function($event){$event.preventDefault();return _vm.accept.apply(null, arguments)}}},[_c('span',[_vm._v(_vm._s(_vm.$t('ACTION_SAVE')))])])])])],1)
}
var staticRenderFns = []

export { render, staticRenderFns }
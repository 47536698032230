<!--

Displays an image of a currently configured product.

-->

<template>
    <div ref="renderer" class="cz2__renderer">
        <div class="cz2__renderer__body" :key="'controller'">
            <div ref="container" class="swiper-container">
                <div class="swiper-wrapper">
                    <div v-for="view in currentVisibleViews" :class="['swiper-slide', 'cz2__swiper-slide--' + view.view.code]" :key="view.view.code">
                        <renderer-image
                            :view="view.view.code"
                            :controller="view.controller"
                            @hide="hideView(view)"
                        ></renderer-image>
                    </div>
                </div>

                <transition name="cz2__fade">
                    <div v-show="!rendererLockSwipe" ref="pagination" :class="[
                        'swiper-pagination',
                    ]"></div>
                </transition>

                <button ref="buttonPrevious" class="cz2_swiper-button cz2_swiper-button-prev">
                    <span class="cz2_swiper-button-icon"></span>
                </button>
                <button ref="buttonNext" class="cz2_swiper-button cz2_swiper-button-next">
                    <span class="cz2_swiper-button-icon"></span>
                </button>
            </div>

            <reset
                :hasAnyActivities="hasAnyActivities"
                @reset="showResetConfirm"
            ></reset>
        </div>
    </div>
</template>

<style lang="scss">
    @import "../style/variables.scss";

    #cz2.cz2 {
        .cz2__renderer {
            position: relative;

            min-height: 340px;
            height: auto;
            aspect-ratio: 1 / 1;

            background-color: $color-background-gray;

            @include respond-above(tablet) {
                height: 390px;
            }

            @include respond-above(sm) {
                position: absolute;
                left: 0;
                top: 0;

                width: calc(100% - 390px);
                height: 100%;

                aspect-ratio: unset;
            }
        }

        .cz2__renderer__body {
            position: relative;

            height: 100%;

            overflow: hidden;

            @include respond-above(sm) {
                position: absolute;

                left: 0;
                top: 0;

                width: 100%;
                padding-top: 0;
            }

            .swiper-container {
                position: relative;
                left: 0;
                top: 0;
                z-index: 1;

                width: 100%;
                height: 100%;
                padding: 0;
                margin: 0;

                overflow: hidden;
            }

            .swiper-wrapper {
                position: absolute;

                width: 100%;
                height: 100%;

                display: flex;

                box-sizing: border-box;

                z-index: 1;
            }

            .swiper-slide {
                position: relative;

                width: 100%;
                height: 100%;

                flex: 0 0 auto;
            }

            .swiper-pagination {
                position: absolute;
                bottom: 0;
                z-index: 1;

                width: 100%;
                height: 21px;
                display: flex;
                justify-content: center;
                align-items: flex-end;
                gap: 8px;

                background: $color-primary-light;

                @include respond-above(sm) {
                    bottom: unset;
                    left: 10px;
                    top: 125px;
                    z-index: 1;

                    width: 72px;
                    height: auto;
                    flex-direction: column;
                    justify-content: flex-start;
                    align-items: center;
                    gap: unset;

                    background: transparent;
                }
            }

            .swiper-pagination-bullet {
                position: relative;

                width: 5px;
                height: 5px;
                display: inline-block;
                border: none;
                border-radius: 100%;

                cursor: pointer;

                background: $color-accent-dark-red;

                opacity: 0.3; // Prevents client style override

                &.swiper-pagination-bullet-active {
                    opacity: 1;
                }

                @include respond-above(sm) {
                    width: 72px;
                    height: 72px;
                    margin: 0;
                    padding: 0;
                    flex: 0 0 auto;

                    background: transparent;
                    opacity: 1;

                    & + .swiper-pagination-bullet {
                        margin-top: 40px;
                    }

                    .cz2__bullet__image {
                        position: absolute;

                        left: 0;
                        top: 0;

                        width: 72px;
                        height: 72px;
                        border-radius: 8px;

                        background-color: $color-primary-light;
                        background-size: contain;

                        backface-visibility: hidden;
                        transition: background-image $transition-fast ease-out;
                    }

                    .cz2__bullet__text {
                        position: absolute;
                        left: 0px;
                        top: 80px;

                        width: 100%;
                        display: inline;

                        font-family: $font-main;
                        font-weight: 400;
                        font-size: 12px;
                        line-height: 18px;
                        text-align: center;
                    }

                    &.swiper-pagination-bullet-active {
                        &:after {
                            content: '';

                            position: absolute;
                            left: 0;
                            top: 0;

                            width: 72px;
                            height: 72px;
                            display: block;
                            margin: 0;
                            border: 2px solid $color-brand-red;
                            border-radius: 8px;

                            transform: none;
                            pointer-events: none;
                        }
                    }
                }
            }

            .cz2__bullet__text {
                display: none;
            }

            .cz2_swiper-button-icon {
                display: block;

                width: 8px;
                height: 16px;

                background: transparent url(../assets/left-arrow@2x.png) no-repeat center/contain;

                @include respond-above(tablet) {
                    width: 14px;
                    height: 22px;
                }
            }

            .cz2_swiper-button {
                position: absolute;
                top: 50%;
                z-index: 2;

                width: 50px;
                padding: 12px;
                display: none;

                background: transparent;

                transform: translate(0, -50%);
                transition: transform $transition-fast ease-out;

                &.swiper-button-disabled {
                    display: none;

                    opacity: .4;
                    cursor: not-allowed;
                }

                @include respond-above(tablet) {
                    padding: 9px;
                }

                @include respond-above(sm) {
                    display: none;
                }
            }

            .cz2_swiper-button-prev {
                left: 9px;

                @include respond-above(tablet) {
                    left: 28px;
                }
            }

            .cz2_swiper-button-next {
                right: 9px;
                transform: translate(0, -50%) rotate(180deg);

                @include respond-above(tablet) {
                    right: 28px;
                }
            }
        }

        .cz2__renderer__content {
            position: absolute;

            left: 40px;
            top: 40px;

            width: calc(100% - 80px);
            height: calc(100% - 80px);

            overflow: hidden;

            @include respond-below(sm) {
                left: 0;
                top: 0;

                width: 100%;
                height: 100%;

                top: 0%;
                height: 100%;
            }
        }

        .cz2__image {
            position: absolute;

            left: 0%;
            top: 0%;

            width: 100%;
            height: 100%;

            transform: translate(0, 0, 0);
            transition: transform 0.2s ease-out;

            .cz__render {
                position: absolute;

                left: 0;
                top: 0;

                width: 100%;
                height: 100%;
            }

            .cz__render__layer {
                position: absolute;

                left: 0;
                top: 0;

                width: 100%;
                height: 100%;

                canvas {
                    position: absolute;

                    left: 0;
                    top: 0;
                }
            }
        }

        .cz__surface {
            transition: opacity $transition-fast ease-out;
        }

        .cz__render__layer {
            pointer-events: none;

            canvas {
                pointer-events: none;
            }
        }
    }
</style>

<script>
    import Vue from 'vue';

    import Swiper from 'swiper';
    import RendererImage from './RendererImage.vue';
    import Reset from './Reset.vue';

    import * as constants from '../constants';
    import { makeThumbnailUrl } from './imagehelpers';

    import unique from '../utilities/unique';

    export default {
        props: {
        },
        components: {
            RendererImage,
            Reset,
        },
        data() {
            return {
                listenId: `id-${unique()}`,

                currentViews: [],
                currentViewsKey: null,
            };
        },
        computed: {
            /**
             * Current controller.
             */
            viewController() {
                return this.$store.state.customizer.viewController;
            },

            /**
             * Currently visible views.
             */
            currentVisibleViews() {
                const filtered = (this.currentViews || []).filter((v) => !v.view.errored);

                return filtered;
            },

            /**
             * Disable swper swiping under certain circumstances.
             */
            rendererLockSwipe() {
                return false;
            },

            /**
             * Currently selected color name.
             */
            selectedColor() {
                return this.$store.getters.selectedColor;
            },

            /**
             * Any customized activities configured
             */
            hasAnyActivities() {
                return this.$store.getters.hasAnyActivities;
            },

            /**
             * Currently focused placement.
             */
            focusedPlacement() {
                return this.$store.state.customizer.focusedPlacement;
            },
        },
        watch: {
            /**
             * Load image view for the new controller.
             */
            viewController(newController, oldController) {
                this.disconnect(oldController);

                this.load(newController);
            },

            /**
             * Enables or disables manual interactions with the swiper.
            */
            rendererLockSwipe() {
                if (this.swiper) {
                    this.swiper.allowTouchMove = !this.rendererLockSwipe;
                }
            },

            /**
             * Update pagination on selected color change.
             */
            selectedColor() {
                this.updateBullets();
            },

            /**
             * When focused placement changes...
             */
            focusedPlacement() {
                this.updateFocusedPlacement();
            },
        },
        mounted() {
            // Resize the view when window size changes.
            this.resize();
            window.addEventListener('resize', this.resize);

            // Paint the main controller.
            if (this.viewController) {
                this.mountController(this.viewController);
            }
        },
        beforeDestroy() {
            window.removeEventListener('resize', this.resize);

            // Destroy swiper after any external transitions have ran.
            setTimeout(() => {
                if (this.swiper) {
                    this.swiper.destroy();
                    this.swiper = null;
                }
            }, 500);
        },
        methods: {
            /**
             * Resizes image view.
             */
            resize() {
                // Might not have rerendered yet.
                if (this.$refs.renderer == null) {
                    this.$nextTick(() => {
                        this.resize();
                    });

                    return;
                }

                this.$store.dispatch('setRendererHeight', this.$refs.renderer.offsetHeight);
            },

            /**
             * Disconnects image view from the controller and stops listening to events.
             */
            disconnect() {
            },

            /**
             * Loads image view for the given controller.
             */
            load(controller) {
                if (controller) {
                    this.mountController(controller);
                }
            },

            /**
             * Create image view.
             */
            mountController(controller) {
                // Find views to display.
                let { views } = controller.blueprint;

                views = views.map((view) => ({ view, controller }));

                const viewsKey = views.map((v) => v.view.code).join(':');

                // If current views are compatible, simply adopt the new controller.
                if (this.currentViewsKey === viewsKey) {
                    this.currentViews.forEach((view) => {
                        view.controller = controller;
                    });

                    this.resize();
                } else {
                    this.currentViews = views;
                    this.currentViewsKey = viewsKey;

                    this.$nextTick(() => {
                        this.createSwiper();
                    });

                    this.resize();
                }
            },

            /**
             * Generate bullet html.
             */
            getBullet(index, className) {
                const color = this.viewController.selectedAtPlacement(constants.PLACEMENT_PRODUCT);

                const view = this.currentViews[index];

                if (color && view) {
                    // eslint-disable-next-line
                    const image = makeThumbnailUrl(view.view.frameLow, color);

                    // eslint-disable-next-line
                    return `<button class="${className}"><span class="cz2__bullet__image" style="background-image: url(${image})"></span><span class="cz2__bullet__text">${view.view.name}</span></button>`;
                }

                return `<button class="${className}"></button>`;
            },

            /**
             * Updates all bullets.
             */
            updateBullets() {
                const color = this.viewController.selectedAtPlacement(constants.PLACEMENT_PRODUCT);

                Array.from(this.$el.querySelectorAll('.cz2__bullet__image')).forEach((bullet, index) => {
                    const view = this.currentViews[index];

                    // eslint-disable-next-line
                    const image = makeThumbnailUrl(view.view.frameLow, color);

                    const loader = new Image();

                    loader.onload = () => {
                        bullet.style.backgroundImage = `url(${image})`;
                    };

                    loader.src = image;
                });
            },

            /**
             * Creates swipable surface.
             */
            createSwiper() {
                if (this.swiper) {
                    this.swiper.destroy();
                    this.swiper = null;
                }

                if (this.currentViews.length > 1) {
                    const swiperOptions = {
                        slidesPerView: 1,
                        on: {
                        },
                        pagination: {
                            el: this.$refs.pagination,
                            clickable: true,
                            renderBullet: (index, className) => this.getBullet(index, className),
                        },
                        navigation: {
                            nextEl: this.$refs.buttonNext,
                            prevEl: this.$refs.buttonPrevious,
                        },
                        fadeEffect: {
                            crossFade: true,
                        },
                    };

                    this.swiper = new Swiper(this.$refs.container, swiperOptions);
                }
            },

            /**
             * Automatically swipes to the selected view.
             */
            swipeTo(code) {
                if (this.swiper && this.currentViews) {
                    const viewIndex = this.currentViews.findIndex((v) => v.view.code === code);
                    if (viewIndex >= 0) {
                        this.swiper.slideTo(viewIndex);
                    }
                }
            },

            /**
             * Reset all customizations
             */
            showResetConfirm() {
                this.$store.dispatch('showResetConfirmation', true);
            },

            /**
             * Update the focused placement.
             */
            updateFocusedPlacement() {
                if (this.focusedPlacement) {
                    const { placement } = this.focusedPlacement;

                    const { location } = placement.custom;

                    const frame = constants.LOCATIONS_BACK.includes(location) ? constants.FRAME_BACK : constants.FRAME_FRONT;

                    const view = this.currentViews.findIndex((v) => v.view.frameLow === frame);

                    if (view >= 0 && this.swiper) {
                        this.swiper.slideTo(view);
                    }
                }
            },

            /**
             * Hides a view.
             */
            hideView(view) {
                Vue.set(view.view, 'errored', true);

                view.view.continueOnError = true;

                // eslint-disable-next-line
                console.log('Hide view', view.view.code);

                this.$nextTick(() => {
                    this.createSwiper();
                });
            },
        },
    };
</script>

const isFilled = (component) => {
    if (component.custom['widget-type'] === 'personalization') {
        return (component.custom['personalization-text'] || '').length > 0;
    }

    if (component.custom['widget-type'] === 'dynamic-image') {
        return (component.custom['dynamic-image-url'] || '').length > 0;
    }

    return !component.placeholder;
};

export default isFilled;

let previous = 0;

export default () => {
    let date = Date.now();

    if (date <= previous) {
        previous += 1;
        date = previous;
    } else {
        previous = date;
    }

    return date;
};

<template>
    <div class="cz2__modal-popup cz2__modal-popup--reset">
        <div class="cz2__modal-popup__body">
            <focus-lock>
                <div class="cz2__modal-popup__content" tabindex="0">
                    <button class="cz2__modal-popup__close" :aria-label="$t('POPUP_CLOSE')" @click.prevent="close">
                    </button>

                    <div class="cz2__modal-popup__header">
                        {{$t('POPUP_SWITCH_PROGRAM_HEADER')}}
                    </div>

                    <div class="cz2__modal-popup__body-copy">
                        {{$t('POPUP_SWITCH_PROGRAM_BODY')}}
                    </div>

                    <div class="cz2__modal-popup__actions">
                        <button class="cz2__modal-popup__button cz2__modal-popup__button--ok" @click.prevent="confirm">
                            {{$t('POPUP_SWITCH_PROGRAM_OK')}}
                        </button>

                        <button class="cz2__modal-popup__button cz2__modal-popup__button--cancel" @click.prevent="cancel">
                            {{$t('POPUP_SWITCH_PROGRAM_CANCEL')}}
                        </button>
                    </div>
                </div>
            </focus-lock>
        </div>
    </div>
</template>

<script>
    import FocusLock from 'vue-focus-lock';

    export default {
        components: {
            FocusLock,
        },

        methods: {
            close() {
                this.$store.dispatch('showSwitchProgramConfirmation', false);
            },

            cancel() {
                this.$store.dispatch('showSwitchProgramConfirmation', false);
            },

            confirm() {
                this.$store.dispatch('switchToProgram');
                this.$store.dispatch('showSwitchProgramConfirmation', false);
            },
        },
    };
</script>

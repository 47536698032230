import Vue from 'vue';

/**
 * Checks if component is filled.
 */
function isFilled(component) {
    if (component.custom['widget-type'] === 'personalization') {
        return (component.custom['personalization-text'] || '').length > 0;
    }

    if (component.custom['widget-type'] === 'dynamic-image') {
        return (component.custom['dynamic-image-url'] || '').length > 0;
    }

    return !component.placeholder;
}

/**
 * Extacts product id from a component.
 *
 * If product ID contains multiple dashes, it means there could be multiple
 * components being mapped to the same SFCC backing product.
 * Extract real product ID.
 */
function getProductId(rootState, controller, component) {
    const isProduct = component.description.find((d) => d.definition === 'Product');

    if (isProduct) {
        if (rootState.customizer.selectedSize) {
            return rootState.customizer.selectedSize.variantId;
        }
    }

    // Get activity code.
    const parts = component.code.split('-');
    if (parts.length > 2) {
        const activity = parts[1];

        const activityProduct = controller.blueprint.custom[`activity-product-${activity}`];
        if (activityProduct) {
            return activityProduct;
        }
    }

    return component.code;
}

// Price cache.
const pricesCache = {};

export default {
    state() {
        return {
            // Widget configuration.
            options: {},

            // Contains loaded product data: inventory levels, pricing, etc.
            products: null,
        };
    },

    mutations: {
        /**
         * Saves options to state.
         */
        SET_OPTIONS(state, options) {
            state.options = options;
        },

        /**
         * Saves product data.
         */
        SET_PRODUCTS(state, products) {
            state.products = products;
        },
    },

    actions: {
        /**
         * Saves options.
         */
        setEcommerceOptions({ commit }, options) {
            commit('SET_OPTIONS', options);
        },

        /**
         * Loads prices for components mentioned in the blueprint.
         */
        getPrices({
            commit,
            state,
            dispatch,
            rootState,
        }, { controller }) {
            return new Promise((resolve) => {
                const products = [];

                // Get a list of all components from the blueprint.
                (controller.blueprint.groups || []).forEach((group) => {
                    (group.placements || []).forEach((placement) => {
                        (placement.components || []).forEach((c) => {
                            // Placeholders don't have pricing.
                            if (c.placeholder) {
                                return;
                            }

                            if (group.code === 'Product') {
                                let anyVariant = rootState.customizer.variants.variants.find((v) => v.lot === c.code && v.available > 0);

                                if (anyVariant == null) {
                                    anyVariant = rootState.customizer.variants.variants.find((v) => v.lot === c.code);
                                }

                                products.push({
                                    fromVariant: anyVariant,
                                    productId: c.code,
                                    online: true,
                                    price: anyVariant ? anyVariant.price.value : 10,
                                    // eslint-disable-next-line
                                    ats: anyVariant ? (anyVariant.available ? 10 : 0) : 0,
                                });
                            } else {
                                // Get activity code.
                                const parts = c.code.split('-');
                                if (parts.length > 2) {
                                    const activity = parts[1];

                                    const activityProduct = controller.blueprint.custom[`activity-product-${activity}`];

                                    if (activityProduct) {
                                        const exists = products.find((p) => p.productId === activityProduct);

                                        if (!exists) {
                                            products.push({
                                                productId: activityProduct,
                                                online: state.options.alwaysAvailable,
                                                price: state.options.alwaysAvailable ? 10 : null,
                                                ats: state.options.alwaysAvailable ? 10 : null,
                                                components: [activityProduct],
                                            });
                                        }
                                    }
                                }
                            }
                        });
                    });
                });

                products.push({
                    productId: controller.blueprint.custom['digitization-product'],
                    online: true,
                    price: 50,
                    ats: 100,
                    components: [controller.blueprint.custom['digitization-product']],
                });

                // Add variants.
                if (rootState.customizer.variants) {
                    rootState.customizer.variants.variants.forEach((variant) => {
                        products.push({
                            productId: variant.variantId,
                            online: state.options.alwaysAvailable,
                            price: variant.price.value,
                            ats: variant.price.value,
                        });
                    });
                }

                if (state.options.alwaysAvailable) {
                    commit('SET_PRODUCTS', products);

                    resolve();
                } else if (state.options.getProductData) {
                    state.options.getProductData(products.map((p) => p.productId)).then((data) => {
                        data.forEach((p) => {
                            const exists = products.find((x) => x.productId === p.productId);

                            Object.assign(exists, p);

                            if (p.productId === controller.blueprint.custom['digitization-product']) {
                                dispatch('formatPrice', p.price).then((priceFormatted) => {
                                    dispatch('setDigitizationPrice', {
                                        price: p.price,
                                        priceFormatted,
                                    });
                                });
                            }
                        });

                        products.forEach((p) => {
                            if (p.fromVariant) {
                                const matching = products.find((another) => another.productId === p.fromVariant.variantId);

                                if (matching) {
                                    p.price = matching.price;
                                    p.standardPrice = matching.standardPrice;
                                    p.pricing = matching.pricing;
                                }
                            }
                        });

                        commit('SET_PRODUCTS', products);

                        resolve();
                    });
                }
            });
        },

        /**
         * Gets a product/component price.
         */
        getPrice({ state }, productId) {
            const product = state.products.find((p) => p.productId === productId);
            if (product && product.online) {
                return product.price;
            }

            return null;
        },

        /**
         * Attaches necessary state related methods to the controller and triggers the loading status.
         */
        loadController({ rootState, state, dispatch }, controller) {
            controller.on('setReactive', (v) => {
                Vue.set(v.target, v.key, v.value);
                return true;
            });

            controller.on('initializeAvailability', () => dispatch('getPrices', {
                controller,
            }).then((data) => data));

            controller.on('isAvailable', (component) => {
                const productId = getProductId(rootState, controller, component);

                const product = state.products.find((p) => p.productId === productId);
                if (product) {
                    return product.online && product.ats > 0;
                }

                return false;
            });

            controller.on('getPrice', (component) => {
                if (isFilled(component)) {
                    // Don't double price EM TEXT placements
                    if (component.code.includes('-EM-T')) {
                        return null;
                    }

                    const productId = getProductId(rootState, controller, component);

                    const product = state.products.find((p) => p.productId === productId);
                    if (product && product.online) {
                        return product.price;
                    }
                }

                return null;
            });

            controller.load();
        },

        /**
         * Calculates full state price.
         */
        getStatePrice(_, { controller, options }) {
            const finalOptions = {
                ignoreOptional: true,

                fixedPlacements: ['Product'],
                ...options,
            };

            const price = controller.statePrice(finalOptions);

            return price;
        },

        /**
         * Formats price on the server using current locale settings.
         */
        formatPrice({ state }, price) {
            if (pricesCache[price]) {
                return Promise.resolve(pricesCache[price]);
            }

            return state.options.formatPrice(price).then((v) => {
                pricesCache[price] = v;

                return v;
            });
        },
    },
};

<template>
    <div class="cz2__modal-popup cz2__modal-popup--done">
        <div class="cz2__modal-popup__body">
            <focus-lock>
                <div class="cz2__modal-popup__content" tabindex="0">
                    <button class="cz2__modal-popup__close" :aria-label="$t('POPUP_CLOSE')" @click.prevent="close">
                    </button>

                    <div class="cz2__modal-popup__header">
                        {{$t('POPUP_DONE_ADDED')}}
                    </div>

                    <div class="cz2__modal-popup__subheader">
                        {{$t('POPUP_DONE_HEADER')}}
                    </div>

                    <div class="cz2__modal-popup__body-copy">
                        {{$t('POPUP_DONE_BODY')}}
                    </div>

                    <div class="cz2__modal-popup__actions">
                        <button class="cz2__modal-popup__button cz2__modal-popup__button--ok" @click.prevent="confirm">
                            {{$t('POPUP_DONE_OK')}}
                        </button>

                        <button class="cz2__modal-popup__button cz2__modal-popup__button--cancel" @click.prevent="cancel">
                            {{$t('POPUP_DONE_CANCEL')}}
                        </button>
                    </div>
                </div>
            </focus-lock>
        </div>
    </div>
</template>

<style lang="scss" scoped>
    @import "../style/variables.scss";

    // #cz2.cz2 {
    //     .cz2__modal-popup {
    //         // since user must be scrolled to the bottom
    //         // to hit the add to cart button, set the
    //         // overlay backdrop to be bottom-aligined.
    //         bottom: 0;
    //     }
    // }
</style>

<script>
    import FocusLock from 'vue-focus-lock';

    export default {
        components: {
            FocusLock,
        },

        methods: {
            close() {
                this.$store.dispatch('showDoneConfirmation', false);

                this.$store.dispatch('continueCustomizing');
            },

            cancel() {
                this.$store.dispatch('showDoneConfirmation', false);

                this.$store.dispatch('continueCustomizing');
            },

            confirm() {
                this.$store.dispatch('showDoneConfirmation', false);

                this.$store.dispatch('globalCancel');
            },
        },
    };
</script>

import VueI18n from 'vue-i18n';

const messages = {
    en: {
        MENU_HEADER: 'Customize',
        // eslint-disable-next-line
        MENU_SUBHEADER: 'Outfit yourself or your team in custom workwear. For more information and FAQs, <a href="/custom-workwear.html" target="_blank">click here.</a>',

        MENU_CHOOSE_COLOR: 'Choose Color',
        MENU_CHOOSE_SIZE: 'Choose Size',

        MENU_CHOOSE_A_SIZE: 'Choose a size',

        ORDINAL_1: '',
        ORDINAL_2: '2nd',
        ORDINAL_3: '3rd',
        ORDINAL_4: '4th',
        ORDINAL_5: '5th',

        TIP_EMBROIDERY: 'Choose location, text and font to embroider names and add name badges',
        TIP_SECOND_EMBROIDERY: 'Choose additional location, text and font to embroider names and add name badges',
        TIP_ART: 'Choose location for American Flag patch',
        TIP_SECOND_ART: 'Choose additional location for American Flag patch',
        TIP_LOGO: 'Choose location to upload or select a previously uploaded logo to place on your garment',
        TIP_SECOND_LOGO: 'Choose additional location to upload or select a previously uploaded logo to place on your garment',

        MENU_EMBROIDERY: 'Embroidery',
        MENU_EMBROIDERY_ADD: 'Add {index} Embroidery',
        MENU_EMBROIDERY_EDIT: 'Edit Embroidery',

        MENU_ART: 'American Flag',
        MENU_ART_ADD: 'Add {index} American Flag',

        MENU_LOGO: 'Logo',
        MENU_LOGO_ADD: 'Add {index} Logo',

        MENU_OPIONAL: 'Optional',

        MENU_CLOSE: 'Close',

        ACTION_ADD: 'Add',
        ACTION_SAVE: 'Save',
        ACTION_CANCEL: 'Cancel',
        ACTION_ADD_TO_CART: 'Add to Cart',
        ACTION_ADD_TO_PROGRAM: 'Add to Program',

        SIZE_CHOOSE_FIT: 'Choose your fit',
        SIZE_SIZE: 'Select Size',
        SIZE_WHAT_SIZE_AM_I: 'Size Guide',
        SIZE_BODY: 'Select Length',
        SIZE_NECK: 'Neck',
        SIZE_SLEEVE: 'Sleeve',

        EDIT_CHOOSE_LOCATION: 'Choose a location',

        EDIT_ART_CHOOSE_ICON: 'Choose an icon',
        EDIT_ART_REMOVE_ICON: 'Remove emblem',

        EDIT_LOGO_CHOOSE_ICON: 'Choose your art',
        EDIT_LOGO_LOGIN: 'You need an account to upload your logo. Please',
        EDIT_LOGO_LOGIN_LOGIN: 'log in',
        EDIT_LOGO_LOGIN_OR: 'or',
        EDIT_LOGO_LOGIN_SIGNUP: 'sign up',
        EDIT_LOGO_LOGIN_NOW: 'now.',

        EDIT_LOGO_UPLOAD_FILE: 'Upload a file',
        EDIT_LOGO_UPLOAD_LIMIT: '10 MB max',

        EDIT_LOGO_FILE_TYPES: 'jpg, gif, tif, png, pdf, ai, eps',

        EDIT_LOGO_UPLOAD_DRAG: 'Drag & drop',
        EDIT_LOGO_UPLOAD_BROWSE: 'or browse your computer',

        EDIT_LOGO_SIZE_WARN: 'Logos should not exceed 4”x4” in size',
        EDIT_LOGO_SIZE_WARN_2IN: 'Logos should not exceed 3”x2” in size',

        EDIT_LOGO_REMOVE_ICON: 'Remove art',

        EDIT_LOGO_CONFIRM_RIGHTS: 'I verify that I have the rights to use this artwork',

        EDIT_LOGO_FEE: 'A one time {price} digitization fee will be added to your order',
        // eslint-disable-next-line
        EDIT_LOGO_REVIEW: 'Once your order is placed with a new uploaded logo, we will digitize your file for embroidery and email a digital proof back to you within 48 hours. Once you respond with your approval, we will begin making your garment.',

        EDIT_LOGO_MULTIPLE: 'Applying recently uploaded images to products within the same order will not incur multiple logo set-up fees.',

        EDIT_LOGO_NOTES: 'Notes',
        EDIT_LOGO_NOTES_LIMIT: '{characters} characters remaining',

        EDIT_LOGO_NOTES_PLACEHOLDER: 'Provide any additional direction regarding your artwork (i.e. "Remove white background", "Use only red thread")',

        EDIT_LOGO_PLACEMENT_OVERLAY_HEADER: 'Your logo will preview here',
        EDIT_LOGO_PLACEMENT_OVERLAY_BODY: 'Actual size and colors may vary',

        EDIT_LOGO_SAVED: 'Saved files',
        EDIT_LOGO_RECENT: 'Recently uploaded',

        EDIT_LOGO_HEIGHT: 'Logo height',

        EDIT_TEXT_CHOOSE_STYLE: 'Choose a Style',
        EDIT_TEXT_STYLE_ED: 'Text on garment',
        EDIT_TEXT_STYLE_EM_O: 'Oval name patch',
        EDIT_TEXT_STYLE_EM_R: 'Rectangular name patch',

        EDIT_TEXT_SIZE: 'Size',
        EDIT_TEXT_COLOR: 'Color',

        EDIT_TEXT_EDIT_TEXT: 'Stylize your text',

        EDIT_TEXT_ENTER: 'Enter your text',
        EDIT_TEXT_ENTER_HINT: '1 line maximum',
        EDIT_TEXT_ENTER_HINT_MANY: '{lines} lines maximum',
        EDIT_TEXT_ENTER_HINT_CHARS: '{characters} characters per line',

        EDIT_TEXT_THREAD: 'Thread color',

        EDIT_TEXT_FONT: 'Font',

        EDIT_TEXT_FONT_SIZE: 'Text Size',
        EDIT_TEXT_FONT_LIMIT_CHANGE: 'Number of characters is subject to change with font selection',

        EDIT_TEXT_REMOVE_ICON: 'Remove text',

        CHECKOUT_QUANTITY: 'Quantity',
        CHECKOUT_QUANTITY_LONG: 'Quantity',
        CHECKOUT_MINUS: '-',
        CHECKOUT_PLUS: '+',
        CHECKOUT_MINUS_TITLE: 'Decrease',
        CHECKOUT_PLUS_TITLE: 'Increase',

        CHECKOUT_PRODUCT: 'Product',
        CHECKOUT_CUSTOMIZATIONS: 'Customizations',
        CHECKOUT_SUBTOTAL: 'Subtotal',
        CHECKOUT_DIGITIZATION: 'One-Time Logo<br/>Digitization Fee',

        BUSINESS_ORDER: 'Purchasing for your business?',

        POPUP_CLOSE: 'Close',

        POPUP_LEAVING_HEADER: 'Are you leaving?',
        POPUP_LEAVING_BODY: 'Please note that your current design will not be saved.',
        POPUP_LEAVING_OK: 'Yes, leave this page',
        POPUP_LEAVING_CANCEL: 'No, go back',

        RESET_CUSTOMIZATIONS: 'Reset Design',

        POPUP_RESET_HEADER: 'Are you sure you want to reset your design?',
        POPUP_RESET_BODY: 'Please note that your current customizations will be removed.',
        POPUP_RESET_OK: 'Yes, reset my design',
        POPUP_RESET_CANCEL: 'No, go back',

        POPUP_SWITCH_PROGRAM_HEADER: 'Are you sure you want to switch to program builder?',
        POPUP_SWITCH_PROGRAM_BODY: 'Please note that size and embroidery will be removed from your design. Color, logo, and patch selections will be retained.',
        POPUP_SWITCH_PROGRAM_OK: 'Yes, switch',
        POPUP_SWITCH_PROGRAM_CANCEL: 'No, go back',

        POPUP_DONE_HEADER: 'Customize another?',
        POPUP_DONE_ADDED: 'Product added to basket',
        POPUP_DONE_BODY: 'Create another item by changing customizations such as name embroidery.',
        POPUP_DONE_OK: 'No, continue shopping',
        POPUP_DONE_CANCEL: 'Yes, customize another',

        EDIT_TEXT_EXACT: 'Input text exactly as you would like for it to be sewn.',

        EDIT_IMAGE_LIMIT: 'Image size limited to {x} x {y} due to product constraints.',

        PROGRAM_MENU_SIZE: 'Size',
        PROGRAM_MENU_SIZE_INFO: 'The item size will be selected when placing a program order',
        PROGRAM_PICK_TEMPLATE: 'Select embroidery template',

        // eslint-disable-next-line max-len
        PROGRAM_TEMPLATE_HINT: 'Select an embroidery template or enter a custom template. Template variables will be substituted with actual values when placing a program order. <b>If a value runs over the character limit, a warning will be generated at the order time.</b>',

        PROGRAM_CUSTOM_ENTRY: 'Custom Template or Text Entry',
        PROGRAM_CUSTOM_EMPTY: 'Empty',
        PROGRAM_CUSTOM_LINE: 'Line {line}:',
        PROGRAM_CUSTOM_EXAMPLE: 'Example:',
        PROGRAM_ENTER_TEMPLATE: 'Enter custom template',
        PROGRAM_VARIABLES: 'You can type a static text or one of the following variables that will be substituted when placing an order: ',

        PROGRAM_SWITCH: 'Add to a program instead?',

        ERROR_PROFANITY: 'Your entry contains restricted text. Please revise and try again.',
    },
};

export default function getTranslations(locale, overrides) {
    const translations = JSON.parse(JSON.stringify(messages));

    if (overrides) {
        Object.keys(translations).forEach((lang) => {
            if (overrides[lang]) {
                Object.assign(translations[lang], overrides[lang]);
            }
        });

        Object.keys(overrides).forEach((lang) => {
            if (overrides[lang]) {
                if (translations[lang] == null) {
                    translations[lang] = {};
                }
                Object.assign(translations[lang], overrides[lang]);
            }
        });
    }

    return new VueI18n({
        locale,
        fallbackLocale: 'en',
        messages: translations,
        silentFallbackWarn: true,
    });
}

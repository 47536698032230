<template>
    <div ref="app" id="cz2" :class="[
        'cz2',
        {
            'cz2--no-outlines': hideOutlines,
            'cz2--loaded': loaded,
            'cz2--absolute': useAbsolute,
        }
    ]" @keydown="keyDown" @mousedown="mouseDown" :style="styles()">
        <focus-lock :disabled="true">
            <customizer></customizer>

            <transition name="cz2__fade">
                <popup-leaving v-if="showExitConfirmation"></popup-leaving>
            </transition>

            <transition name="cz2__fade">
                <popup-reset v-if="showResetConfirmation"></popup-reset>
            </transition>

            <transition name="cz2__fade">
                <popup-switch-program v-if="showSwitchProgramConfirmation"></popup-switch-program>
            </transition>

            <transition name="cz2__fade">
                <popup-done v-if="showDoneConfirmation"></popup-done>
            </transition>

            <div v-if="globalBusy" class="cz2__global-busy">
            </div>
        </focus-lock>
    </div>
</template>

<style lang="scss">
    @import "style/variables.scss";

    @import "style/fonts.scss";

    @import url('https://use.typekit.net/sbo2obb.css');

    // Hide everything that is not the widget.
    .cz2__overlay-lock {
        overflow: hidden !important;
    }

    .cz2__overlay-lock > *:not(.cz2):not(#tasks-container) {
        // display: none !important;
        // margin: 0 !important;
        // padding: 0 !important;
    }

    #cz2.cz2 {
        position: relative;

        left: 0;
        top: 0;
        width: 100vw;

        --app-height: 100%;
        --modal-top-value: 0;

        z-index: 10000;

        background: #fff;

        box-sizing: border-box;

        * {
            box-sizing: border-box;
        }

        @include respond-above(sm) {
            position: fixed;
            height: 100vh;
        }

        [data-lock] {
            position: relative;

            left: 0;
            top: 0;

            width: 100%;
            height: 100%;

            display: flex;

            flex-direction: column;

            @include respond-above(sm) {
                // flex-direction: row;
                position: absolute;
            }
        }

        opacity: 0;
        pointer-events: none;
        transition: opacity $transition-fast ease-out;

        &.cz2--loaded {
            opacity: 1;
            pointer-events: auto;
        }

        &.cz2--absolute {
            position: relative;

            @include respond-above(sm) {
                position: absolute;
            }
        }

        &.cz2--no-outlines {
            *, a, button {
                outline: none !important;
            }

            :focus {
                outline: none !important;
            }
        }

        .cz2__global-busy {
            position: absolute;

            left: 0;
            top: 0;

            width: 100%;
            height: 100%;

            z-index: 99;

            background: rgba(255, 255, 255, 0.6);

            cursor: wait;
        }
    }
</style>

<script>
    import FocusLock from 'vue-focus-lock';
    import interact from 'interactjs';

    import Customizer from './components/Customizer.vue';

    import PopupLeaving from './components/PopupLeaving.vue';
    import PopupReset from './components/PopupReset.vue';
    import PopupSwitchProgram from './components/PopupSwitchProgram.vue';
    import PopupDone from './components/PopupDone.vue';

    export default {
        props: {
            options: {
                type: Object,
                default: () => {},
            },
        },
        components: {
            FocusLock,
            Customizer,
            PopupLeaving,
            PopupReset,
            PopupDone,
            PopupSwitchProgram,
        },
        data() {
            return {
                loaded: false,
                useAbsolute: false,

                hideOutlines: true,

                lockHeight: null,

                // heightTimer: null,
            };
        },
        computed: {
            /**
             * Is the UX blocked?
             */
            globalBusy() {
                return this.$store.state.globalBusy;
            },

            /**
             * Is exit confirmation visible?
             */
            showExitConfirmation() {
                return this.$store.state.showExitConfirmation;
            },

            /**
             * Is reset confirmation visible?
             */
            showResetConfirmation() {
                return this.$store.state.showResetConfirmation;
            },

            /**
             * Confirm switching to program?
             */
            showSwitchProgramConfirmation() {
                return this.$store.state.showSwitchProgramConfirmation;
            },

            /**
             * Is any modal showing?
             */
            hasOpenModal() {
                const { showDoneConfirmation, showExitConfirmation, showResetConfirmation } = this.$store.state;

                return showDoneConfirmation || showExitConfirmation || showResetConfirmation;
            },

            /**
             * Is done confirmation visible?
             */
            showDoneConfirmation() {
                return this.$store.state.showDoneConfirmation;
            },

            /**
             * Shortcut to self-destruct flag.
             */
            selfDestruct() {
                return this.$store.state.selfDestruct;
            },

        },
        watch: {
            /**
             * Begin self-destruct sequence.
             */
            selfDestruct(v) {
                if (v) {
                    this.loaded = false;

                    setTimeout(() => {
                        this.$emit('selfDestruct');
                    }, 500);
                }
            },

            /**
             * Freeze page scrolling when a modal opens
             */
            hasOpenModal(v) {
                if (v) {
                    document.body.style.overflow = 'hidden';
                } else {
                    document.body.style.overflow = 'auto';
                }
            },
        },
        mounted() {
            interact.addDocument(window.document, {
                events: { passive: false },
            });

            // Save options in all modules.
            this.$store.dispatch('setOptions', this.options);
            this.$store.dispatch('setCustomizerOptions', this.options);
            this.$store.dispatch('setEcommerceOptions', this.options);

            // Once the popup is in DOM, load the product data.
            this.$store.dispatch('load', {
                options: this.options,
            });

            this.loaded = true;

            // Lock height.
            if (window.innerWidth < 1024) {
                this.lockHeight = window.innerHeight * (document.body.clientWidth / window.innerWidth);
            } else {
                this.lockHeight = null;
            }

            // Reset scroll position.
            this.oldBodyScroll = document.body.scrollTop || document.documentElement.scrollTop;
            document.body.scrollTop = 0;
            document.documentElement.scrollTop = 0;

            document.body.style.maxHeight = this.lockHeight ? `${this.lockHeight}px` : `${this.$refs.app.offsetHeight}px`;

            // Replace fixed positioning with absolute.
            this.useAbsolute = true;

            // Hide everything so we have a clean page.
            document.body.classList.add('cz2__overlay-lock');

            window.addEventListener('resize', this.resize);

            // Because the scrolling state may have changed now with the body height adjusted, reevaluate sizing a moment later.
            // dev note: I don't understand why this needs to run so often :)
            this.heightTimer = setInterval(this.checkHeight, 100);

            this.$nextTick(() => {
                this.resize();
            });
        },
        beforeDestroy() {
            document.body.classList.remove('cz2__overlay-lock');
            document.body.style.maxHeight = 'auto';

            window.removeEventListener('resize', this.resize);

            if (this.oldBodyScroll) {
                document.body.scrollTop = this.oldBodyScroll;
                document.documentElement.scrollTop = this.oldBodyScroll;
            }

            if (this.heightTimer) {
                clearInterval(this.heightTimer);
            }
        },
        methods: {
            /**
             * Ensures full-screen dimensions on mobile.
             */
            checkHeight() {
                // Lock height.
                if (window.innerWidth < 1024) {
                    // console.log('called checkHeight');
                    let h = window.innerHeight * (document.body.clientWidth / window.innerWidth);

                    const heights = [
                        this.$refs.app.querySelector('.cz2__navigation')?.offsetHeight ?? 0,
                        this.$refs.app.querySelector('.cz2__views')?.offsetHeight ?? 0,
                        Array.from(this.$refs.app.querySelectorAll('.cz2__sidebar')).find((x) => x.offsetHeight > 0)?.offsetHeight ?? 0,
                        this.$refs.app.querySelector('.cz2__footer')?.offsetHeight ?? 0,
                        this.$refs.app.querySelector('.cz2__footer__next')?.offsetHeight ?? 0,
                    ];

                    // console.log(heights);

                    const nonIntersectHeight = heights.reduce((a, v) => a + v);

                    if (h < nonIntersectHeight) {
                        h = nonIntersectHeight;
                    }

                    if (this.lockHeight !== h) {
                        this.lockHeight = h;

                        document.body.style.maxHeight = `${h}px`;
                    }

                    // since this is already running constantly,
                    // we'll use it for now to set the top position
                    // of the modal backdrop based on user's
                    // current scroll position
                    this.$refs.app.style.setProperty('--modal-top', `${window.scrollY}px`);
                } else if (this.lockHeight) {
                    this.lockHeight = null;
                    document.body.style.maxHeight = 'auto';
                }
            },

            /**
             * Hides outlines on mouse actions.
             */
            mouseDown() {
                this.hideOutlines = true;
            },

            /**
             * Shows outlines again when keyboard is engaged.
             */
            keyDown() {
                this.hideOutlines = false;
            },

            /**
             * Update sizing parameters.
             */
            resize() {
                this.$refs.app.style.setProperty('--app-height', `${window.innerHeight}px`);

                document.body.style.maxHeight = `${this.$refs.app.offsetHeight}px`;

                this.$store.dispatch('setWindowWidth', window.innerWidth);
            },

            /**
             * Calculate widget styles.
             */
            styles() {
                const s = {
                };

                if (this.lockHeight && window.innerWidth >= 1024) {
                    // s.height = `${this.lockHeight}px`;
                    s.maxHeight = `${this.lockHeight}px`;
                }

                return s;
            },
        },
    };
</script>

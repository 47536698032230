<template>
    <div class="cz2__customizer">
        <transition name="cz2__fade-secondary">
            <colors v-if="selectedStep === 'colors'">
            </colors>
        </transition>

        <transition name="cz2__fade-secondary">
            <sizes v-if="selectedStep === 'sizes'">
            </sizes>
        </transition>

        <transition name="cz2__fade-secondary">
            <edit-text v-if="selectedStep != null && (selectedStep.startsWith('new-embroidery'))">
            </edit-text>
        </transition>

        <transition name="cz2__fade-secondary">
            <edit-text v-if="selectedStep != null && (selectedStep.startsWith('edit-embroidery'))">
            </edit-text>
        </transition>

        <transition name="cz2__fade-secondary">
            <edit-art v-if="selectedStep != null && (selectedStep.startsWith('new-art'))">
            </edit-art>
        </transition>

        <transition name="cz2__fade-secondary">
            <edit-art v-if="selectedStep != null && (selectedStep.startsWith('edit-art'))">
            </edit-art>
        </transition>

        <transition name="cz2__fade-secondary">
            <edit-logo v-if="selectedStep != null && (selectedStep.startsWith('new-logo'))">
            </edit-logo>
        </transition>

        <transition name="cz2__fade-secondary">
            <edit-logo v-if="selectedStep != null && (selectedStep.startsWith('edit-logo'))">
            </edit-logo>
        </transition>

        <menu-bar></menu-bar>

        <renderer></renderer>

        <header-bar v-on:close="closeCustomizer"></header-bar>

    </div>
</template>

<style lang="scss">
    @import "../style/variables.scss";

    #cz2.cz2 {
        .cz2__customizer {
            position: relative;

            left: 0;
            top: 0;
            width: 100%;
            // IE11
            min-height: 100%;
            min-height: var(--app-height);

            display: flex;
            flex-direction: column-reverse;

             @include respond-above(sm) {
                display: block;
                position: absolute;
            }
        }

        .cz2__panel.cz2__colors,
        .cz2__panel.cz2__sizes,
        .cz2__panel.cz2__edit-text,
        .cz2__panel.cz2__edit-art,
        .cz2__panel.cz2__edit-logo {
            &.cz2__fade-secondary-enter-active, &.cz2__fade-secondary-leave-active {
                transition: right $transition-fast ease-out;
            }

            &.cz2__fade-secondary-enter, &.cz2__fade-secondary-leave-to {
                right: calc(390px - 325px);
            }
        }
    }
</style>

<script>
    import HeaderBar from './HeaderBar.vue';
    import MenuBar from './Menu.vue';
    import Renderer from './Renderer.vue';
    import Colors from './Colors.vue';
    import Sizes from './Sizes.vue';
    import EditText from './EditText.vue';
    import EditArt from './EditArt.vue';
    import EditLogo from './EditLogo.vue';

    export default {
        components: {
            HeaderBar,
            MenuBar,
            Renderer,
            Colors,
            Sizes,
            EditText,
            EditArt,
            EditLogo,
        },
        data() {
            return {};
        },
        computed: {
            selectedStep() {
                return this.$store.state.selectedStep;
            },
        },
        methods: {
            /**
             * Close customizer widget.
             */
            closeCustomizer() {
                this.$store.dispatch('showExitConfirmation', true);
            },
        },
    };
</script>

import customizerStore from './vuex/customizer/store';
import ecommerceStore from './vuex/ecommerce/store';

export default {
    state() {
        return {
            options: null,

            // Are we in the self-destruct mode.
            selfDestruct: false,

            // Global busy indicator.
            globalBusy: false,

            // Selected step.
            selectedStep: null,

            // Window width.
            windowWidth: window.innerWidth,

            // Renderer height.
            rendererHeight: null,

            // Is the exit confirmation dialog visible.
            showExitConfirmation: false,

            // Is the reset confirmation dialog visible.
            showResetConfirmation: false,

            // Is the program switch confirmation dialog visible.
            showSwitchProgramConfirmation: false,

            // Is the done confirmation dialog visible.
            showDoneConfirmation: false,
        };
    },

    mutations: {
        /**
         * Saves options.
         */
        SET_OPTIONS(state, options) {
            state.options = options;
        },

        /**
         * Self-destruct in ... 3... 2... 1...
         */
        SELFDESTUCT(state) {
            state.selfDestruct = true;
        },

        /**
         * Sets global busy status.
         */
        SET_GLOBAL_BUSY(state, v) {
            state.globalBusy = v;
        },

        /**
         * Update current step.
         */
        SET_SELECTED_STEP(state, v) {
            state.selectedStep = v;
        },

        /**
         * Saves window width.
         */
        SET_WINDOW_WIDTH(state, v) {
            state.windowWidth = v;
        },

        /**
         * Saves renderer height.
         */
        SET_RENDERER_HEIGHT(state, v) {
            state.rendererHeight = v;
        },

        /**
         * Saves exit confirmation status.
         */
        SET_EXIT_CONFIRMATION(state, v) {
            state.showExitConfirmation = v;
        },

        /**
         * Saves reset confirmation status.
         */
        SET_RESET_CONFIRMATION(state, v) {
            state.showResetConfirmation = v;
        },

        /**
         * Saves switch program confirmation status.
         */
        SET_SWITCH_PROGRAM_CONFIRMATION(state, v) {
            state.showSwitchProgramConfirmation = v;
        },

        /**
         * Saves don confirmation status.
         */
        SET_DONE_CONFIRMATION(state, v) {
            state.showDoneConfirmation = v;
        },
    },

    actions: {
        /**
         * Saves options.
         */
        setOptions({ commit }, options) {
            commit('SET_OPTIONS', options);
        },

        /**
         * Select a new step.
         */
        selectStep({ commit, dispatch }, v) {
            commit('SET_SELECTED_STEP', v);

            // Unselect any focused placements.
            dispatch('setFocusedPlacement', null);
            dispatch('showPlacementOverlay', null);

            // Scroll to top
            const customizerModal = document.getElementById('customizerModal');
            if (customizerModal) {
                if (customizerModal.scrollTo) {
                    customizerModal.scrollTo(0, 0);
                }
            } else if (window.scrollTo) {
                window.scrollTo(0, 0);
            }
        },

        /**
         * Cancels out from the widget.
         */
        globalCancel({ commit, state }) {
            if (state.options && state.options.onCancel) {
                state.options.onCancel();
            }

            commit('SELFDESTUCT');
        },

        /**
         * Closes the widget.
         */
        globalClose({ commit }) {
            commit('SELFDESTUCT');
        },

        /**
         * Sends out a notification that the widget is busy.
         */
        globalBusy({ commit, state }, busy) {
            commit('SET_GLOBAL_BUSY', busy);

            if (state.options && state.options.onBusy) {
                state.options.onBusy(busy);
            }
        },

        /**
         * Sends out successful message and closes the widget.
         */
        globalDone({ state }, result) {
            if (state.options && state.options.onDone) {
                state.options.onDone(result);
            }
        },

        /**
         * Show business order popup.
         */
        globalBusinessOrder({ state }) {
            if (state.options && state.options.onBusinessOrder) {
                state.options.onBusinessOrder();
            }
        },

        /**
        * Show business order popup.
        */
        showSizingChart({ state }) {
            if (state.options && state.options.onSizingChart) {
                state.options.onSizingChart();
            }
        },

        /**
         * Send analytics event.
         */
        analytics({ state }, event) {
            if (state.options.onAnalytics) {
                state.options.onAnalytics(event);
            }
        },

        /**
         * Saves window width.
         */
        setWindowWidth({ commit }, v) {
            commit('SET_WINDOW_WIDTH', v);
        },

        /**
         * Saves renderer height.
         */
        setRendererHeight({ commit }, v) {
            commit('SET_RENDERER_HEIGHT', v);
        },

        /**
         * Shows exit confirmation.
         */
        showExitConfirmation({ commit }, v) {
            commit('SET_EXIT_CONFIRMATION', v);
        },

        /**
         * Shows reset confirmation.
         */
        showResetConfirmation({ commit }, v) {
            commit('SET_RESET_CONFIRMATION', v);
        },

        /**
         * Shows switch program confirmation.
         */
        showSwitchProgramConfirmation({ commit }, v) {
            commit('SET_SWITCH_PROGRAM_CONFIRMATION', v);
        },

        /**
         * Shows done confirmation.
         */
        showDoneConfirmation({ commit }, v) {
            commit('SET_DONE_CONFIRMATION', v);
        },
    },

    modules: {
        ecommerce: ecommerceStore,
        customizer: customizerStore,
    },
};

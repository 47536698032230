// Imports
import ___CSS_LOADER_API_NO_SOURCEMAP_IMPORT___ from "../../node_modules/css-loader/dist/runtime/noSourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../node_modules/css-loader/dist/runtime/api.js";
import ___CSS_LOADER_GET_URL_IMPORT___ from "../../node_modules/css-loader/dist/runtime/getUrl.js";
var ___CSS_LOADER_URL_IMPORT_0___ = new URL("../assets/close-red@2x.png", import.meta.url);
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_NO_SOURCEMAP_IMPORT___);
var ___CSS_LOADER_URL_REPLACEMENT_0___ = ___CSS_LOADER_GET_URL_IMPORT___(___CSS_LOADER_URL_IMPORT_0___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, "#cz2.cz2 .cz2__header-bar{position:relative;display:flex;align-items:center;justify-content:center;background:#fbf4ed;padding:8px 60px}@media(min-width:1024px){#cz2.cz2 .cz2__header-bar{position:absolute;top:20px;left:20px;height:auto;background:transparent;padding:0}}#cz2.cz2 .cz2__header-bar__close{position:absolute;left:20px;top:50%;z-index:2;width:22px;height:22px;display:flex;align-items:center;justify-content:center;transform:translateY(-50%);cursor:pointer}@media(min-width:768px){#cz2.cz2 .cz2__header-bar__close{width:40px;height:40px}}@media(min-width:1024px){#cz2.cz2 .cz2__header-bar__close{position:static;transform:translate(0)}}#cz2.cz2 .cz2__header-bar__close:after{content:\"\";width:17px;height:17px;background:transparent url(" + ___CSS_LOADER_URL_REPLACEMENT_0___ + ") no-repeat 50%/contain}@media(min-width:768px){#cz2.cz2 .cz2__header-bar__close:after{width:20px;height:20px}}#cz2.cz2 .cz2__header-bar__title{color:#1c1513;font-family:neue-haas-grotesk-display,sans-serif;font-size:24px;font-weight:600;line-height:32px}@media(min-width:768px){#cz2.cz2 .cz2__header-bar__title{font-size:24px;font-weight:500;line-height:28px}}@media(min-width:1024px){#cz2.cz2 .cz2__header-bar__title{display:none}}", ""]);
// Exports
export default ___CSS_LOADER_EXPORT___;

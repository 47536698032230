<template>
    <div class="cz2__panel cz2__panel--secondary cz2__colors">
        <focus-lock>
            <div class="cz2__panel__body cz2__colors__body" tabindex="0">
                <div class="cz2__colors__body__inner">
                    <button v-for="color in availableColors" :key="color.component.code" :class="[
                        'cz2__color-option',
                        {
                            'cz2__color-option--selected': color.selected,
                            'cz2__color-option--not-available': !color.available,
                        }
                    ]" @click.prevent="selectColor(color)">
                        <span class="cz2__color-option__color">
                            <span class="cz2__color-option__color__inner" :style="getSwatchBackground(color)"></span>
                        </span>

                        <span class="cz2__color-option__name">
                            {{color.lot.name}}
                        </span>
                    </button>
                </div>
            </div>

            <div class="cz2__panel__actions">
                <button class="cz2__panel-action" @click.prevent="cancel"><span>{{$t('ACTION_CANCEL')}}</span></button>
                <button class="cz2__panel-action cz2__panel-action--primary" @click.prevent="accept"><span>{{$t('ACTION_SAVE')}}</span></button>
            </div>
        </focus-lock>
    </div>
</template>

<style lang="scss">
    @import "../style/variables.scss";

    #cz2.cz2 {

        .cz2__panel__body.cz2__colors__body {
            display: flex;

            justify-content: flex-start;
            align-content: flex-start;

            flex-wrap: wrap;
        }

        .cz2__colors__body__inner {
            display: flex;
            flex-wrap: wrap;
            gap: 23px;

            // justify-content: flex-start;
            // align-content: flex-start;

        }

        .cz2__color-option {
            display: flex;
            flex-direction: column;

            position: relative;

            justify-content: flex-start;
            align-items: center;

            width: 72px; // three / row on desktop
            // min-height: 110px;
            margin-bottom: 10px;

        }

        .cz2__color-option__color {
            position: relative;

            display: block;
            width: 48px;
            height: 48px;
            margin-bottom: 8px;
            padding: 6px;
            border: 2px solid transparent;
            border-radius: 100%;

            transition: all $transition-fast ease-out;

        }

        .cz2__color-option__color__inner {
            display: block;

            width: 100%;
            height: 100%;
            border-radius: 50%;

            transition: all $transition-fast ease-out;

        }

        .cz2__color-option__name {
            display: block;
            overflow: hidden;

            color: $color-primary-dark;

            font-family: $font-main;
            font-weight: 400;
            font-size: 12px;
            line-height: 18px;
            text-align: center;
            text-transform: capitalize;

            text-overflow: ellipsis;
            -webkit-line-clamp: 3;
            display: -webkit-box;
            -webkit-box-orient: vertical;
        }

        .cz2__color-option.cz2__color-option--not-available {
            cursor: default;
            pointer-events: none;

            .cz2__color-option__color {
                &:before {
                    content: '';

                    position: absolute;
                    left: 50%;
                    top: 0;
                    bottom: 0;

                    width: 2px;

                    background: $color-primary-dark;

                    transform: translateX(-1px) rotate(45deg);
                }
            }

            .cz2__color-option__color__inner {
                opacity: 0.5;
            }
        }

        .cz2__color-option.cz2__color-option--selected {

            .cz2__color-option__color {
                border-color: $color-primary-dark;
            }
        }
    }
</style>

<script>
    import FocusLock from 'vue-focus-lock';

    import * as constants from '../constants';

    export default {
        components: {
            FocusLock,
        },
        computed: {
            /**
             * Current view controller.
             */
            viewController() {
                return this.$store.state.customizer.viewController;
            },

            /**
             * Selected size variant.
             */
            selectedSize() {
                return this.$store.state.customizer.selectedSize;
            },

            /**
             * Get variants collection.
             */
            variants() {
                return this.$store.state.customizer.variants;
            },

            /**
             * Generate a list of available components.
             */
            availableColors() {
                if (this.viewController == null) {
                    return [];
                }

                const lots = this.$store.getters.availableLots;
                const productPlacement = this.viewController.findPlacement(constants.PLACEMENT_PRODUCT);

                const selectedProduct = this.viewController.selectedAtPlacement(constants.PLACEMENT_PRODUCT);

                return productPlacement.components.filter((c) => lots.some((l) => l.lot === c.code)).map((c) => {
                    let available = true;

                    if (this.selectedSize) {
                        const variant = this.variants.variants.find((v) => {
                            if (v.available && v.lot === c.code) {
                                let match = true;

                                this.variants.variationAttributes.forEach((a) => {
                                    if (v[a] !== this.selectedSize[a]) {
                                        match = false;
                                    }
                                });

                                if (match) {
                                    return true;
                                }
                            }

                            return false;
                        });

                        if (variant == null) {
                            available = false;
                        }
                    }

                    return {
                        component: c,
                        available,
                        selected: c.code === selectedProduct.code,
                        lot: lots.find((l) => l.lot === c.code),
                    };
                });
            },
        },
        mounted() {
            // When the component is loaded, capture the current state of the view controller and save it in case
            // we need to revert to the previous state and toss away all customizations.
            //
            // At any given time there should be only one module that can do the revert.
            this.captureController();
        },
        methods: {
            /**
             * Captures the current state and active controller so we could cancel changes if necessary.
             */
            captureController() {
                if (this.viewController) {
                    this.revertSize = this.selectedSize;
                    this.revertController = this.viewController.clone();
                }
            },

            /**
             * Accept color choice.
             */
            accept() {
                this.$store.dispatch('validateSizeForLot');

                if (this.selectedColor) {
                    if (!this.selectedColor.available) {
                        this.$store.dispatch('selectSize', null);
                    }
                }

                // Keep the current configured view controller, and go back to the main screen.
                this.$store.dispatch('selectStep', null);
            },

            /**
             * Reject color changes and restore the original selection.
             */
            cancel() {
                // Revert back to the state of the controller as was captured when the component was mounted.
                this.$store.dispatch('showController', this.revertController);

                // Go back to the main screen.
                this.$store.dispatch('selectStep', null);
            },

            /**
             * Selects a color component, and makes sure that sizes are properly selected.
             */
            selectColor(color) {
                this.selectedColor = color;

                this.viewController.updateComponent(constants.PLACEMENT_PRODUCT, color.component.code);
            },

            /**
             * Returns a css background property based on the hex information for the given variant hex value(s).
             */
            getSwatchBackground(color) {
                const {
                    hex,
                    images,
                } = color.lot;

                // Check for empty hex and image case
                const hasHexValues = Array.isArray(hex) && hex.length !== 0;
                const hasImageValues = Array.isArray(images) && images.length !== 0;
                if (!hasHexValues && !hasImageValues) {
                    return 'background: grey;';
                }

                // Check if image used instead of hex color (assume one bg image only)
                if (!hasHexValues || hex[0].indexOf('.') !== -1) {
                    return `
                        background-image: url('${images[0].url}');
                        background-position: center;
                        background-size: cover;
                        background-repeat: no-repeat;
                    `;
                }

                // One solid color, return standard background (also IE 11 safe)
                if (hex.length === 1) {
                    return `background: #${hex[0]};`;
                }

                // More than one solid colors, create vertical stack, includes IE background-color fallback
                const bg = [];
                const segmentHeight = 100 / hex.length;
                hex.forEach((hexValue, i) => {
                    bg.push(`#${hexValue} ${segmentHeight * i}% ${segmentHeight * (i + 1)}%`);
                });

                return `
                    background-color: #${hex[0]};
                    background: linear-gradient(to bottom, ${bg.join(', ')};
                `;
            },
        },
    };
</script>

var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',{ref:"app",class:[
    'cz2',
    {
        'cz2--no-outlines': _vm.hideOutlines,
        'cz2--loaded': _vm.loaded,
        'cz2--absolute': _vm.useAbsolute,
    }
],style:(_vm.styles()),attrs:{"id":"cz2"},on:{"keydown":_vm.keyDown,"mousedown":_vm.mouseDown}},[_c('focus-lock',{attrs:{"disabled":true}},[_c('customizer'),_c('transition',{attrs:{"name":"cz2__fade"}},[(_vm.showExitConfirmation)?_c('popup-leaving'):_vm._e()],1),_c('transition',{attrs:{"name":"cz2__fade"}},[(_vm.showResetConfirmation)?_c('popup-reset'):_vm._e()],1),_c('transition',{attrs:{"name":"cz2__fade"}},[(_vm.showSwitchProgramConfirmation)?_c('popup-switch-program'):_vm._e()],1),_c('transition',{attrs:{"name":"cz2__fade"}},[(_vm.showDoneConfirmation)?_c('popup-done'):_vm._e()],1),(_vm.globalBusy)?_c('div',{staticClass:"cz2__global-busy"}):_vm._e()],1)],1)
}
var staticRenderFns = []

export { render, staticRenderFns }
<template>
    <button v-if="hasAnyActivities" class="cz2__reset-button" @click.prevent="$emit('reset')">
        <span>
            {{$t('RESET_CUSTOMIZATIONS')}}
        </span>
    </button>
</template>

<style lang="scss">
    @import "../style/variables.scss";

    #cz2.cz2 {
        .cz2__reset-button {
            position: absolute;
            bottom: calc(16px + 21px); // offset + height of swiper bullets
            left: 16px;
            z-index: 2; // Above the primary renderer content

            width: auto;
            max-width: 200px; // IE fix
            padding: 9px 14px 11px;
            border: 2px solid $color-primary-dark;
            border-radius: 4px;
            display: flex;
            justify-content: center;
            align-items: center;

            background: $color-primary-light;
            color: $color-primary-dark;

            @include respond-above(sm) {
                bottom: 30px;

                padding: 13px 32px 15px;
            }

            span {
                font-family: $font-buttons;
                font-weight: 500;
                font-size: 12px;
                line-height: 24px;
                letter-spacing: 0.02em;
                text-transform: uppercase;
            }
        }
    }
</style>

<script>
    export default {
        props: {
            hasAnyActivities: {
                type: Boolean,
                default: false,
            },
        },
    };
</script>

<!--
    Displays a view inside the renderer component.
-->

<template>
    <div ref="container" class="cz2__renderer__content">
        <div ref="image" :class="[
            'cz2__image',
        ]" :style="imageStyle"></div>

        <div :class="['cz2__placement-overlay', overlayTextClass]" :style="overlayStyle ">
            <div v-if="overlayPlacementHeader" class="cz2__placement-overlay__header" :style="overlayTextStyle">{{overlayPlacementHeader}}</div>
            <div v-if="overlayPlacementBody" class="cz2__placement-overlay__body" :style="overlayTextStyle">{{overlayPlacementBody}}</div>
        </div>
    </div>
</template>

<style lang="scss">
    @import "../style/variables.scss";

    #cz2.cz2 {
        .cz2__placement-overlay {
            position: absolute;
            display: flex;

            justify-content: center;
            align-items: center;
            flex-direction: column;

            text-align: center;

            pointer-events: none;

            opacity: 0;

            transition: opacity 0.2s ease-out, transform 0.2s ease-out;

            transform: translate(0, 0);

            border: .5px dashed #e8e8e8;
            background-color: rgba(255, 255, 255, 0.2);

            &.has-focus-zoom {
                .cz2__placement-overlay__header,
                .cz2__placement-overlay__body {
                    font-size: 6px;
                    line-height: 6px;
                }

                @include respond-above(tablet) {
                    .cz2__placement-overlay__header,
                    .cz2__placement-overlay__body {
                        font-size: 8px;
                        line-height: 8px;
                    }

                    .cz2__placement-overlay__body {
                        margin-top: 0;
                    }

                }

                @include respond-above(sm) {
                    .cz2__placement-overlay__header,
                    .cz2__placement-overlay__body {
                        font-size: 12px;
                        line-height: 12px;
                        padding: 0 6px;
                    }
                }
            }
        }

        .cz2__placement-overlay__header,
        .cz2__placement-overlay__body {
            padding: 0 2px;
        }

        .cz2__placement-overlay__header {
            color: $color-primary-dark;
            font-family: $font-header;
            font-size: 12px;
            font-weight: 700;
            letter-spacing: 2px;
            line-height: 12px;
            text-transform: uppercase;
        }

        .cz2__placement-overlay__body {
            margin-top: -5px;

            color: $color-primary-dark;
            font-family: $font-main;
            font-size: 12px;
            font-weight: 400;
        }
    }
</style>

<script>
    import * as constants from '../constants';

    export default {
        props: {
            view: {
                type: String,
                default: '',
            },

            controller: {
                type: Object,
                default: null,
            },
        },
        data() {
            return {
                focusX: null,
                focusY: null,
                focusZoom: null,
                renderedSizing: null,

                overlayVisible: false,
                overlayX: null,
                overlayY: null,
                overlayWidth: null,
                overlayHeight: null,
                overlayRotate: null,
            };
        },
        computed: {
            globalOptions() {
                return this.$store.state.options;
            },

            /**
             * Currently focused placement.
             */
            focusedPlacement() {
                return this.$store.state.customizer.focusedPlacement;
            },

            /**
             * Placement overlay data.
             */
            overlayPlacement() {
                return this.$store.state.customizer.overlayPlacement;
            },

            /**
             * Placement overlay data.
             */
            overlayPlacementHeader() {
                return this.$store.state.customizer.overlayPlacementHeader;
            },

            /**
             * Placement overlay data.
             */
            overlayPlacementBody() {
                return this.$store.state.customizer.overlayPlacementBody;
            },

            /**
             * Image renderer styles.
             */
            imageStyle() {
                // Handle Desktop sub-panel adjustment
                const hasSelectedStep = this.selectedStep !== null;
                const isDesktop = this.windowWidth >= constants.DESKTOP_BREAKPOINT_SMALL;
                const panelAdjustment = hasSelectedStep && isDesktop ? constants.SUB_PANEL_DESKTOP_WIDTH / 2 : 0;

                if (this.focusZoom && this.renderedSizing) {
                    const size = this.renderedSizing;

                    const tx = (this.focusX * (size.targetWidth / size.sourceWidth)) + size.targetLeft;
                    const ty = (this.focusY * (size.targetHeight / size.sourceHeight)) + size.targetTop;

                    const cx = (0.5 * size.targetWidth) + size.targetLeft;
                    const cy = (0.5 * size.targetHeight) + size.targetTop;

                    const dx = cx - tx - panelAdjustment;
                    const dy = cy - ty;

                    return {
                        transform: `translate(${dx}px, ${dy}px) scale(${this.focusZoom})`,
                    };
                }

                return {
                    transform: `translate(${-1 * panelAdjustment}px, 0)`,
                };
            },

            /**
             * Placement overlay style.
             */
            overlayStyle() {
                if (this.overlayVisible && this.renderedSizing) {
                    const hasSelectedStep = this.selectedStep !== null;
                    const isDesktop = this.windowWidth >= constants.DESKTOP_BREAKPOINT_SMALL;
                    const panelAdjustment = hasSelectedStep && isDesktop ? constants.SUB_PANEL_DESKTOP_WIDTH / 2 : 0;

                    const size = this.renderedSizing;
                    const f = 1 / size.scale;

                    const css = {
                        left: `${f * ((this.overlayX * (size.targetWidth / size.sourceWidth)) + size.targetLeft)}px`,
                        top: `${f * ((this.overlayY * (size.targetHeight / size.sourceHeight)) + size.targetTop)}px`,
                        width: `${f * (this.overlayWidth * (size.targetWidth / size.sourceWidth))}px`,
                        height: `${f * (this.overlayHeight * (size.targetHeight / size.sourceHeight)) - 1}px`,
                        opacity: 1,
                        transform: `rotate(${this.overlayRotate}deg)`,
                    };

                    // Shift the overlay if the is a focused placement.
                    if (this.focusZoom) {
                        const tx = (this.focusX * (size.targetWidth / size.sourceWidth)) + size.targetLeft;
                        const ty = (this.focusY * (size.targetHeight / size.sourceHeight)) + size.targetTop;

                        const cx = (0.5 * size.targetWidth) + size.targetLeft;
                        const cy = (0.5 * size.targetHeight) + size.targetTop;

                        const dx = f * (cx - tx) - panelAdjustment;
                        const dy = f * (cy - ty);

                        css.transform = `translate(${dx}px, ${dy}px) scale(${this.focusZoom}) ${css.transform}`;
                        // css.border = 'none'; // '0.5px dashed #e8e8e8';
                    }

                    return css;
                }

                return {
                    transform: 'translate(0, 0)',
                };
            },

            overlayTextStyle() {
                if (this.focusZoom) {
                    const size = this.renderedSizing;
                    const f = 1 / size.scale;
                    return {
                        width: `${f * (this.overlayWidth * (size.targetWidth / size.sourceWidth)) * 2}px`,
                        // fontSize: '12px',
                        // lineHeight: '12px',
                        transform: `translate(0, 0) scale(${1 / this.focusZoom})`,
                    };
                }

                return {
                    transform: 'translate(0, 0)',
                };
            },

            overlayTextClass() {
                return this.focusZoom ? 'has-focus-zoom' : '';
            },

            /**
             * Current selected step.
             */
            selectedStep() {
                return this.$store.state.selectedStep;
            },

            windowWidth() {
                return this.$store.state.windowWidth;
            },

            isLogo2High() {
                return this.$store.getters.isLogo2High;
            },
        },
        watch: {
            controller() {
                if (this.image) {
                    this.image.adoptController(this.controller);
                    this.image.selectViewByCode(this.view);
                } else {
                    this.mountController();
                }
            },

            /**
             * When focused placement changes...
             */
            focusedPlacement() {
                this.updateFocusedPlacement();
            },

            /**
             * When overlaid placement changes...
             */
            overlayPlacement() {
                this.updatePlacementOverlay();
            },
        },
        mounted() {
            // Resize the view when window size changes.
            this.resize();
            window.addEventListener('resize', this.resizeWindow);

            this.mountController();
        },
        beforeDestroy() {
            if (this.image) {
                this.image.destroy();

                this.image = null;
            }

            window.removeEventListener('resize', this.resizeWindow);
        },
        methods: {
            /**
             * Resize after window resize.
             */
            resizeWindow() {
                setTimeout(() => {
                    // Wait a bit after resizing to allow parent container to respond first.
                    // Waiting using a timer since we are not sure when Swiper will resize slides.
                    this.resize();
                }, 10);
            },

            /**
             * Resizes image slide.
             */
            resize() {
                if (!this.$refs.container) {
                    return;
                }

                if (this.image) {
                    this.image.resize();
                }
            },

            mountController() {
                if (this.image) {
                    this.image.off();
                    this.image.destroy();
                }

                if (this.controller) {
                    this.image = new this.globalOptions.DriveCustomizer.ImageView(this.controller, {
                        container: this.$refs.image,
                        preloadViews: false,
                    });

                    this.image.on('rendered', this.rendered);

                    this.image.on('error', this.errored);

                    this.image.on('notfound', this.errored);

                    this.image.selectViewByCode(this.view);
                }
            },

            errored() {
                // eslint-disable-next-line
                console.log(`Failed to render: ${this.view}`);

                this.$emit('hide');
            },

            rendered(sizing) {
                if (sizing) {
                    this.renderedSizing = sizing;

                    this.$emit('rendered', sizing);
                }
            },

            updateFocusedPlacement() {
                if (this.focusedPlacement) {
                    const { placement } = this.focusedPlacement;
                    const { location } = placement.custom;

                    const frame = constants.LOCATIONS_BACK.includes(location) ? constants.FRAME_BACK : constants.FRAME_FRONT;

                    const requestedView = this.controller.blueprint.views.find((v) => v.frameLow === frame);

                    if (requestedView && requestedView.code === this.view) {
                        const x = +placement.custom['personalization-x'] + (+placement.custom['personalization-width'] * 0.5);
                        const y = +placement.custom['personalization-y'] + (+placement.custom['personalization-height'] * 0.5);

                        this.focusX = x;
                        this.focusY = y;
                        this.focusZoom = constants.FOCUSED_ZOOM;
                    } else {
                        this.focusZoom = null;
                    }
                } else {
                    this.focusZoom = null;
                }
            },

            updatePlacementOverlay() {
                if (this.overlayPlacement) {
                    const { placement } = this.overlayPlacement;
                    const { location } = placement.custom;

                    const frame = constants.LOCATIONS_BACK.includes(location) ? constants.FRAME_BACK : constants.FRAME_FRONT;

                    const requestedView = this.controller.blueprint.views.find((v) => v.frameLow === frame);

                    if (requestedView && requestedView.code === this.view) {
                        let x = +placement.custom['personalization-x'];
                        let width = +placement.custom['personalization-width'];
                        let y = +placement.custom['personalization-y'];
                        let height = +placement.custom['personalization-height'];
                        const rotate = +placement.custom['personalization-rotate'];

                        // Adjust for the location size.
                        let locationSize = constants.DEFAULT_SIZES.findIndex((l) => l === location);
                        if (locationSize >= 0) {
                            locationSize = constants.DEFAULT_SIZES[locationSize + 1];
                        } else {
                            locationSize = constants.DEFAULT_SIZE;
                        }

                        locationSize = locationSize.split('x').map((v) => +v);

                        // eslint-disable-next-line
                        let realWidth = width;
                        // eslint-disable-next-line
                        let realHeight = height;

                        x += (width - realWidth) * 0.5;

                        if (this.isLogo2High && locationSize && locationSize[1] > 2) {
                            const f = 2 / locationSize[1];

                            realHeight *= f;

                            if (placement.custom['personalization-vertical-alignment'] === 'bottom') {
                                // Bottom.
                                y += height - realHeight;

                                y += (height - realHeight) * 0.5;
                            } else if (placement.custom['personalization-vertical-alignment'] === 'top') {
                                // Nothing.
                            } else {
                                // Center.
                                y += (height - realHeight) * 0.5;
                            }
                        } else {
                            y += (height - realHeight) * 0.5;
                        }

                        if (this.isLogo2High && locationSize && locationSize[0] > 3) {
                            const f = 3 / locationSize[0];

                            realWidth *= f;
                        }

                        width = realWidth;
                        height = realHeight;

                        this.overlayVisible = true;
                        this.overlayX = x;
                        this.overlayY = y;
                        this.overlayWidth = width;
                        this.overlayHeight = height;
                        this.overlayRotate = rotate;
                    } else {
                        this.overlayVisible = false;
                    }
                } else {
                    this.overlayVisible = false;
                }
            },
        },
    };
</script>

<template>
    <div :class="[
        'cz2__menu-item',
        {
            'cz2__menu-item__complete': isComplete,
            'cz2__menu-item--active': isActive,
            'cz2__menu-item--with-error': hasErrors,
            'cz2__menu-item--multiline': multilineDescription,
        }
    ]">
        <div class='cz2__menu-item__inner'>
            <span class="cz2__menu-item__complete-indicator"></span>
            <span v-if="hasErrors" class="cz2__menu-item__error-icon"></span>
            <span v-else class="cz2__menu-item__placeholder"></span>
            <button @click.prevent="selectItem">
                <span class="cz2__menu-item__button-content">
                    <span class="cz2__type__small-header">
                        {{title}}

                        <template v-if="tip">
                            <span
                                :class="[
                                    'cz2__menu-item__tip',
                                    {
                                        'cz2__menu-item__tip--active': tipActive,
                                    }
                                ]"
                                @click.prevent.stop="showTip"
                            >
                                <span class="cz2__menu-item__tooltip">
                                    {{tip}}
                                </span>
                            </span>
                        </template>

                        <span v-if="price !== null" class="cz2__menu-item__price">{{price}}</span>
                    </span>

                    <span :class="['cz2__menu-item__description', 'cz2__type__normal', {
                        'cz2__menu-item__description--no-caps': capitalize == false,
                        'cz2__menu-item__description--multiline': multilineDescription
                    }]">
                        {{description}}
                    </span>
                </span>
            </button>

            <span v-if="!readonly" class="cz2__menu-item__expand-indicator"></span>
        </div>
    </div>
</template>

<style lang="scss">
    @import "../style/variables.scss";

    #cz2.cz2 {
        .cz2__menu-item__tip {
            position: relative;

            display: inline-block;

            width: 16px;
            height: 16px;

            margin-left: 2px;
            vertical-align: -3px;

            background: transparent url(../assets/info-new@2x.png) no-repeat center/contain;

            @media (hover: hover) {
                &:hover {
                    .cz2__menu-item__tooltip {
                        opacity: 1;
                        visibility: visible;
                    }
                }
            }

            @media (hover: none) {
                &.cz2__menu-item__tip--active {
                    .cz2__menu-item__tooltip {
                        opacity: 1;
                        visibility: visible;
                    }
                }
            }
        }

        .cz2__menu-item__tooltip {
            position: absolute;
            left: 50%;
            bottom: 100%;

            padding: 24px 32px 24px 24px;
            width: 210px;
            border-radius: 16px;

            background-color: $color-accent-light-gray;
            color: $color-primary-dark;

            transform: translate(-50%, -15px);

            opacity: 0;
            visibility: hidden;

            transition: all 0.25s ease-out;

            font-family: $font-main;
            font-size: 12px;
            font-weight: 400;
            line-height: 18px;

            &:after {
                content: '';

                position: absolute;
                left: 50%;
                bottom: -15px;

                width: 0;
                height: 0;
                border-left: solid transparent 15px;
                border-right: solid transparent 15px;
                border-top: solid $color-accent-light-gray 15px;
                border-bottom-left-radius: 80%;

                transform: translateX(-50%);
            }
        }

        .cz2__menu-quantity + .cz2__menu-item {
                button {
                    border-bottom: 1px solid $color-accent-light-gray;
                }
            }

        .cz2__menu-item {
            position: relative;

            display: flex;

            width: 100%;
            height: 70px;

            justify-content: flex-start;
            align-items: stretch;

            transition: background-color $transition-fast ease-out;

            padding: 0 40px;

            @include respond-above(sm) {
                padding: 0 56px;
            }

            &.cz2__menu-item--active {
                button {
                    border-bottom: none;
                }
            }

            .cz2__menu-item__complete-indicator,
            .cz2__menu-item__expand-indicator {
                position: absolute;
                top: 21px;

                display: inline-block;
                height: 12px;

                pointer-events: none;
            }

            .cz2__menu-item__expand-indicator {
                right: 3px;

                width: 10px;

                background: transparent url(../assets/open-arrow-new@2x.png) no-repeat center/contain;

                transform: rotate(0);
                transition: transform $transition-fast ease-out;
            }

            .cz2__menu-item__complete-indicator {
                // aligns checkmark with the menu item name
                left: -22px;

                width: 12px;
                padding: 0;

                background: transparent url(../assets/menu-check-dark@2x.png) no-repeat center/contain;

                visibility: hidden;
            }

            .cz2__menu-item__error-icon {
                position: absolute;
                top: 19px;
                left: -24px;

                display: block;
                width: 16px;
                height: 16px;

                background: transparent url(../assets/error-new@2x.png) no-repeat center/contain;
            }

            &.cz2__menu-item--active {
                z-index: 101;

                .cz2__menu-item__active-indicator {
                    visibility: visible;

                    opacity: 1;
                }

                .cz2__menu-item__expand-indicator {
                    transform: rotate(180deg);
                }
            }

            &.cz2__menu-item__complete {
                .cz2__menu-item__complete-indicator {
                    visibility: visible;
                }
            }

            &.cz2__menu-item--multiline {
                height: auto;
            }

            &.cz2__menu-item--with-error {
                button {
                    .cz2__type__small-header {
                        color: $color-brand-red;
                    }

                    .cz2__menu-item__description {
                        color: $color-brand-red;
                    }
                }
            }

            button {
                display: flex;
                width: 100%;
                padding: 16px 0;
                height: auto;
                border: none;
                border-top: 1px solid $color-accent-light-gray;

                /* Client Styles Reset */
                font-size: 0;
                line-height: inherit;
                text-align: left;

                background: transparent;

                .cz2__menu-item__description {
                    display: block;
                    max-width: 265px;
                    min-height: 22px;

                    overflow: hidden;

                    text-transform: capitalize;
                    text-overflow: ellipsis;
                    white-space: nowrap;

                    &.cz2__menu-item__description--no-caps {
                        text-transform: none;
                    }

                    &.cz2__menu-item__description--multiline {
                        white-space: normal;
                    }
                }
            }
        }

        .cz2__menu-item__inner {
            position: relative;

            width: 100%;
            height: 100%;
            display: flex;
            justify-content: flex-start;
            align-items: stretch;
        }

        .cz2__menu-item__button-content {
            flex-grow: 1;
        }

        .cz2__menu-item__price {
            margin-left: 10px;

            color: $color-primary-dark;
            font-family: $font-main;
            font-size: 12px;
            font-weight: 400;
            line-height: 16px;

            &:before {
                content: '+ ';
            }
        }
    }
</style>

<script>
    export default {
        props: {
            isActive: {
                type: Boolean,
                default: false,
            },
            title: {
                type: String,
                default: '',
            },
            price: {
                type: String,
                default: null,
            },
            description: {
                type: String,
                default: null,
            },
            isComplete: {
                type: Boolean,
                default: false,
            },
            hasErrors: {
                type: Boolean,
                default: false,
            },
            capitalize: {
                type: Boolean,
                default: true,
            },
            tip: {
                type: String,
                default: null,
            },
            multilineDescription: Boolean,
            readonly: Boolean,
        },
        data() {
            return {
                tipActive: false,
            };
        },
        computed: {
            /**
             * Current selected step.
             */
            selectedStep() {
                return this.$store.state.selectedStep;
            },
        },
        watch: {
            tipActive() {
                if (!this.tipActive) {
                    document.documentElement.removeEventListener('click', this.closeTip, false);
                    document.documentElement.removeEventListener('hideTip', this.closeTip, false);
                }
            },
        },
        methods: {
            /**
             * Enter selection mode if no item selected.
             */
            selectItem() {
                if (!this.selectedStep) {
                    this.$emit('buttonClick');
                }
            },

            closeTip() {
                this.tipActive = false;
            },

            showTip() {
                const event = new Event('hideTip');
                document.documentElement.dispatchEvent(event);

                this.tipActive = !this.tipActive;

                document.documentElement.addEventListener('click', this.closeTip, false);
                document.documentElement.addEventListener('hideTip', this.closeTip, false);
            },
        },
    };
</script>
